import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Languages = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Languages</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Arabic</td><td>Arap&#231;a</td></tr>
                                        <tr><td>Chinese</td><td>&#199;ince</td></tr>
                                        <tr><td>English</td><td>&#304;ngilizce</td></tr>
                                        <tr><td>Farsi</td><td>Fars&#231;a</td></tr>
                                        <tr><td>French</td><td>Frans&#305;zca</td></tr>
                                        <tr><td>German</td><td>Almanca</td></tr>
                                        <tr><td>Hindi</td><td>Hint&#231;e</td></tr>
                                        <tr><td>Kurdish</td><td>K&#252;rt&#231;e</td></tr>
                                        <tr><td>Latin</td><td>Latince</td></tr>
                                        <tr><td>Ottoman</td><td>Osmanl&#305;ca</td></tr>
                                        <tr><td>Portuguese</td><td>Portekizce</td></tr>
                                        <tr><td>Russian</td><td>Rus&#231;a</td></tr>
                                        <tr><td>Spanish</td><td>&#304;spanyolca</td></tr>
                                        <tr><td>Turkish</td><td>T&#252;rk&#231;e</td></tr>
                                        <tr><td>Urdu</td><td>Urduca</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
