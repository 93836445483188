import { Card, Col, Container, Row } from "@themesberg/react-bootstrap";

export const Directions = () => {
	return (
		<Container className="px-0">
			<Row>
				<Col xs={12} className="p-3">
					<Card>
						<Card.Body>
							<article>
								<h1>Directions</h1>

								<p class="phrases">
									<span class="c1">Can you help me?</span><br />
									<span className="text-secondary">Bana yardımcı olabilir misiniz?</span><br />
									<br />
									<span class="c1">I'm lost</span><br />
									<span className="text-secondary">Kayboldum</span><br />
									<br />
									<span class="c1">Where is __________ ?</span><br />
									<span className="text-secondary">__________ neresi?</span><br />
									<br />
									<span class="c1">I am looking for a __________</span><br />
									<span className="text-secondary">Ben bir __________ arıyorum</span><br />
									<br />
									<span class="c1">How far is it from here?</span><br />
									<span className="text-secondary">Ne kadar uzaklıkta?</span><br />
									<br />
									<span class="c1">Do you have a map?</span><br />
									<span className="text-secondary">Haritanız var mı?</span><br />
									<br />
									<span class="c1">Where does this road go to?</span><br />
									<span className="text-secondary">Bu yol nereye gidiyor?</span><br />
									<br />
									<span class="c1">Is this <span class="underline">[place name]</span> ?</span><br />
									<span className="text-secondary">Burası ____________ mı?</span><br />
									<br />
									<span class="c1">Thank you very much</span><br />
									<span className="text-secondary">Çok teşekkür ederim</span><br />
									<br />
									<br />
								</p>

								<p class="phrases">
									<span class="c1">Go straight</span><br />
									<span className="text-secondary">Düz git</span><br />
									<br />
									<span class="c1">Turn right</span><br />
									<span className="text-secondary">Sağa dön</span><br />
									<br />
									<span class="c1">Turn left</span><br />
									<span className="text-secondary">Sola dön</span><br />
									<br />
									<span class="c1">Traffic lights</span><br />
									<span className="text-secondary">Trafik ışıkları</span><br />
									<br />
									<span class="c1">Map</span><br />
									<span className="text-secondary">Harita</span><br />
								</p>



								{/* <div class="phr_image"> */}
								{/* <img src="https://turkishbasics.com//images/phrases/turkish-compass.jpg">		</div>
<img src="https://turkishbasics.com//images/phrases/turkish-compass.jpg">
<div class="phr_image">
			<img src="https://turkishbasics.com//images/phrases/turkish-compass.jpg">		</div> */}
							</article>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container >
	);
}
