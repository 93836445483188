
import { faBook, faQuoteLeft, faRunning, faSpellCheck } from "@fortawesome/free-solid-svg-icons";

export default [
    {
        "id": 0,
        "title": "Grammar",
        "icon": faBook,
        // "description": "The Turkish alphabet"
    },
    {
        "id": 1,
        "title": "Verbs",
        "icon": faRunning,
        // "description": "Built with the most popular CSS Framework"
    },
    {
        "id": 2,
        "title": "Phrases",
        "icon": faQuoteLeft,
        // "description": "Variables and mixins to empower development"
    },
    {
        "id": 3,
        "title": "Vocabulary",
        "icon": faSpellCheck,
        // "description": "All pages and components are 100% responsive"
    }
];