import { Col, Container, Image, Row } from "@themesberg/react-bootstrap";

const ImageRule = ({ src }) => (
    <Container className="my-4">
        <Row className="justify-content-center">
            <Col xs={12} md={9}>
                <Image src={src} />
            </Col>
        </Row>
    </Container>
)

export default ImageRule;