// Dynamically load external scripts
export const loadScript = (src, onLoad) => {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = onLoad;
      document.body.appendChild(script);
    } else if (onLoad) {
      onLoad();
    }
  };
  
  // Initialize Google Tag Manager (GTag)
  export const initializeGTag = (trackingId) => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', trackingId);
  };
  
  // Track custom GTag events
  export const trackGTagEvent = (eventParams) => {
    if (window.gtag) {
      window.gtag('event', 'conversion', eventParams);
    }
  };

    
  // Initialize GPT (Google Publisher Tag) slots
  export const initializeGPTSlot = (slotId, adUnit, dimensions) => {
    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(() => {
      window.googletag
        .defineSlot(adUnit, dimensions, slotId)
        .addService(window.googletag.pubads());
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();
      
      window.googletag.display(slotId);
    });
  };