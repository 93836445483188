
export const Routes = {
    // pages
    Presentation: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/examples/sign-in" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" },

    // content
    Verbs: {
        DefinitePastTense: { title: "Definite Past Tense", path: "/verbs/definite-past-tense" },
        ReportedPastTense: { title: 'Reported Past Tense', path: "/verbs/reported-past-tense" },
        PresentContinuousTense: { title: 'Present Continuous Tense', path: "/verbs/present-continuous-tense" },
        PresentSimpleTense: { title: 'Present Simple Tense', path: "/verbs/present-simple-tense" },
        FutureTense: { title: 'Future Tense', path: "/verbs/future-tense" },
        PassiveVoice: { title: 'Passive Voice', path: "/verbs/passive-voice" },
        Imperative: { title: 'Imperative', path: "/verbs/imperative" },
        Can: { title: 'Can', path: "/verbs/can" },
        Must: { title: 'Must', path: "/verbs/must" },
        If: { title: 'If', path: "/verbs/if" },
        Subjunctive: { title: 'Subjunctive', path: "/verbs/subjunctive" },
    },
    Grammar: {
        Alphabet: { title: 'Alphabet', path: "/grammar/alphabet" },
        IAmYouAre: { title: 'I Am, You Are', path: "/grammar/i-am-you-are" },
        Numbers: { title: 'Numbers', path: "/grammar/numbers" },
        Plurals: { title: 'Plurals', path: "/grammar/plurals" },
        Possession: { title: 'Possession', path: "/grammar/possession" },
        VarAndYok: { title: 'Var And Yok', path: "/grammar/var-and-yok" },
        TellingTheTime: { title: 'Telling The Time', path: "/grammar/telling-the-time" },
        ConsonantMutation: { title: 'Consonant Mutation', path: "/grammar/consonant-mutation" },
        DegreesOfComparison: { title: 'Degrees Of Comparison', path: "/grammar/degrees-of-comparison" },
        VowelHarmony: { title: 'Vowel Harmony', path: "/grammar/vowel-harmony" },
    },
    Phrases: {
        BasicPhrases: { title: 'Basic Phrases', path: "/phrases/basic-phrases" },
        MeetingPeople: { title: 'Meeting People', path: "/phrases/meeting-people" },
        Directions: { title: 'Directions', path: "/phrases/directions" },
        Eating: { title: 'Eating', path: "/phrases/eating" },
        Shopping: { title: 'Shopping', path: "/phrases/shopping" },
        Occasions: { title: 'Occasions', path: "/phrases/occasions" },
    },
    Vocabulary: {
        Airport: { title: 'Airport', path: "/vocabulary/airport" },
        Animals: { title: 'Animals', path: "/vocabulary/animals" },
        AtHome: { title: 'At Home', path: "/vocabulary/at-home" },
        Clothing: { title: 'Clothing', path: "/vocabulary/clothing" },
        Colors: { title: 'Colors', path: "/vocabulary/colors" },
        Countries: { title: 'Countries', path: "/vocabulary/countries" },
        Currency: { title: 'Currency', path: "/vocabulary/currency" },
        DaysAndMonths: { title: 'Days And Months', path: "/vocabulary/days-and-months" },
        FamilyMembers: { title: 'Family Members', path: "/vocabulary/family-members" },
        Food: { title: 'Food', path: "/vocabulary/food" },
        Islam: { title: 'Islam', path: "/vocabulary/islam" },
        Languages: { title: 'Languages', path: "/vocabulary/languages" },
        MobilePhone: { title: 'Mobile Phone', path: "/vocabulary/mobile-phone" },
        Occupations: { title: 'Occupations', path: "/vocabulary/occupations" },
        PartsOfTheBody: { title: 'Parts Of The Body', path: "/vocabulary/parts-of-the-body" },
        Politics: { title: 'Politics', path: "/vocabulary/politics" },
        School: { title: 'School', path: "/vocabulary/school" },
        Transport: { title: 'Transport', path: "/vocabulary/transport" },
    },
    Opposites: { title: 'Opposites', path: "/additional/opposites" },
    Punctuation: { title: 'Punctuation', path: "/additional/punctuation" },
    RepublicOfTurkiye: { title: 'Republic Of Türkiye', path: "/information/republic-of-turkiye" },
    Turkish: { title: 'Turkish', path: "/information/turkish" },
    Stories: { title: 'Stories', path: "/resources/stories" },
};