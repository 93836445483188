import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Countries = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Countries</h1>

                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Türkiye</td><td>Türkiye</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Africa (Afrika)</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Algeria</td><td>Cezayir</td></tr>
                                        <tr><td>Angola</td><td>Angola</td></tr>
                                        <tr><td>Botswana</td><td>Botsvana</td></tr>
                                        <tr><td>Cameroon</td><td>Kamerun</td></tr>
                                        <tr><td>Central African Republic</td><td>Orta Afrika Cumhuriyeti</td></tr>
                                        <tr><td>Democratic Republic of Congo</td><td>Demokratik Kongo Cumhuriyeti</td></tr>
                                        <tr><td>Egypt</td><td>M&#305;s&#305;r</td></tr>
                                        <tr><td>Ethiopia</td><td>Etiyopya</td></tr>
                                        <tr><td>Gabon</td><td>Gabon</td></tr>
                                        <tr><td>Ghana</td><td>Gana</td></tr>
                                        <tr><td>Ivory Coast</td><td>Fildi&#351;i K&#305;y&#305;s&#305;</td></tr>
                                        <tr><td>Kenya</td><td>Kenya</td></tr>
                                        <tr><td>Libya</td><td>Libya</td></tr>
                                        <tr><td>Malawi</td><td>Malavi</td></tr>
                                        <tr><td>Mali</td><td>Mali</td></tr>
                                        <tr><td>Mauritania</td><td>Moritanya</td></tr>
                                        <tr><td>Morocco</td><td>Fas</td></tr>
                                        <tr><td>Mozambique</td><td>Mozambik</td></tr>
                                        <tr><td>Namibia</td><td>Namibya</td></tr>
                                        <tr><td>Nigeria</td><td>Nijerya</td></tr>
                                        <tr><td>Republic of the Congo</td><td>Kongo Cumhuriyeti</td></tr>
                                        <tr><td>Senegal</td><td>Senegal</td></tr>
                                        <tr><td>Somalia</td><td>Somali</td></tr>
                                        <tr><td>South Africa</td><td>G&#252;ney Afrika</td></tr>
                                        <tr><td>Sudan</td><td>Sudan</td></tr>
                                        <tr><td>Tanzania</td><td>Tanzanya</td></tr>
                                        <tr><td>Tunisia</td><td>Tunus</td></tr>
                                        <tr><td>Uganda</td><td>Uganda</td></tr>
                                        <tr><td>Zambia</td><td>Zambiya</td></tr>
                                        <tr><td>Zimbabwe</td><td>Zimbabve</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Asia (Asya)</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Afghanistan</td><td>Afganistan</td></tr>
                                        <tr><td>Armenia</td><td>Ermenistan</td></tr>
                                        <tr><td>Azerbaijan</td><td>Azerbaycan</td></tr>
                                        <tr><td>Bangladesh</td><td>Banglade&#351;</td></tr>
                                        <tr><td>Bahrain</td><td>Bahreyn</td></tr>
                                        <tr><td>Cambodia</td><td>Kambo&#231;ya</td></tr>
                                        <tr><td>Chechnya</td><td>&#199;e&#231;enistan</td></tr>
                                        <tr><td>China</td><td>&#199;in</td></tr>
                                        <tr><td>Georgia</td><td>G&#252;rcistan</td></tr>
                                        <tr><td>Hong Kong</td><td>Hong Kong</td></tr>
                                        <tr><td>India</td><td>Hindistan</td></tr>
                                        <tr><td>Indonesia</td><td>Endonezya</td></tr>
                                        <tr><td>Iran</td><td>&#304;ran</td></tr>
                                        <tr><td>Iraq</td><td>Irak</td></tr>
                                        <tr><td>Israel</td><td>&#304;srail</td></tr>
                                        <tr><td>Japan</td><td>Japonya</td></tr>
                                        <tr><td>Jordan</td><td>&#220;rd&#252;n</td></tr>
                                        <tr><td>Kazakhstan</td><td>Kazakistan</td></tr>
                                        <tr><td>Kuwait</td><td>Kuveyt</td></tr>
                                        <tr><td>Kyrgyzstan</td><td>K&#305;rg&#305;zistan</td></tr>
                                        <tr><td>Lebanon</td><td>L&#252;bnan</td></tr>
                                        <tr><td>Malaysia</td><td>Malezya</td></tr>
                                        <tr><td>Mongolia</td><td>Mo&#287;olistan</td></tr>
                                        <tr><td>North Korea</td><td>Kuzey Kore</td></tr>
                                        <tr><td>Oman</td><td>Umman</td></tr>
                                        <tr><td>Pakistan</td><td>Pakistan</td></tr>
                                        <tr><td>Palestine</td><td>Filistin</td></tr>
                                        <tr><td>Philippines</td><td>Filipinler</td></tr>
                                        <tr><td>Qatar</td><td>Katar</td></tr>
                                        <tr><td>Saudi Arabia</td><td>Suudi Arabistan</td></tr>
                                        <tr><td>South Korea</td><td>G&#252;ney Kore</td></tr>
                                        <tr><td>Syria</td><td>Suriye</td></tr>
                                        <tr><td>Tajikistan</td><td>Tacikistan</td></tr>
                                        <tr><td>Türkiye</td><td>Türkiye</td></tr>
                                        <tr><td>Turkmenistan</td><td>T&#252;rkmenistan</td></tr>
                                        <tr><td>United Arab Emirates</td><td>Birle&#351;ik Arap Emirlikleri</td></tr>
                                        <tr><td>Uzbekistan</td><td>&#214;zbekistan</td></tr>
                                        <tr><td>Vietnam</td><td>Vietnam</td></tr>
                                        <tr><td>Yemen</td><td>Yemen</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Europe (Avrupa)</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Albania</td><td>Arnavutluk</td></tr>
                                        <tr><td>Austria</td><td>Avusturya</td></tr>
                                        <tr><td>Belarus</td><td>Belarus / Beyaz Rusya</td></tr>
                                        <tr><td>Belgium</td><td>Bel&#231;ika</td></tr>
                                        <tr><td>Bosnia Herzegovina</td><td>Bosna Hersek</td></tr>
                                        <tr><td>Bulgaria</td><td>Bulgaristan</td></tr>
                                        <tr><td>Croatia</td><td>Hırvatistan</td></tr>
                                        <tr><td>Cyprus</td><td>K&#305;br&#305;s</td></tr>
                                        <tr><td>Czech Republic</td><td>&#199;ek Cumhuriyeti</td></tr>
                                        <tr><td>Denmark</td><td>Danimarka</td></tr>
                                        <tr><td>England</td><td>&#304;ngiltere</td></tr>
                                        <tr><td>Estonia</td><td>Estonya</td></tr>
                                        <tr><td>Finland</td><td>Finlandiya</td></tr>
                                        <tr><td>France</td><td>Fransa</td></tr>
                                        <tr><td>Georgia</td><td>G&#252;rcistan</td></tr>
                                        <tr><td>Germany</td><td>Almanya</td></tr>
                                        <tr><td>Greece</td><td>Yunanistan</td></tr>
                                        <tr><td>Holland</td><td>Hollanda</td></tr>
                                        <tr><td>Hungary</td><td>Macaristan</td></tr>
                                        <tr><td>Ireland</td><td>&#304;rlanda</td></tr>
                                        <tr><td>Italy</td><td>&#304;talya</td></tr>
                                        <tr><td>Kosovo</td><td>Kosova</td></tr>
                                        <tr><td>Latvia</td><td>Letonya</td></tr>
                                        <tr><td>Lithuania</td><td>Litvanya</td></tr>
                                        <tr><td>Luxembourg</td><td>L&#252;ksemburg</td></tr>
                                        <tr><td>Malta</td><td>Malta</td></tr>
                                        <tr><td>Macedonia</td><td>Makedonya</td></tr>
                                        <tr><td>Moldova</td><td>Moldova</td></tr>
                                        <tr><td>Montenegro</td><td>Karada&#287;</td></tr>
                                        <tr><td>Northern Ireland</td><td>Kuzey &#304;rlanda</td></tr>
                                        <tr><td>Norway</td><td>Norve&#231;</td></tr>
                                        <tr><td>Poland</td><td>Polonya</td></tr>
                                        <tr><td>Portugal</td><td>Portekiz</td></tr>
                                        <tr><td>Romania</td><td>Romanya</td></tr>
                                        <tr><td>Russia</td><td>Rusya</td></tr>
                                        <tr><td>Scotland</td><td>&#304;sko&#231;ya</td></tr>
                                        <tr><td>Serbia</td><td>S&#305;rbistan</td></tr>
                                        <tr><td>Slovakia</td><td>Slovakya</td></tr>
                                        <tr><td>Slovenia</td><td>Slovenya</td></tr>
                                        <tr><td>Spain</td><td>&#304;spanya</td></tr>
                                        <tr><td>Sweden</td><td>&#304;sve&#231;</td></tr>
                                        <tr><td>Switzerland</td><td>&#304;svi&#231;re</td></tr>
                                        <tr><td>Ukraine</td><td>Ukrayna</td></tr>
                                        <tr><td>Wales</td><td>Galler</td></tr>
                                    </tbody>
                                </Table>

                                <h2>North America (Kuzey Amer&#304;ka)</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Canada</td><td>Kanada</td></tr>
                                        <tr><td>Costa Rica</td><td>Kosta Rika</td></tr>
                                        <tr><td>Cuba</td><td>K&#252;ba</td></tr>
                                        <tr><td>Haiti</td><td>Haiti</td></tr>
                                        <tr><td>Mexico</td><td>Meksika</td></tr>
                                        <tr><td>Panama</td><td>Panama</td></tr>
                                        <tr><td>United States of America</td><td>Amerika Birle&#351;ik Devletleri</td></tr>
                                    </tbody>
                                </Table>

                                <h2>South America (G&#252;ney Amer&#304;ka)</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Argentina</td><td>Arjantin</td></tr>
                                        <tr><td>Bolivia</td><td>Bolivya</td></tr>
                                        <tr><td>Brazil</td><td>Brezilya</td></tr>
                                        <tr><td>Chile</td><td>&#350;ili</td></tr>
                                        <tr><td>Colombia</td><td>Kolombiya</td></tr>
                                        <tr><td>Ecuador</td><td>Ekvador</td></tr>
                                        <tr><td>Paraguay</td><td>Paraguay</td></tr>
                                        <tr><td>Peru</td><td>Peru</td></tr>
                                        <tr><td>Uruguay</td><td>Uruguay</td></tr>
                                        <tr><td>Venezuela</td><td>Venezuela</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Oceania (Okyanusya)</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Australia</td><td>Avustralya</td></tr>
                                        <tr><td>Fiji</td><td>Fiji</td></tr>
                                        <tr><td>New Zealand</td><td>Yeni Zelanda</td></tr>
                                        <tr><td>Papua New Guinea</td><td>Papua Yeni Gine</td></tr>
                                        <tr><td>Samoa</td><td>Samoa</td></tr>
                                        <tr><td>Tonga</td><td>Tonga</td></tr>
                                    </tbody>
                                </Table>

                                <p>
                                    Note: Countries/Territories/States might not be listed by their official names.
                                </p>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
