import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Numbers = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Numbers</h1>

                                <p>
                                    <Table responsive hover striped size="sm" className="num">
                                        <tbody>
                                            <tr>
                                                <td>1 - Bir</td>
                                                <td>10 - On</td>
                                                <td>1 000 - Bin</td>
                                            </tr>
                                            <tr>
                                                <td>2 - &#304;ki</td>
                                                <td>20 - Yirmi</td>
                                                <td>1 000 000 - Milyon</td>
                                            </tr>
                                            <tr>
                                                <td>3 - &#220;&ccedil;</td>
                                                <td>30 - Otuz</td>
                                                <td>1 000 000 000 - Milyar</td>
                                            </tr>
                                            <tr>
                                                <td>4 - D&#246;rt</td>
                                                <td>40 - K&#305;rk</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>5 - Be&#351;</td>
                                                <td>50 - Elli</td>
                                                <td>0 - S&#305;f&#305;r</td>
                                            </tr>
                                            <tr>
                                                <td>6 - Alt&#305;</td>
                                                <td>60 - Atm&#305;&#351;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>7 - Yedi</td>
                                                <td>70 - Yetmi&#351;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>8 - Sekiz</td>
                                                <td>80 - Seksen</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>9 - Dokuz</td>
                                                <td>90 - Doksan</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>10 - On</td>
                                                <td>100 - Y&#252;z</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>

                                <p className='mt-4'>
                                    Examples:
                                </p>

                                <p className="scd">
                                    11 - On bir<br />57 - Elli yedi<br />82 - Seksen iki
                                </p>
                                <p className="scd">
                                    144 - Y&#252;z k&#305;rk d&#246;rt<br />500 - Be&#351; y&#252;z<br />999 - Dokuz y&#252;z doksan dokuz
                                </p>
                                <p className="scd">
                                    1024 - Bin yirmi d&#246;rt<br />1986 - Bin dokuz y&#252;z seksen alt&#305;<br />2025 - &#304;ki bin yirmi beş
                                </p>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
