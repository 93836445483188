import { faExternalLinkAlt, faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Image, Nav, Navbar, NavDropdown, OverlayTrigger, Row, Tooltip } from '@themesberg/react-bootstrap';
import React from "react";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import TBSquareLogo from "../assets/img/brand/tb_logo_64x64.png";
import TurkishBasicsLogo from "../assets/img/brand/turkishbasics-logo_full_no_bg-22001.webp";
import { Routes } from "../routes";

import features from "../data/features";
import AdSlot from "../ui/components/advertising/AdSlot";

export default () => {
  const isAdsEnabled = process.env.REACT_APP_SHOW_ADS === 'true';

  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link as={Link} to={link} className="page-preview page-preview-lg scale-up-hover-2">
          <Image src={image} className="shadow-lg rounded scale" alt="Dashboard page preview" />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name} <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-primary shadow-soft rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li data-toggle="tooltip" data-placement="left" title="Main folder that you will be working with">
          <FontAwesomeIcon icon={icon ? icon : faFolder} className={`${color} me-2`} /> {name}
        </li>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <Navbar variant="dark" expand="lg" className="navbar-transparent sticky-top bg-primary bg-gradient">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 d-flex align-items-center">
            <Image src={TurkishBasicsLogo} />
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <NavDropdown title="Grammar" id="grammar-nav-dropdown">
                  {Object.entries(Routes.Grammar).map(([key, value]) => (
                    <NavDropdown.Item as={Link} to={value.path} key={key}>{value.title}</NavDropdown.Item>
                  ))}
                </NavDropdown>

                <NavDropdown title="Verbs" id="verbs-nav-dropdown">
                  {Object.entries(Routes.Verbs).map(([key, value]) => (
                    <NavDropdown.Item as={Link} to={value.path} key={key}>{value.title}</NavDropdown.Item>
                  ))}
                </NavDropdown>

                <NavDropdown title="Phrases" id="phrases-nav-dropdown">
                  {Object.entries(Routes.Phrases).map(([key, value]) => (
                    <NavDropdown.Item as={Link} to={value.path} key={key}>{value.title}</NavDropdown.Item>
                  ))}
                </NavDropdown>

                <NavDropdown title="Vocabulary" id="vocabulary-nav-dropdown">
                  {Object.entries(Routes.Vocabulary).map(([key, value]) => (
                    <NavDropdown.Item as={Link} to={value.path} key={key}>{value.title}</NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>

      <section className="d-flex justify-content-center overflow-hidden pt-lg-4 bg-primary" id="top-ad-desktop">
        {isAdsEnabled && (
          <AdSlot slotId="div-gpt-ad-1597699414979-0" adUnit="/1092393/TurkishBasics_728_90" dimensions={[728, 90]} />
        )}
      </section>

      <section className="section-header overflow-hidden py-10 py-lg-9 bg-primary text-white" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1 className="display-1 py-2">Learn Turkish with ease.</h1>
              <h6 className="display-4 py-4 d-flex justify-content-center flex-column">Explore the alphabet, grammar, phrases and more - for free.</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center mt-4">
              <Button as={Link} to={Routes.DashboardOverview.path} variant="secondary" size="lg">Start Learning</Button>
            </Col>
          </Row>
          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg className="fill-soft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure>
        </Container>
      </section>

      <section className="section section-lg text-white">
        <Container>
          <Row className="justify-content-center mb-5 mb-lg-6">
            <Col xs={12} className="text-center">
              <h2 className="px-lg-5 text-primary">What you'll learn</h2>
              {/* <p className="lead px-lg-8">You get all React.js components fully customized. Besides, you receive numerous plugins out of the box and ready to use.</p> */}
            </Col>
          </Row>
          <Row>
            {features.map(feature => <Feature key={`features-${feature.id}`} {...feature} />)}
          </Row>
        </Container>
      </section>

      <footer className="footer py-6 bg-primary text-white">
        <Container>
          <Row>
            <Col>
              <Image src={TBSquareLogo} height={35} className="d-block mx-auto mb-3" alt="Turkish Basics Logo" />
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © Turkish Basics 2011-<span className="current-year">{new Date().getFullYear()}</span>.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
