
import React from 'react';

import { ReactComponent as StarIcon } from "../assets/img/icons/pointed-star-svgrepo-com.svg";

export default (props) => {

  const { show } = props;

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      <StarIcon className="loader-element animate__animated animate__jackInTheBox text-secondary" height={44} />
    </div>
  );
};
