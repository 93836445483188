import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const AtHome = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>At Home</h1>

                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Chair</td><td>Sandalye</td></tr>
                                        <tr><td>Clock</td><td>Saat</td></tr>
                                        <tr><td>Desk</td><td>Masa</td></tr>
                                        <tr><td>Door</td><td>Kap&#305;</td></tr>
                                        <tr><td>House</td><td>Ev</td></tr>
                                        <tr><td>Light</td><td>I&#351;&#305;k</td></tr>
                                        <tr><td>Room</td><td>Oda</td></tr>
                                        <tr><td>Stairs</td><td>Merdiven</td></tr>
                                        <tr><td>Window</td><td>Pencere</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Living room</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Bookshelf</td><td>Kitapl&#305;k</td></tr>
                                        <tr><td>Coffee table</td><td>Sehpa</td></tr>
                                        <tr><td>Couch</td><td>Kanepe</td></tr>
                                        <tr><td>Fireplace</td><td>&#350;&#246;mine</td></tr>
                                        <tr><td>Living room</td><td>Oturma odas&#305;</td></tr>
                                        <tr><td>Rug</td><td>Hal&#305;</td></tr>
                                        <tr><td>Vase</td><td>Vazo</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Bedroom</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Alarm clock</td><td>&#199;alar saat</td></tr>
                                        <tr><td>Bed</td><td>Yatak</td></tr>
                                        <tr><td>Bedroom</td><td>Yatak odas&#305;</td></tr>
                                        <tr><td>Blanket</td><td>Battaniye</td></tr>
                                        <tr><td>Computer</td><td>Bilgisayar</td></tr>
                                        <tr><td>Curtain</td><td>Perde</td></tr>
                                        <tr><td>Drawer</td><td>&#199;ekmece</td></tr>
                                        <tr><td>Lamp</td><td>Lamba</td></tr>
                                        <tr><td>Pillow</td><td>Yast&#305;k</td></tr>
                                        <tr><td>Wardrobe</td><td>Gard&#305;rop</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Kitchen</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Dinner Table</td><td>Yemek masas&#305;</td></tr>
                                        <tr><td>Dishwasher</td><td>Bula&#351;&#305;k makinesi</td></tr>
                                        <tr><td>Fridge</td><td>Buzdolab&#305;</td></tr>
                                        <tr><td>Kitchen</td><td>Mutfak</td></tr>
                                        <tr><td>Oven</td><td>F&#305;r&#305;n</td></tr>
                                        <tr><td>Pot</td><td>Tencere</td></tr>
                                        <tr><td>Stove</td><td>Ocak</td></tr>
                                        <tr><td>Teapot</td><td>&#199;aydanl&#305;k</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Bathroom</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Bathroom</td><td>Banyo</td></tr>
                                        <tr><td>Mirror</td><td>Ayna</td></tr>
                                        <tr><td>Shower</td><td>Du&#351;</td></tr>
                                        <tr><td>Soap</td><td>Sabun</td></tr>
                                        <tr><td>Tap</td><td>Musluk</td></tr>
                                        <tr><td>Toothbrush</td><td>Di&#351; f&#305;r&#231;as&#305;</td></tr>
                                        <tr><td>Toothpaste</td><td>Di&#351; macunu</td></tr>
                                        <tr><td>Towel</td><td>Havlu</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
