import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Possession = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Possession</h1>
                                <p>
                                    Possession in Turkish works using pronouns and suffixes. Each pronoun is associated with its own suffix.
                                    The suffixes may take on different forms depending on the last letter and the last vowel of the original word.
                                    There are six pronouns of possession:
                                </p>
                                <p>
                                    <Table hover striped size="sm" className="poss1">
                                        <tbody>
                                            <tr><td>My</td><td>Benim</td></tr>
                                            <tr><td>Your</td><td>Senin</td></tr>
                                            <tr><td>His / Her / Its</td><td>Onun</td></tr>
                                            <tr><td>Our</td><td>Bizim</td></tr>
                                            <tr><td>Your (Plural)</td><td>Sizin</td></tr>
                                            <tr><td>Their</td><td>Onlar&#305;n</td></tr>
                                        </tbody>
                                    </Table>
                                </p>

                                <p>
                                    Each suffix has more than one form. We choose the correct form of the suffix based on the last vowel of our original word. This is done
                                    to preserve Vowel Harmony.

                                    <Table responsive hover striped size="sm" className="poss2" >
                                        <thead>
                                            <tr><th className="corner">Last Vowel &#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr><td className="colh">Benim</td><td>-(&#305;)m</td><td>-(i)m</td><td>-(u)m</td><td>-(&#252;)m</td></tr>
                                            <tr><td className="colh">Senin</td><td>-(&#305;)n</td><td>-(i)n</td><td>-(u)n</td><td>-(&#252;)n</td></tr>
                                            <tr><td className="colh">Onun</td><td>-[s]&#305;</td><td>-[s]i</td><td>-[s]u</td><td>-[s]&#252;</td></tr>
                                            <tr><td className="colh">Bizim</td><td>-(&#305;)m&#305;z</td><td>-(i)miz</td><td>-(u)muz</td><td>-(&#252;)m&#252;z</td></tr>
                                            <tr><td className="colh">Sizin</td><td>-(&#305;)n&#305;z</td><td>-(i)niz</td><td>-(u)nuz</td><td>-(&#252;)n&#252;z</td></tr>
                                            <tr><td className="colh">Onlar&#305;n</td><td>-lar&#305;</td><td>-leri</td><td>-lar&#305;</td><td>-leri</td></tr>
                                        </tbody>
                                    </Table>
                                </p>

                                <p>
                                    Examples:
                                    <p className="scd">
                                        Benim &#231;anta<span className="red">m</span> - My bag<br />
                                        Senin anne<span className="red">n</span> - Your mother<br />
                                        Onun top<span className="red">u</span> - His/Her/Its ball<br />
                                        Bizim araba<span className="red">m&#305;z</span> - Our car<br />
                                        Sizin kuzu<span className="red">nuz</span> - Your (Plural) lamb<br />
                                        Onlar&#305;n kitap<span className="red">lar&#305;</span> - Their book<br />
                                    </p>
                                </p>

                                <p>
                                    When adding suffixes to a word, we make sure that we never have two vowels side-by-side.
                                </p>

                                <p>
                                    Letters indicated inside <span className="fo">round brackets ()</span> in the Suffixes of Possession table should be added when the last letter of the original word is a consonant. Eg.:<br />
                                    <p className="scd">
                                        Ad<span className="red"><span className="un">&#305;</span>m</span> - My name<br />
                                        Ev<span className="red"><span className="un">i</span>n</span> - Your house<br />
                                        Otel<span className="red"><span className="un">i</span>miz</span> - Our hotel<br />
                                        Okul<span className="red"><span className="un">u</span>nuz</span> - Your (Plural) school<br />
                                    </p>
                                </p>

                                <p>
                                    For "Onun", the letter "s" indicated inside <span className="fo">square brackets [s]</span> in the Suffixes of Possession table above should be added when the last letter of the original word is a vowel. Eg.:<br />
                                    <p className="scd">
                                        Onun para<span className="red"><span className="un">s</span>&#305;</span> - His/Her/Its money<br />
                                        Onun dede<span className="red"><span className="un">s</span>i</span> - His/Her/Its grandfather<br />
                                        Onun havlu<span className="red"><span className="un">s</span>u</span> - His/Her/Its towel<br />
                                    </p>
                                </p>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
