import { Card, Col, Container, Row } from "@themesberg/react-bootstrap";

export const Shopping = () => {
	return (<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Shopping</h1>

							<p class="phrases">
								<span class="c1">What is your size? (Clothing)</span><br />
								<span className="text-secondary">Kaç beden giyiyorsunuz?</span><br />
								<br />
								<span class="c1">My size is ___</span><br />
								<span className="text-secondary">___ beden giyiyorum</span><br />
								<br />
								<span class="c1">What color would you like?</span><br />
								<span className="text-secondary">Ne renk istiyorsunuz?</span><br />
								<br />
								<span class="c1">I would like <span class="underline">&nbsp;&nbsp;[color]&nbsp;&nbsp;</span></span><br />
								<span className="text-secondary">_______ renk istiyorum</span><br />
								<br />
							</p>
							<br />
							<br />
							<p class="phrases">
								<span class="c1">I would like to buy a __________</span><br />
								<span className="text-secondary">_________ almak istiyorum</span><br />
								<br />
								<span class="c1">Do you have _________?</span><br />
								<span className="text-secondary">_________ var mı?</span><br />
								<br />
								<span class="c1">How much is this?</span><br />
								<span className="text-secondary">Bunun fiyatı ne?</span><br />
								<br />
								<span class="c1">Ok, I'll take it</span><br />
								<span className="text-secondary">Tamam alacağım</span><br />
							</p>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container >
	);
}
