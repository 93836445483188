import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Currency = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Currency</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Dirham</td><td>Dirhem</td></tr>
                                        <tr><td>Dollar</td><td>Dolar</td></tr>
                                        <tr><td>Euro</td><td>Euro / Avro</td></tr>
                                        <tr><td>Franc</td><td>Frank</td></tr>
                                        <tr><td>Pound</td><td>Sterlin</td></tr>
                                        <tr><td>Rupee</td><td>Rupi</td></tr>
                                        <tr><td>Yen</td><td>Yen</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
