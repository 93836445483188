import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const FamilyMembers = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Family Members</h1>

                                <h2>Immediate</h2>
                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Father</td><td>Baba</td></tr>
                                        <tr><td>Mother</td><td>Anne</td></tr>
                                        <tr><td>Brother</td><td>Karde&#351;</td></tr>
                                        <tr><td>Sister</td><td>K&#305;z karde&#351;</td></tr>
                                        <tr><td>Elder brother</td><td>Abi</td></tr>
                                        <tr><td>Elder sister</td><td>Abla</td></tr>
                                        <tr><td>Grandfather</td><td>Dede</td></tr>
                                        <tr><td>Grandmother</td><td><span className="tblnote">See below</span></td></tr>
                                        <tr><td>Grandchild</td><td>Torun</td></tr>
                                        <tr><td>Husband</td><td>Koca</td></tr>
                                        <tr><td>Wife</td><td>Han&#305;m</td></tr>
                                        <tr><td>Spouse (Husband / Wife)</td><td>E&#351;</td></tr>
                                        <tr><td>Son</td><td>O&#287;ul</td></tr>
                                        <tr><td>Daughter</td><td>K&#305;z</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Maternal</h2>
                                <Table responsive hover striped size="sm" className="meet" >
                                    <tbody>
                                        <tr><td>Aunt</td><td>Teyze</td></tr>
                                        <tr><td>Uncle</td><td>Day&#305;</td></tr>
                                        <tr><td>Grandmother</td><td>Anneanne</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Paternal</h2>
                                <Table responsive hover striped size="sm" className="meet" >
                                    <tbody>
                                        <tr><td>Aunt</td><td>Hala</td></tr>
                                        <tr><td>Uncle</td><td>Amca</td></tr>
                                        <tr><td>Grandmother</td><td>Babaanne</td></tr>
                                    </tbody>
                                </Table>

                                <h2>In-laws</h2>
                                <Table responsive hover striped size="sm" className="meet" >
                                    <tbody>
                                        <tr><td>Mother-in-law</td><td>Kaynana / Kay&#305;nvalide</td></tr>
                                        <tr><td>Father-in-law</td><td>Kay&#305;nbaba / Kay&#305;npeder</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
