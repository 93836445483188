import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";
import SectionHeading from "../../../components/SectionHeading";

const Alphabet = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Alphabet</h1>

                                <p>
                                    The Turkish alphabet is a modified version of the Latin alphabet and consists of 29 letters.
                                    Included are 6 additional letters - &#231;, &#287;, &#305;, &#246;, &#351;, &#252; - while - q, w, x - are excluded.
                                </p>

                                <Table responsive size="sm">
                                    <tbody>
                                        <tr>
                                            <td className="px-1">Aa</td>
                                            <td className="px-1">Bb</td>
                                            <td className="px-1">Cc</td>
                                            <td className="px-1">&#199;&#231;</td>
                                            <td className="px-1">Dd</td>
                                            <td className="px-1">Ee</td>
                                            <td className="px-1">Ff</td>
                                            <td className="px-1">Gg</td>
                                            <td className="px-1">&#286;&#287;</td>
                                            <td className="px-1">Hh</td>
                                            <td className="px-1">I&#305;</td>
                                            <td className="px-1">&#304;i</td>
                                            <td className="px-1">Jj</td>
                                            <td className="px-1">Kk</td>
                                            <td className="px-1">Ll</td>
                                            <td className="px-1">Mm</td>
                                            <td className="px-1">Nn</td>
                                            <td className="px-1">Oo</td>
                                            <td className="px-1">&#214;&#246;</td>
                                            <td className="px-1">Pp</td>
                                            <td className="px-1">Rr</td>
                                            <td className="px-1">Ss</td>
                                            <td className="px-1">&#350;&#351;</td>
                                            <td className="px-1">Tt</td>
                                            <td className="px-1">Uu</td>
                                            <td className="px-1">&#220;&#252;</td>
                                            <td className="px-1">Vv</td>
                                            <td className="px-1">Yy</td>
                                            <td className="px-1">Zz</td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <p>
                                    There are 8 vowels and 21 consonants:
                                </p>

                                <Table size="sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Vowels
                                            </td>
                                            <td>
                                                a e &#305; i o &#246; u &#252;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Consonants
                                            </td>
                                            <td>
                                                b c &#231; d f g &#287; h j k l m n p r s &#351; t v y z
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <SectionHeading title="Pronunciation" />
                                <p>
                                    Most Turkish letters are similar in pronunciation to their English counterparts. There are a few letters however whose pronunciation is unique
                                    to Turkish.
                                </p>
                                <p>
                                    "Yumu&#351;ak ge" (&#286; &#287;) or "soft g" for example always follows a vowel and is pronounced in one of two ways.
                                    If the vowel before it is one of a, &#305;, o, u then "yumu&#351;ak ge" will lengthen the sound of that vowel, eg. ya&#287;mur, a&#287;a&#231;.
                                    If the vowel is one of e, i, ö, ü then "yumu&#351;ak ge" will be pronounced as "y", eg. e&#287;itim, i&#287;ne. Since "yumu&#351;ak ge" always
                                    follows a vowel there are no words that start with it.
                                </p>

                                <Table responsive striped size="sm">
                                    <thead>
                                        <tr>
                                            <th scope='col'>Letter</th>
                                            <th scope='col'>Name</th>
                                            <th scope='col'>Pronunciation</th>
                                            <th scope='col'>Turkish Example</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Aa</td>
                                            <td>a</td>
                                            <td>s<span className="pf">u</span>n</td>
                                            <td>k<span className="pf">a</span>lem (pen)</td>
                                        </tr>
                                        <tr>
                                            <td>Bb</td>
                                            <td>be</td>
                                            <td><span className="pf">b</span>us</td>
                                            <td><span className="pf">b</span>al&#305;k (fish)</td>
                                        </tr>
                                        <tr>
                                            <td>Cc</td>
                                            <td>ce</td>
                                            <td><span className="pf">j</span>ar</td>
                                            <td><span className="pf">c</span>ep (pocket)</td>
                                        </tr>
                                        <tr>
                                            <td>&#199;&#231;</td>
                                            <td>&#231;e</td>
                                            <td><span className="pf">ch</span>at</td>
                                            <td><span className="pf">&ccedil;</span>ay (tea)</td>
                                        </tr>
                                        <tr>
                                            <td>Dd</td>
                                            <td>de</td>
                                            <td><span className="pf">d</span>oor</td>
                                            <td><span className="pf">d</span>eve (camel)</td>
                                        </tr>
                                        <tr>
                                            <td>Ee</td>
                                            <td>e</td>
                                            <td><span className="pf">e</span>gg</td>
                                            <td><span className="pf">e</span>v (house)</td>
                                        </tr>
                                        <tr>
                                            <td>Ff</td>
                                            <td>fe</td>
                                            <td><span className="pf">f</span>ace</td>
                                            <td><span className="pf">f</span>il (elephant)</td>
                                        </tr>
                                        <tr>
                                            <td>Gg</td>
                                            <td>ge</td>
                                            <td><span className="pf">g</span>arden</td>
                                            <td><span className="pf">g</span>emi (ship)</td>
                                        </tr>
                                        <tr>
                                            <td>&#286;&#287;</td>
                                            <td>yumu&#351;ak ge</td>
                                            <td className='td-nested-table'>
                                                <Table size="sm">
                                                    <tbody>
                                                        <tr><td>lengthens vowel (after a,&#305;,o,u)</td></tr>
                                                        <tr><td><span className="pf">y</span>ellow (after e,i,&#246;,&#252;)</td></tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                            <td className='td-nested-table'>
                                                <Table size="sm">
                                                    <tbody>
                                                        <tr><td>ya<span className="pf">&#287;</span>mur (rain)</td></tr>
                                                        <tr><td>e<span className="pf">&#287;</span>itim (education)</td></tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Hh</td>
                                            <td>he</td>
                                            <td><span className="pf">h</span>at</td>
                                            <td><span className="pf">h</span>avlu (towel)</td>
                                        </tr>
                                        <tr>
                                            <td>I&#305;</td>
                                            <td>&#305;</td>
                                            <td>cous<span className="pf">i</span>n</td>
                                            <td><span className="pf">&#305;</span>spanak (spinach)</td>
                                        </tr>
                                        <tr>
                                            <td>&#304;i</td>
                                            <td>i</td>
                                            <td><span className="pf">i</span>nternet</td>
                                            <td><span className="pf">&#304;</span>ngilizce (English)</td>
                                        </tr>
                                        <tr>
                                            <td>Jj</td>
                                            <td>je</td>
                                            <td>plea<span className="pf">s</span>ure</td>
                                            <td><span className="pf">j</span>imnastik (gymnastics)</td>
                                        </tr>
                                        <tr>
                                            <td>Kk</td>
                                            <td>ke</td>
                                            <td><span className="pf">k</span>ite</td>
                                            <td><span className="pf">k</span>itap (book)</td>
                                        </tr>
                                        <tr>
                                            <td>Ll</td>
                                            <td>le</td>
                                            <td><span className="pf">l</span>amb</td>
                                            <td><span className="pf">l</span>imon (lemon)</td>
                                        </tr>
                                        <tr>
                                            <td>Mm</td>
                                            <td>me</td>
                                            <td><span className="pf">m</span>other</td>
                                            <td><span className="pf">m</span>asa (desk)</td>
                                        </tr>
                                        <tr>
                                            <td>Nn</td>
                                            <td>ne</td>
                                            <td><span className="pf">n</span>est</td>
                                            <td><span className="pf">N</span>amaz (Prayer)</td>
                                        </tr>
                                        <tr>
                                            <td>Oo</td>
                                            <td>o</td>
                                            <td><span className="pf">o</span>range</td>
                                            <td><span className="pf">o</span>kul (school)</td>
                                        </tr>
                                        <tr>
                                            <td>&#214;&#246;</td>
                                            <td>&#246;</td>
                                            <td>c<span className="pf">ure</span></td>
                                            <td><span className="pf">&#246;</span>rdek (duck)</td>
                                        </tr>
                                        <tr>
                                            <td>Pp</td>
                                            <td>pe</td>
                                            <td><span className="pf">p</span>en</td>
                                            <td><span className="pf">p</span>armak (finger)</td>
                                        </tr>
                                        <tr>
                                            <td>Rr</td>
                                            <td>re</td>
                                            <td><span className="pf">r</span>ose</td>
                                            <td><span className="pf">r</span>esim (picture)</td>
                                        </tr>
                                        <tr>
                                            <td>Ss</td>
                                            <td>se</td>
                                            <td><span className="pf">s</span>occer</td>
                                            <td><span className="pf">s</span>u (water)</td>
                                        </tr>
                                        <tr>
                                            <td>&#350;&#351;</td>
                                            <td>&#351;e</td>
                                            <td><span className="pf">sh</span>oe</td>
                                            <td><span className="pf">&#351;</span>eker (sugar)</td>
                                        </tr>
                                        <tr>
                                            <td>Tt</td>
                                            <td>te</td>
                                            <td><span className="pf">t</span>rain</td>
                                            <td><span className="pf">t</span>op (ball)</td>
                                        </tr>
                                        <tr>
                                            <td>Uu</td>
                                            <td>u</td>
                                            <td>b<span className="pf">oo</span>k</td>
                                            <td><span className="pf">u</span>&#231;ak (airplane)</td>
                                        </tr>
                                        <tr>
                                            <td>&#220;&#252;</td>
                                            <td>&#252;</td>
                                            <td>c<span className="pf">u</span>te</td>
                                            <td><span className="pf">&#252;</span>lke (country)</td>
                                        </tr>
                                        <tr>
                                            <td>Vv</td>
                                            <td>ve</td>
                                            <td><span className="pf">w</span>indow</td>
                                            <td><span className="pf">v</span>azo (vase)</td>
                                        </tr>
                                        <tr>
                                            <td>Yy</td>
                                            <td>ye</td>
                                            <td><span className="pf">y</span>ellow</td>
                                            <td><span className="pf">y</span>emek (food)</td>
                                        </tr>
                                        <tr>
                                            <td>Zz</td>
                                            <td>ze</td>
                                            <td><span className="pf">z</span>ebra</td>
                                            <td><span className="pf">z</span>ambak (lily)</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}

export default Alphabet;