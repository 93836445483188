import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const DaysAndMonths = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Days And Months</h1>

                                <h2>Days</h2>
                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Monday</td><td>Pazartesi</td></tr>
                                        <tr><td>Tuesday</td><td>Sal&#305;</td></tr>
                                        <tr><td>Wednesday</td><td>&#199;ar&#351;amba</td></tr>
                                        <tr><td>Thursday</td><td>Per&#351;embe</td></tr>
                                        <tr><td>Friday</td><td>Cuma</td></tr>
                                        <tr><td>Saturday</td><td>Cumartesi</td></tr>
                                        <tr><td>Sunday</td><td>Pazar</td></tr>
                                    </tbody>
                                </Table>

                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Today</td><td>Bug&#252;n</td></tr>
                                        <tr><td>Yesterday</td><td>D&#252;n</td></tr>
                                        <tr><td>Tomorrow</td><td>Yar&#305;n</td></tr>
                                        <tr><td>Day before yesterday</td><td>&#214;nceki g&#252;n / Evvelsi g&#252;n</td></tr>
                                        <tr><td>Day after tomorrow</td><td>&#214;b&#252;r g&#252;n</td></tr>
                                    </tbody>
                                </Table>

                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Day</td><td>G&#252;n</td></tr>
                                        <tr><td>Week</td><td>Hafta</td></tr>
                                        <tr><td>Month</td><td>Ay</td></tr>
                                        <tr><td>Year</td><td>Y&#305;l / Sene</td></tr>
                                        <tr><td>Century</td><td>Y&#252;zy&#305;l</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Months</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>January</td><td>Ocak</td></tr>
                                        <tr><td>February</td><td>&#350;ubat</td></tr>
                                        <tr><td>March</td><td>Mart</td></tr>
                                        <tr><td>April</td><td>Nisan</td></tr>
                                        <tr><td>May</td><td>May&#305;s</td></tr>
                                        <tr><td>June</td><td>Haziran</td></tr>
                                        <tr><td>July</td><td>Temmuz</td></tr>
                                        <tr><td>August</td><td>A&#287;ustos</td></tr>
                                        <tr><td>September</td><td>Eyl&#252;l</td></tr>
                                        <tr><td>October</td><td>Ekim</td></tr>
                                        <tr><td>November</td><td>Kas&#305;m</td></tr>
                                        <tr><td>December</td><td>Aral&#305;k</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Seasons</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Spring</td><td>&#304;lkbahar</td></tr>
                                        <tr><td>Summer</td><td>Yaz</td></tr>
                                        <tr><td>Autumn</td><td>Sonbahar</td></tr>
                                        <tr><td>Winter</td><td>K&#305;&#351;</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Times of the day</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Sunrise</td><td>G&#252;ndo&#287;umu</td></tr>
                                        <tr><td>Morning</td><td>Sabah</td></tr>
                                        <tr><td>Midday</td><td>&#214;&#287;le / &#214;&#287;len</td></tr>
                                        <tr><td>Afternoon</td><td>&#214;&#287;leden sonra</td></tr>
                                        <tr><td>Evening</td><td>Ak&#351;am</td></tr>
                                        <tr><td>Sunset</td><td>G&#252;nbat&#305;m&#305;</td></tr>
                                        <tr><td>Night</td><td>Gece</td></tr>
                                        <tr><td>Midnight</td><td>Gece yar&#305;s&#305;</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Clock Time</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Second</td><td>Saniye</td></tr>
                                        <tr><td>Minute</td><td>Dakika</td></tr>
                                        <tr><td>Hour</td><td>Saat</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
