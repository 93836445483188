import { loadScript, initializeGTag, trackGTagEvent, initializeFacebookPixel } from '../utils/scriptUtils';

export const initializeGlobalScripts = () => {
  
  // Load and initialize Google Ads conversion tracking
  loadScript('https://www.googletagmanager.com/gtag/js?id=DC-13776505', () => {
    initializeGTag('DC-13776505');
    trackGTagEvent({
      allow_custom_scripts: true,
      send_to: 'DC-13776505/websi0/turki0+standard',
    });
  });
  
  // Load and initialize Google Analytics (GTag)
  loadScript('https://www.googletagmanager.com/gtag/js?id=G-6H5W0K21C7', () => {
    initializeGTag('G-6H5W0K21C7');
  });
  
  // Load AdSense
  // loadScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1979351441736704', () => {
    
    // Seems to only be used where the ad is displayed
    // window.adsbygoogle = window.adsbygoogle || [];
    // window.adsbygoogle.push({});
  // });
};