import { Card, Col, Container, Row } from "@themesberg/react-bootstrap";

const MeetingPeople = () => {
	return (<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Meeting People</h1>

							<p class='phrases'>
								<span class='c1'>Hello</span><br />
								<span className="text-secondary">Merhaba</span><br />
								<br />
								<span class='c1'>Good morning</span><br />
								<span className="text-secondary">G&#252;nayd&#305;n</span><br />
								<br />
								<span class='c1'>Good day</span><br />
								<span className="text-secondary">&#304;yi g&#252;nler</span><br />
								<br />
								<span class='c1'>Good evening</span><br />
								<span className="text-secondary">&#304;yi ak&#351;amlar</span><br />
								<br />
								<span class='c1'>What's up? (Informal)</span><br />
								<span className="text-secondary">Ne var ne yok?</span><br />
								<br />
								<span class='c1'>Welcome</span><br />
								<span className="text-secondary">Ho&#351; geldiniz</span><br />
								<br />
								<span class='c1'>Thank you (Reply to "Welcome")</span><br />
								<span className="text-secondary">Ho&#351; bulduk</span><br />
								<br />
							</p>

							<br /><br />

							<p class='phrases'>
								<span class='c1'>How are you?</span><br />
								<span className="text-secondary">Nas&#305;ls&#305;n&#305;z?</span><br />
								<br />
								<span class='c1'>I am fine thank you</span><br />
								<span className="text-secondary">Te&#351;ekk&#252;r ederim iyiyim</span><br />
								<br />
								<span class='c1'>What is your name?</span><br />
								<span className="text-secondary">Ad&#305;n&#305;z ne?</span><br />
								<br />
								<span class='c1'>My name is _______</span><br />
								<span className="text-secondary">Ad&#305;m _______</span><br />
								<br />
								<span class='c1'>How old are you?</span><br />
								<span className="text-secondary">Ka&#231; ya&#351;&#305;ndas&#305;n&#305;z?</span><br />
								<br />
								<span class='c1'>I am <span class="underline">20</span> years old</span><br />
								<span className="text-secondary">Ben <span class="underline">yirmi</span> ya&#351;&#305;nday&#305;m</span><br />
								<br />
								<span class='c1'>Where are you from?</span><br />
								<span className="text-secondary">Nerelisiniz?</span><br />
								<br />
								<span class='c1'>I speak English</span><br />
								<span className="text-secondary">Ben &#304;ngilizce konu&#351;uyorum</span><br />
								<br />
								<span class='c1'>I am learning Turkish</span><br />
								<span className="text-secondary">Ben T&#252;rk&#231;e &#246;&#287;reniyorum</span><br />
								<br />
								<span class='c1'>Pleased to meet you</span><br />
								<span className="text-secondary">Memnun oldum</span><br />
								<br />
								<span class='c1'>Pleased to meet you too</span><br />
								<span className="text-secondary">Ben de memnun oldum</span><br />
							</p>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container >
	);
}

export default MeetingPeople;