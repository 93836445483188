import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Occupations = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Occupations</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Accountant</td><td>Muhasebeci</td></tr>
                                        <tr><td>Businessman</td><td>&#304;&#351;adam&#305;</td></tr>
                                        <tr><td>Chef</td><td>A&#351;&#231;&#305;</td></tr>
                                        <tr><td>Dentist</td><td>Di&#351; hekimi</td></tr>
                                        <tr><td>Doctor</td><td>Doktor</td></tr>
                                        <tr><td>Engineer</td><td>M&#252;hendis</td></tr>
                                        <tr><td>Farmer</td><td>&#199;ift&#231;i</td></tr>
                                        <tr><td>Journalist</td><td>Gazeteci</td></tr>
                                        <tr><td>Lawyer</td><td>Avukat</td></tr>
                                        <tr><td>Nurse</td><td>Hem&#351;ire</td></tr>
                                        <tr><td>Researcher</td><td>Ara&#351;t&#305;rmac&#305;</td></tr>
                                        <tr><td>Student</td><td>&#214;&#287;renci</td></tr>
                                        <tr><td>Teacher</td><td>&#214;&#287;retmen</td></tr>
                                        <tr><td>Writer</td><td>Yazar</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
