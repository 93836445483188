import { Card, Col, Container, Row } from "@themesberg/react-bootstrap";
import SectionHeading from "../../../components/SectionHeading";

export const VarAndYok = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Var and Yok</h1>

                                <p>
                                    In Turkish, "var" and "yok" are used to express the presence and absence of things. There are no direct English equivalents so understanding these concepts are important in learning Turkish. "Var" and "yok" are generally placed at the end of the sentence. There are four cases to be considered:
                                </p>

                                <p>
                                    <ol>
                                        <li>Positive - Var</li>
                                        <li>Negative - Yok</li>
                                        <li>Positive Question - Var m&#305;</li>
                                        <li>Negative Question - Yok mu</li>
                                    </ol>
                                </p>


                                <SectionHeading title='1. Positive - Var' />

                                <p>
                                    <span className="red">Var</span> basically means "there is".
                                </p>

                                <p className='mt-4'>
                                    Examples:
                                </p>

                                <p className="scd">
                                    Garajda araba <span className="red">var</span> - There is a car in the garage<br />
                                    Parkta &#231;o&#231;uk <span className="red">var</span> - There is a child in the park<br />
                                    Futbol sahas&#305;nda top <span className="red">var</span> - There is a ball on the football field<br />
                                    Trende yolcu <span className="red">var</span> - There is a passenger on the train<br />
                                    Camide m&#252;ezzin <span className="red">var</span> - There is a muezzin in the mosque<br />
                                </p>



                                <SectionHeading title='2. Negative - Yok' />
                                <p>
                                    <span className="red">Yok</span> is used to mean "there isn't".
                                </p>

                                <p className='mt-4'>
                                    Examples:
                                </p>

                                <p className="scd">
                                    Garajda araba <span className="red">yok</span> - There isn't a car in the garage<br />
                                    Parkta &#231;o&#231;uk <span className="red">yok</span> - There isn't a child in the park<br />
                                    Futbol sahas&#305;nda top <span className="red">yok</span> - There isn't a ball on the football field<br />
                                    Trende yolcu <span className="red">yok</span> - There isn't a passenger on the train<br />
                                    Camide m&#252;ezzin <span className="red">yok</span> - There isn't a muezzin in the mosque<br />
                                </p>


                                <SectionHeading title='3. Positive Question - Var m&#305;' />
                                <p>
                                    In Turkish we use the different forms of "mi" to form questions ("m&#305;"/"mi"/"mu"/"m&#252;"). By applying the rules of vowel harmony we add "m&#305;" to "var" to get the positive question form <span className="red">var m&#305;</span>.
                                </p>

                                <p className='mt-4'>
                                    Examples:
                                </p>

                                <p className="scd">
                                    Garajda araba <span className="red">var m&#305;</span>? - Is there a car in the garage?<br />
                                    Parkta &#231;o&#231;uk <span className="red">var m&#305;</span>? - Is there a child in the park?<br />
                                    Futbol sahas&#305;nda top <span className="red">var m&#305;</span>? - Is there is a ball on the football field?<br />
                                    Trende yolcu <span className="red">var m&#305;</span>? - Is there is a passenger on the train?<br />
                                    Camide m&#252;ezzin <span className="red">var m&#305;</span>? - Is there is a muezzin in the mosque?<br />
                                </p>


                                <SectionHeading title='4. Negative Question - Yok mu' />
                                <p>
                                    To form the negative question form we add "mu" to "yok" to get <span className="red">yok mu</span>.
                                </p>

                                <p className='mt-4'>
                                    Examples:
                                </p>

                                <p className="scd">
                                    Garajda araba <span className="red">yok mu</span>? - Isn't there a car in the garage?<br />
                                    Parkta &#231;o&#231;uk <span className="red">yok mu</span>? - Isn't there a child in the park?<br />
                                    Futbol sahas&#305;nda top <span className="red">yok mu</span>? - Isn't there a ball on the football field?<br />
                                    Trende yolcu <span className="red">yok mu</span>? - Isn't there a passenger on the train?<br />
                                    Camide m&#252;ezzin <span className="red">yok mu</span>? - Isn't there a muezzin in the mosque?<br />
                                </p>

                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
