import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Colors = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Colors</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr className="bgwh"><td>White</td><td>Beyaz</td></tr>
                                        <tr className="bggrey"><td>Grey</td><td>Gri</td></tr>
                                        <tr className="bgturq"><td>Turquoise</td><td>T&#252;rk&#252;az</td></tr>
                                        <tr className="bgblu"><td>Blue</td><td>Mavi</td></tr>
                                        <tr className="bgna"><td>Navy</td><td>Lacivert</td></tr>
                                        <tr className="bggreen"><td>Green</td><td>Ye&#351;il</td></tr>
                                        <tr className="bgye"><td>Yellow</td><td>Sar&#305;</td></tr>
                                        <tr className="bgor"><td>Orange</td><td>Turuncu</td></tr>
                                        <tr className="bgre"><td>Red</td><td>K&#305;rm&#305;z&#305;</td></tr>
                                        <tr className="bgpi"><td>Pink</td><td>Pembe</td></tr>
                                        <tr className="bgpu"><td>Purple</td><td>Mor</td></tr>
                                        <tr className="bgbr"><td>Brown</td><td>Kahverengi</td></tr>
                                        <tr className="bgbla"><td>Black</td><td>Siyah</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
