
import React from 'react';
import { Row, Col, Card, Container, Table } from '@themesberg/react-bootstrap';

export const Imperative = () => (
	<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Imperative</h1>
							<p className="fs-5 fw-light">-</p>

							<div className="listandtable">
								<div className="listc">
									<p>
										In Turkish we use the imperative to give instructions or express a command. The imperative does not
										add any suffixes of its own to the verb root.
									</p>
									<ol>
										<li>Positive (E.g. Go!)</li>
										<li>Negative (E.g. Don't go!)</li>
									</ol>
								</div>
								<Table responsive hover striped size="sm" className="mt-4">
									<tbody>
										<tr className="bg1"><td>I</td><td className="tur">Ben</td></tr>
										<tr className="bg2"><td>You</td><td className="tur">Sen</td></tr>
										<tr className="bg1"><td>He / She / It</td><td className="tur">O</td></tr>
										<tr className="bg2"><td>We</td><td className="tur">Biz</td></tr>
										<tr className="bg1"><td>You (Plural)</td><td className="tur">Siz</td></tr>
										<tr className="bg2"><td>They</td><td className="tur">Onlar</td></tr>
									</tbody>
								</Table>
							</div>

							<h2 className="spaceabove"><a id="imperativepos" className="linktarget">Positive</a></h2>


							<p className="centerrule">
								[<span className="red">verb root</span> + <span className="red">personal suffix</span>]
							</p>

							<p>
								We don't add any suffix to the verb root for the imperative. We only attach the personal suffix depending on the last vowel of
								the verb root. Since the imperative expresses a command, it cannot be used for "ben" (I) or "biz" (we).
							</p>
							<p>
								For "sen" (you singular) there is no personal suffix either. So the imperative form consists of just the verb root.
							</p>
							<p>
								For "siz" (you plural), if the letter at the end of the verb root
								is a vowel we also first attach a "y" to prevent having two vowels side by side.
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Sen</td><td>-</td><td>-</td></tr>
									<tr className="bg2"><td className="colh">O</td><td>-sın</td><td>-sin</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-[y]ın</td><td>-[y]in</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-sınlar</td><td>-sinler</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Positive)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<p>
								<Table responsive hover striped size="sm" className="mt-4">
									<thead>
										<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">gelmek - to come</td></tr>
									</thead>
									<tbody>
										<tr><td className="tur">Sen yaz</td><td>Write!</td><td className="tur">Sen gel</td><td>Come!</td></tr>
										<tr><td className="tur">O yazsın</td><td>Let him/her/it write!</td><td className="tur">O gelsin</td><td>Let him/her/it come!</td></tr>
										<tr><td className="tur">Siz yazın</td><td>Write!</td><td className="tur">Siz gelin</td><td>Come!</td></tr>
										<tr><td className="tur">Onlar yazsınlar</td><td>Let them write!</td><td className="tur">Onlar gelsinler</td><td>Let them come!</td></tr>
									</tbody>
								</Table>
							</p>

							<p>
								For "siz", the buffer letter "y" is added when the last letter of the verb root is a vowel:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">ba&#351;lamak - to start</td><td colspan="2" className="head">izlemek - to watch</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Sen ba&#351;la</td><td>Start!</td><td className="tur">Sen izle</td><td>Watch!</td></tr>
									<tr><td className="tur">O ba&#351;lasın</td><td>Let him/her/it start!</td><td className="tur">O izlesin</td><td>Let him/her/it watch!</td></tr>
									<tr><td className="tur">Siz ba&#351;la<span className="y_underline">y</span>&#305;n&#305;z</td><td>Start!</td><td className="tur">Siz izle<span className="y_underline">y</span>in</td><td>Watch!</td></tr>
									<tr><td className="tur">Onlar ba&#351;lasınlar</td><td>Let them start!</td><td className="tur">Onlar izlesinler</td><td>Let them watch!</td></tr>
								</tbody>
							</Table>

							<h2 className="spaceabove"><a id="imperativeneg" className="linktarget">Negative</a></h2>

							<p className="centerrule">
								[<span className="red">verb root</span> + <span className="red">"ma/me"</span> + <span className="red">personal suffix</span>]
							</p>

							<p>
								For negative, we attach the negative suffix "ma/me" to the verb root depending on its last vowel. Thereafter
								we attach the personal suffix. Once again there is no suffix for the imperative itself.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Sen</td><td>-ma</td><td>-me</td></tr>
									<tr className="bg2"><td className="colh">O</td><td>-masın</td><td>-mesin</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-mayın</td><td>-meyin</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-masınlar</td><td>-mesinler</td></tr>
								</tbody>
								<caption>Suffixes added to the verb root (Negative)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">gelmek - to come</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Sen yazma</td><td>Don't write!</td><td className="tur">Sen gelme</td><td>Don't come!</td></tr>
									<tr><td className="tur">O yazmasın</td><td>Don't let him/her/it write!</td><td className="tur">O gelmesin</td><td>Don't let him/her/it come!</td></tr>
									<tr><td className="tur">Siz yazmayın</td><td>Don't write!</td><td className="tur">Siz gelmeyin</td><td>Don't come!</td></tr>
									<tr><td className="tur">Onlar yazmasınlar</td><td>Don't let them write!</td><td className="tur">Onlar gelmesinler</td><td>Don't let them come!</td></tr>
								</tbody>
							</Table>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container>
);
