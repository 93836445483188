import { Card, Col, Container, Row } from "@themesberg/react-bootstrap";

export const TellingTheTime = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Telling the time</h1>

                                <p>
                                    There are generally two ways of telling the time in Turkish:
                                </p>

                                <ol>
                                    <li>As an answer to the question <span className="red">"What is the time?"</span></li>
                                    <li>By specifying <span className="red">"At what time(?)"</span> something will happen</li>
                                </ol>

                                <p>
                                    Both rules make use of vowel harmony.
                                </p>

                                <br />

                                <h3>1. What is the time? - Saat ka&#231;?</h3>

                                <p>
                                    This is used when we want to tell the current time.
                                </p>

                                <p>
                                    <span className="underline">On the hour:</span>
                                </p>

                                <p>
                                    [<span className="red">"Saat"</span>] [<span className="red">hour</span>]
                                </p>

                                <p>
                                    03:00 : Saat &#252;&#231;<br />
                                    05:00 : Saat be&#351;<br />
                                    10:00 : Saat on<br />
                                    18:00 : Saat alt&#305;<br />
                                    21:00 : Saat dokuz<br />
                                </p>

                                <p>
                                    <span className="underline">Quarter past:</span>
                                </p>

                                <p>
                                    [<span className="red">"Saat"</span>] [<span className="red">hour</span> + <span className="red">"&#305;/i/u/&#252;"</span>] [<span className="red">"&#231;eyrek ge&#231;iyor"</span>]
                                </p>

                                <p>
                                    03:15 : Saat &#252;&#231;&#252; &#231;eyrek ge&#231;iyor<br />
                                    05:15 : Saat be&#351;i &#231;eyrek ge&#231;iyor<br />
                                    10:15 : Saat onu &#231;eyrek ge&#231;iyor<br />
                                    18:15 : Saat alt&#305;y&#305; &#231;eyrek ge&#231;iyor<br />
                                    21:15 : Saat dokuzu &#231;eyrek ge&#231;iyor<br />
                                </p>

                                <p>
                                    <span className="underline">Half past:</span>
                                </p>

                                <p>
                                    [<span className="red">"Saat"</span>] [<span className="red">hour</span>] [<span className="red">"bu&#231;uk"</span>]
                                </p>

                                <p>
                                    03:30 : Saat &#252;&#231; bu&#231;uk<br />
                                    05:30 : Saat be&#351; bu&#231;uk<br />
                                    10:30 : Saat on bu&#231;uk<br />
                                    18:30 : Saat alt&#305; bu&#231;uk<br />
                                    21:30 : Saat dokuz bu&#231;uk<br />
                                </p>

                                <p>
                                    <span className="underline">Quarter to:</span>
                                </p>

                                <p>
                                    [<span className="red">"Saat"</span>] [<span className="red">hour</span> + <span className="red">"a/e"</span>] [<span className="red">"&#231;eyrek var"</span>]
                                </p>

                                <p>
                                    03:45 : Saat d&#246;rde &#231;eyrek var<br />
                                    05:45 : Saat alt&#305;ya &#231;eyrek var<br />
                                    10:45 : Saat on bire &#231;eyrek var<br />
                                    18:45 : Saat yediye &#231;eyrek var<br />
                                    21:45 : Saat ona &#231;eyrek var<br />
                                </p>

                                <p>
                                    <span className="underline">Examples</span>
                                </p>

                                <p>
                                    The time is <span className="red">09:00</span> - <span className="red">Saat dokuz</span><br />
                                    The time is <span className="red">13:15</span> - <span className="red">Saat biri &#231;eyrek ge&#231;iyor</span><br />
                                    The time is <span className="red">15:30</span> - <span className="red">Saat &#252;&#231; bu&#231;uk</span><br />
                                    The time is <span className="red">23:45</span> - <span className="red">Saat on ikiye &#231;eyrek var</span><br />
                                </p>


                                <br />

                                <h3>2. At what time? - Saat ka&#231;ta?</h3>

                                <p>
                                    We use this to specify the time at which something will happen or take place.
                                </p>

                                <p>
                                    <span className="underline">On the hour:</span>
                                </p>

                                <p>
                                    [<span className="red">"Saat"</span>] [<span className="red">hour</span> + <span className="red">"da/de/ta/te"</span>]
                                </p>

                                <p>
                                    03:00 : Saat &#252;&#231;te<br />
                                    05:00 : Saat be&#351;te<br />
                                    10:00 : Saat onda<br />
                                    18:00 : Saat alt&#305;da<br />
                                    21:00 : Saat dokuzda<br />
                                </p>

                                <p>
                                    <span className="underline">Quarter past:</span>
                                </p>

                                <p>
                                    [<span className="red">"Saat"</span>] [<span className="red">hour</span> + <span className="red">"&#305;/i/u/&#252;"</span>] [<span className="red">"&#231;eyrek ge&#231;e"</span>]
                                </p>

                                <p>
                                    03:15 : Saat &#252;&#231;&#252; &#231;eyrek ge&#231;e<br />
                                    05:15 : Saat be&#351;i &#231;eyrek ge&#231;e<br />
                                    10:15 : Saat onu &#231;eyrek ge&#231;e<br />
                                    18:15 : Saat alt&#305;y&#305; &#231;eyrek ge&#231;e<br />
                                    21:15 : Saat dokuzu &#231;eyrek ge&#231;e<br />
                                </p>

                                <p>
                                    <span className="underline">Half past:</span>
                                </p>

                                <p>
                                    [<span className="red">"Saat"</span>] [<span className="red">hour</span>] [<span className="red">"bu&#231;ukta"</span>]
                                </p>

                                <p>
                                    03:30 : Saat &#252;&#231; bu&#231;ukta<br />
                                    05:30 : Saat be&#351; bu&#231;ukta<br />
                                    10:30 : Saat on bu&#231;ukta<br />
                                    18:30 : Saat alt&#305; bu&#231;ukta<br />
                                    21:30 : Saat dokuz bu&#231;ukta<br />
                                </p>

                                <p>
                                    <span className="underline">Quarter to:</span>
                                </p>

                                <p>
                                    [<span className="red">"Saat"</span>] [<span className="red">hour</span> + <span className="red">"a/e"</span>] [<span className="red">"&#231;eyrek kala"</span>]
                                </p>

                                <p>
                                    03:45 : Saat d&#246;rde &#231;eyrek kala<br />
                                    05:45 : Saat alt&#305;ya &#231;eyrek kala<br />
                                    10:45 : Saat on bire &#231;eyrek kala<br />
                                    18:45 : Saat yediye &#231;eyrek kala<br />
                                    21:45 : Saat ona &#231;eyrek kala<br />
                                </p>

                                <p>
                                    <span className="underline">Examples</span>
                                </p>

                                <p>
                                    Lessons start at <span className="red">09:00</span> - Ders <span className="red">saat dokuzda</span> ba&#351;lar<br />
                                    The bell rings at <span className="red">13:15</span> - Zil <span className="red">saat biri &#231;eyrek ge&#231;e</span> &#231;alar<br />
                                    Azan is at <span className="red">15:30</span> - Ezan <span className="red">saat &#252;&#231; bu&#231;ukta</span> okunur<br />
                                    The match ends at <span className="red">23:45</span> - Ma&#231; <span className="red">saat on ikiye &#231;eyrek kala</span> biter<br />
                                </p>

                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
