
import React from 'react';
import { Row, Col, Card, Container, Table } from '@themesberg/react-bootstrap';

import Positive from "../../../assets/img/content/rules/verbs/if/turkish_verbs_saying_if.jpg";
import ImageRule from '../../../components/ImageRule';
import { Link } from 'react-router-dom';

export const If = () => (
	<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>"If"</h1>
							<p className="fs-5 fw-light">(sa/se)</p>

							<p>
								We say "if" in Turkish by attaching the suffixes "sa/se" to the verb. "sa/se" can be used with both positive and negative verbs and is
								attached after the tense suffix. We choose the correct one based on the last vowel before it i.e. in the tense suffix.
								Onlar is an exception, we attach "sa/se" right at the end.
							</p>

							<ImageRule src={Positive} />
							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
								<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
								<tr className="bg1"><td className="colh">Ben</td><td>-sam</td><td>-sem</td></tr>
								<tr className="bg2"><td className="colh">Sen</td><td>-san</td><td>-sen</td></tr>
								<tr className="bg1"><td className="colh">O</td><td>-sa</td><td>-se</td></tr>
								<tr className="bg2"><td className="colh">Biz</td><td>-sak</td><td>-sek</td></tr>
								<tr className="bg1"><td className="colh">Siz</td><td>-san&#305;z</td><td>-seniz</td></tr>
								<tr className="bg2"><td className="colh">Onlar</td><td>-larsa</td><td>-lerse</td></tr>
								</tbody>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<p>
								In Turkish, "sa/se" is most often used with the <Link to={"./present-simple-tense"} >present simple tense</Link>:
							</p>

							<p className="scd">
								Ben yazar<span className="red">sa</span>m  - If I write<br />
								Sen duyar<span className="red">sa</span>n - If you hear<br />
								Onlar gelirler<span className="red">se</span> - If they come<br />
								Siz izler<span className="red">se</span>niz - If you watch<br />
								<br />
								Ben unutmaz<span className="red">sa</span>m - If I don't forget<br />
								Sen istemez<span className="red">se</span>n - If you don't want<br />
								O okumaz<span className="red">sa</span> - If he/she/it does not read<br />
								Biz d&#252;&#351;&#252;nmez<span className="red">se</span>k - If we don't think
							</p>

							<p>
								...however, it could be applied to any of the tenses:
							</p>

							<p className="scd">
								Ben gidecek<span className="red">se</span>m - If I will go<br />
								Sen ba&#351;lad&#305;y<span className="red">sa</span>n - If you started<br />
								Biz ko&#351;uyor<span className="red">sa</span>k - If we are running<br />
								Onlar &#246;demi&#351;ler<span className="red">se</span> - If they paid<br />
								<br />
								Ben g&#246;rmeyecek<span className="red">se</span>m - If I will not see<br />
								Sen bulmad&#305;y<span className="red">sa</span>n - If you did not find<br />
								O be&#287;enmemi&#351;<span className="red">se</span> - If he/she/it did not like<br />
								Siz kullanm&#305;yor<span className="red">sa</span>n&#305;z - If you are not using
							</p>

						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container >
);
