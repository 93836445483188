import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const School = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>School</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Bell</td><td>Zil</td></tr>
                                        <tr><td>Blackboard</td><td>Tahta</td></tr>
                                        <tr><td>Chair</td><td>Sandalye</td></tr>
                                        <tr><td>Class / Grade</td><td>S&#305;n&#305;f</td></tr>
                                        <tr><td>Desk</td><td>Masa</td></tr>
                                        <tr><td>Elementary School</td><td>&#304;lkokul</td></tr>
                                        <tr><td>Holiday</td><td>Tatil</td></tr>
                                        <tr><td>Homework</td><td>&#214;dev</td></tr>
                                        <tr><td>Lesson</td><td>Ders</td></tr>
                                        <tr><td>Middle School</td><td>Ortaokul</td></tr>
                                        <tr><td>Principal</td><td>Okul m&#252;d&#252;r&#252;</td></tr>
                                        <tr><td>Recess / Break</td><td>Teneff&#252;s</td></tr>
                                        <tr><td>School</td><td>Okul</td></tr>
                                        <tr><td>Secondary School / High School</td><td>Lise</td></tr>
                                        <tr><td>Student</td><td>&#214;&#287;renci</td></tr>
                                        <tr><td>Subject</td><td>Ders</td></tr>
                                        <tr><td>Teacher</td><td>&#214;&#287;retmen</td></tr>
                                        <tr><td>Test</td><td>S&#305;nav</td></tr>
                                        <tr><td>Uniform</td><td>K&#305;yafet</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Stationery</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Chalk</td><td>Tebe&#351;ir</td></tr>
                                        <tr><td>Eraser</td><td>Silgi</td></tr>
                                        <tr><td>Glue</td><td>Tutkal</td></tr>
                                        <tr><td>Notebook</td><td>Defter</td></tr>
                                        <tr><td>Pen</td><td>Kalem</td></tr>
                                        <tr><td>Pencil</td><td>Kur&#351;un kalem</td></tr>
                                        <tr><td>Ruler</td><td>Cetvel</td></tr>
                                        <tr><td>Scissor</td><td>Makas</td></tr>
                                        <tr><td>Sharpener</td><td>Kalemt&#305;ra&#351;</td></tr>
                                        <tr><td>Textbook</td><td>Kitap</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Subjects</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Biology</td><td>Biyoloji</td></tr>
                                        <tr><td>Chemistry</td><td>Kimya</td></tr>
                                        <tr><td>English</td><td>&#304;ngilizce</td></tr>
                                        <tr><td>French</td><td>Frans&#305;zca</td></tr>
                                        <tr><td>Geography</td><td>Co&#287;rafya</td></tr>
                                        <tr><td>Geometry</td><td>Geometri</td></tr>
                                        <tr><td>German</td><td>Almanca</td></tr>
                                        <tr><td>History</td><td>Tarih</td></tr>
                                        <tr><td>Literature</td><td>Edebiyat</td></tr>
                                        <tr><td>Mathematics</td><td>Matematik</td></tr>
                                        <tr><td>Philosophy</td><td>Felsefe</td></tr>
                                        <tr><td>Physics</td><td>Fizik</td></tr>
                                        <tr><td>Spanish</td><td>&#304;spanyolca</td></tr>
                                        <tr><td>Turkish</td><td>T&#252;rk&#231;e</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
