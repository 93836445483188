import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Airport = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Airport</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Aeroplane</td><td>U&#231;ak</td></tr>
                                        <tr><td>Airline</td><td>Havayolu</td></tr>
                                        <tr><td>Airport</td><td>Havaalan&#305; / Havaliman&#305;</td></tr>
                                        <tr><td>Arrival</td><td>Var&#305;&#351;</td></tr>
                                        <tr><td>Customs</td><td>G&#252;mr&#252;k</td></tr>
                                        <tr><td>Departure</td><td>Kalk&#305;&#351;</td></tr>
                                        <tr><td>Domestic</td><td>Yurti&#231;i</td></tr>
                                        <tr><td>Flight</td><td>U&#231;u&#351;</td></tr>
                                        <tr><td>Hand luggage</td><td>El bagaj&#305;</td></tr>
                                        <tr><td>International</td><td>Yurtd&#305;&#351;&#305;</td></tr>
                                        <tr><td>Luggage</td><td>Bagaj</td></tr>
                                        <tr><td>Passenger</td><td>Yolcu</td></tr>
                                        <tr><td>Passport</td><td>Pasaport</td></tr>
                                        <tr><td>Runway</td><td>Pist</td></tr>
                                        <tr><td>Security Control</td><td>G&#252;venlik Kontrol&#252;</td></tr>
                                        <tr><td>Terminal</td><td>Terminal</td></tr>
                                        <tr><td>Ticket</td><td>Bilet</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
