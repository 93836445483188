import { Card, Col, Container, Row } from "@themesberg/react-bootstrap";

const BasicPhrases = () => {
	return (<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Basic Phrases</h1>

							<p class='phrases'>
								<span>Hello</span><br />
								<span className="text-secondary">Merhaba</span><br />
								<br />
								<span>My name is _____</span><br />
								<span className="text-secondary">Benim ad&#305;m _____</span><br />
								<br />
								<span>How are you?</span><br />
								<span className="text-secondary">Nas&#305;ls&#305;n&#305;z?</span><br />
								<br />
								<span>I am fine thank you</span><br />
								<span className="text-secondary">Te&#351;ekk&#252;r ederim iyiyim</span><br />
								<br />
								<span>Pleased to meet you</span><br />
								<span className="text-secondary">Memnun oldum</span><br />
								<br />
								<span>Pleased to meet you too</span><br />
								<span className="text-secondary">Ben de memnun oldum</span><br />
								<br />
								<span>The weather is really nice today</span><br />
								<span className="text-secondary">Bug&#252;n hava &#231;ok g&#252;zel</span><br />
								<br />
								<span>I speak English</span><br />
								<span className="text-secondary">Ben &#304;ngilizce konu&#351;uyorum</span><br />
								<br />
								<span>I am learning Turkish</span><br />
								<span className="text-secondary">Ben T&#252;rk&#231;e &#246;&#287;reniyorum</span><br />
								<br />
								<span>See you</span><br />
								<span className="text-secondary">G&#246;r&#252;&#351;&#252;r&#252;z</span><br />
								<br />
							</p>

							<br /><br />

							<p class='phrases'>
								<span>What's this?</span><br />
								<span className="text-secondary">Bu ne?</span><br />
								<br />
								<span>This is a _______</span><br />
								<span className="text-secondary">Bu _______</span><br />
								<br />
								<span>Can you help me?</span><br />
								<span className="text-secondary">Bana yard&#305;mc&#305; olabilir misiniz?</span><br />
								<br />
								<span>What does this mean?</span><br />
								<span className="text-secondary">Bu ne demek?</span><br />
								<br />
								<span>I understand</span><br />
								<span className="text-secondary">Anlad&#305;m</span><br />
								<br />
								<span>I don't understand</span><br />
								<span className="text-secondary">Anlamad&#305;m</span><br />
								<br />
								<span>Do you have a _______?</span><br />
								<span className="text-secondary">_______ var m&#305;?</span><br />
								<br />
							</p>

							<br /><br />

							<p class='phrases'>
								<span>Yes</span><br />
								<span className="text-secondary">Evet</span><br />
								<br />
								<span>No</span><br />
								<span className="text-secondary">Hay&#305;r</span><br />
								<br />
								<span>Please</span><br />
								<span className="text-secondary">L&#252;tfen</span><br />
								<br />
								<span>Thank you</span><br />
								<span className="text-secondary">Te&#351;ekk&#252;r ederim</span><br />
								<br />
								<span>You're welcome</span><br />
								<span className="text-secondary">Rica ederim</span><br />
							</p>

						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container >
	);
}

export default BasicPhrases;