
import { Card, Col, Row } from '@themesberg/react-bootstrap';
import moment from "moment-timezone";
import React from "react";

export default () => {
  const currentYear = moment().get("year");

  return (
    <footer className="footer section py-5">
      <Row>
        <Col xs={12} lg={6} className="mb-4 mb-lg-0">
          <p className="mb-0 text-center text-xl-left">
            Copyright © 2011-{`${currentYear} `} Turkish Basics
          </p>
        </Col>
        {/* <Col xs={12} lg={6}>
          <ul className="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
            <li className="list-inline-item px-0 px-sm-2">
              <Card.Link href="https://themesberg.com/about" target="_blank">
                About
              </Card.Link>
            </li>
          </ul>
        </Col> */}
      </Row>
    </footer>
  );
};
