import { Table } from "@themesberg/react-bootstrap";

const PersonalPronounsTable = () => {
    return (
        <div class="tablec">
            <Table striped class="pronouns2col">
                <tbody>
                    <tr>
                        <td>I</td>
                        <td>Ben</td>
                    </tr>
                    <tr>
                        <td>You</td>
                        <td>Sen</td>
                    </tr>
                    <tr>
                        <td>He / She / It</td>
                        <td>O</td>
                    </tr>
                    <tr>
                        <td>We</td>
                        <td>Biz</td>
                    </tr>
                    <tr>
                        <td>You (Plural)</td>
                        <td>Siz</td>
                    </tr>
                    <tr>
                        <td>They</td>
                        <td>Onlar</td>
                    </tr>
                </tbody>
                {/* <caption>Personal pronouns</caption> */}
            </Table>
        </div>
    );
}

export default PersonalPronounsTable;