import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const PartsOfTheBody = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Parts Of The Body</h1>

                                <h2>Head and face</h2>
                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Beard</td><td>Sakal</td></tr>
                                        <tr><td>Brain</td><td>Beyin</td></tr>
                                        <tr><td>Chin</td><td>&#199;ene</td></tr>
                                        <tr><td>Ear</td><td>Kulak</td></tr>
                                        <tr><td>Eye</td><td>G&#246;z</td></tr>
                                        <tr><td>Face</td><td>Y&#252;z</td></tr>
                                        <tr><td>Hair</td><td>Sa&#231;</td></tr>
                                        <tr><td>Head</td><td>Ba&#351;</td></tr>
                                        <tr><td>Lip</td><td>Dudak</td></tr>
                                        <tr><td>Moustache</td><td>B&#305;y&#305;k</td></tr>
                                        <tr><td>Mouth</td><td>A&#287;&#305;z</td></tr>
                                        <tr><td>Neck</td><td>Boyun</td></tr>
                                        <tr><td>Nose</td><td>Burun</td></tr>
                                        <tr><td>Tongue</td><td>Dil</td></tr>
                                        <tr><td>Tooth</td><td>Di&#351;</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Upper Body</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Arm</td><td>Kol</td></tr>
                                        <tr><td>Chest</td><td>G&#246;&#287;&#252;s</td></tr>
                                        <tr><td>Elbow</td><td>Dirsek</td></tr>
                                        <tr><td>Finger</td><td>Parmak</td></tr>
                                        <tr><td>Hand</td><td>El</td></tr>
                                        <tr><td>Heart</td><td>Kalp</td></tr>
                                        <tr><td>Shoulder</td><td>Omuz</td></tr>
                                        <tr><td>Stomach</td><td>Mide</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Lower Body</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Ankle</td><td>Ayak bile&#287;i</td></tr>
                                        <tr><td>Foot</td><td>Ayak</td></tr>
                                        <tr><td>Heel</td><td>Topuk</td></tr>
                                        <tr><td>Knee</td><td>Diz</td></tr>
                                        <tr><td>Leg</td><td>Bacak</td></tr>
                                        <tr><td>Toe</td><td>Ayak parma&#287;&#305;</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
