import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Islam = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Islam</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Muslim</td><td>M&#252;sl&#252;man</td></tr>
                                        <tr><td>Islam</td><td>&#304;slam</td></tr>
                                        <tr><td>Quran</td><td>Kur'&#226;n</td></tr>
                                        <tr><td>Sunnah</td><td>S&#252;nnet</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Mosque</h2>
                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Ablution (Wuzu)</td><td>Abdest</td></tr>
                                        <tr><td>Call to prayer</td><td>Ezan</td></tr>
                                        <tr><td>Dome</td><td>Kubbe</td></tr>
                                        <tr><td>Imam</td><td>&#304;mam</td></tr>
                                        <tr><td>Minaret</td><td>Minare</td></tr>
                                        <tr><td>Mosque</td><td>Cami</td></tr>
                                        <tr><td>Muazzin</td><td>M&#252;ezzin</td></tr>
                                        <tr><td>Niche</td><td>Mihrap</td></tr>
                                        <tr><td>Pulpit</td><td>Minber</td></tr>
                                        <tr><td>Salaah (Prayer)</td><td>Namaz</td></tr>
                                    </tbody>
                                </Table>


                                <h2>Salaah (Prayer)</h2>
                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Fajr salaah</td><td>Sabah namaz&#305;</td></tr>
                                        <tr><td>Thuhr salaah</td><td>&#214;&#287;le namaz&#305;</td></tr>
                                        <tr><td>Asr salaah</td><td>&#304;kindi namaz&#305;</td></tr>
                                        <tr><td>Maghrib salaah</td><td>Ak&#351;am namaz&#305;</td></tr>
                                        <tr><td>Esha salaah</td><td>Yats&#305; namaz&#305;</td></tr>
                                        <tr><td>To perform salaah</td><td>Namaz k&#305;lmak</td></tr>
                                    </tbody>
                                </Table>


                                <h2>Ramadan (Fasting Month)</h2>
                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>To Fast</td><td>Oru&#231; tutmak</td></tr>
                                        <tr><td>Iftar</td><td>&#304;ftar</td></tr>
                                        <tr><td>Laylatul Qadr</td><td>Kadir gecesi</td></tr>
                                        <tr><td>Ramadan</td><td>Ramazan</td></tr>
                                        <tr><td>Ramadan Mubarak</td><td>Ramazan&#305;n&#305;z M&#252;barek Olsun</td></tr>
                                        <tr><td>Suhoor</td><td>Sahur</td></tr>
                                        <tr><td>Taraweeh salaah</td><td>Teravih namaz&#305;</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Special Occasions</h2>
                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Eid Al-Fitr</td><td>Ramazan Bayram&#305;</td></tr>
                                        <tr><td>Eid Al-Adha</td><td>Kurban Bayram&#305;</td></tr>
                                        <tr><td>Eid Mubarak</td><td>Bayram&#305;n&#305;z M&#252;barek Olsun</td></tr>
                                        <tr><td>Jumuah</td><td>Cuma</td></tr>
                                        <tr><td>Jumuah Mubarakah</td><td>Cuman&#305;z M&#252;barek Olsun</td></tr>
                                        <tr><td>Hajj</td><td>Hac</td></tr>
                                        <tr><td>Umrah</td><td>Umre</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Months (Hijri)</h2>
                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Muharram</td><td>Muharrem</td></tr>
                                        <tr><td>Safar</td><td>Safer</td></tr>
                                        <tr><td>Rabi'ul Awwal</td><td>Rebi&#252;levvel</td></tr>
                                        <tr><td>Rabi'ul Akhir</td><td>Rebi&#252;lahir</td></tr>
                                        <tr><td>Jumadul Awwal</td><td>Cemaziyelevvel</td></tr>
                                        <tr><td>Jumadul Akhir</td><td>Cemaziyelahir</td></tr>
                                        <tr><td>Rajab</td><td>Recep</td></tr>
                                        <tr><td>Shabaan</td><td>&#350;aban</td></tr>
                                        <tr><td>Ramadan</td><td>Ramazan</td></tr>
                                        <tr><td>Shawwal</td><td>&#350;evval</td></tr>
                                        <tr><td>Dhul Qadah</td><td>Zilkade</td></tr>
                                        <tr><td>Dhul Hijjah</td><td>Zilhicce</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Clothing</h2>
                                <Table responsive hover striped size="sm" >
                                    <tbody>
                                        <tr><td>Headscarf</td><td>E&#351;arp / Ba&#351;&#246;rt&#252;s&#252;</td></tr>
                                        <tr><td>Niqab</td><td>&#199;ar&#351;af</td></tr>
                                        <tr><td>Jubbah / Kurta</td><td>C&#252;bbe</td></tr>
                                        <tr><td>Turban</td><td>Sar&#305;k</td></tr>
                                        <tr><td>Taqiyah / Cap</td><td>Takke</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
