
import React from 'react';
import { Row, Col, Card, Container, Table } from '@themesberg/react-bootstrap';

import Positive from "../../../assets/img/content/rules/verbs/def_past/turkish_verbs_definite_past_tense_pos.jpg";
import Negative from "../../../assets/img/content/rules/verbs/def_past/turkish_verbs_definite_past_tense_pos_ques.jpg";
import PositiveQuestion from "../../../assets/img/content/rules/verbs/def_past/turkish_verbs_definite_past_tense_neg.jpg";
import NegativeQuestion from "../../../assets/img/content/rules/verbs/def_past/turkish_verbs_definite_past_tense_neg_ques.jpg";
import ImageRule from '../../../components/ImageRule';
import SectionHeading from '../../../components/SectionHeading';

export const DefinitePastTense = () => (
  <Container className="px-0">
    <Row>
      <Col xs={12} className="p-3">
        <Card>
          <Card.Body>
            <article>
              <h1>Definite Past Tense</h1>
              <p className="fs-5 fw-light">(dı/di/du/dü)</p>

              <p>
                There are two types of past tense in Turkish: definite past tense and reported past tense.
                Definite past tense is similar to ordinary past tense in English.
                We use it when we know for sure that something has taken place. In other words when there is certainty about something that has happened in the past.
              </p>

              <p>The definite past tense attaches the suffixes "d&#305;/di/du/d&#252;" to the verb root.
                We obtain the verb root by removing "mak/mek" from the infinitive form. For example, the verb root of "yazmak" would be "yaz", "d&#252;&#351;mek" would be "d&#252;&#351;" and "izlemek" would be "izle".</p>

              <p>
                We can express the definite past tense in four ways:
              </p>


              <ol className="ps-4 docs-list">
                <li>Positive (Eg. I wrote)</li>
                <li>Negative (Eg. I did not write)</li>
                <li>Positive Question (Eg. Did I write?)</li>
                <li>Negative Question (Eg. Did I not write?)</li>
              </ol>



              <SectionHeading title='Positive' />
              <ImageRule src={Positive} />

              <p>
                For positive, we focus on two particular letters in the verb root. Firstly, we focus on its <span className="empha">last vowel</span>.
                Secondly, we take note of its <span className="empha">last letter</span>.
              </p>

              <p>
                Based on the last vowel, we attach one of "d&#305;/di/du/d&#252;" to the verb root. This is done to preserve vowel harmony.
              </p>

              <p>
                If the very last letter of the verb root is one of "&#231;/f/h/k/s/&#351;/t/p", we replace the "d" in the definite past tense suffixes with a "t".
                Our suffixes then become "t&#305;/ti/tu/t&#252;". A helpful method of memorizing these letters is by remembering the phrase "<span className="empha">f</span>&#305;<span className="empha">st</span>&#305;<span className="empha">k&#231;</span>&#305; <span className="empha">&#351;</span>a<span className="empha">h</span>a<span className="empha">p</span>".
              </p>

              <p>
                Lastly we attach the personal suffix. Each personal pronoun has its own set of personal suffixes.
              </p>

              <Table responsive hover striped size="sm" className="mt-4">
                <thead>
                  <tr><td></td><td>a/&#305;</td><td>e/i</td><td>o/u</td><td>&#246;/&#252;</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben</td><td>-d&#305;m</td><td>-dim</td><td>-dum</td><td>-d&#252;m</td></tr>
                  <tr><td>Sen</td><td>-d&#305;n</td><td>-din</td><td>-dun</td><td>-d&#252;n</td></tr>
                  <tr><td>O</td><td>-d&#305;</td><td>-di</td><td>-du</td><td>-d&#252;</td></tr>
                  <tr><td>Biz</td><td>-d&#305;k</td><td>-dik</td><td>-duk</td><td>-d&#252;k</td></tr>
                  <tr><td>Siz</td><td>-d&#305;n&#305;z</td><td>-diniz</td><td>-dunuz</td><td>-d&#252;n&#252;z</td></tr>
                  <tr><td>Onlar</td><td>-d&#305;lar</td><td>-diler</td><td>-dular</td><td>-d&#252;ler</td></tr>
                </tbody>
              </Table>

              <p className='mt-4'>
                Examples:
              </p>

              <Table responsive hover striped size="sm" className="mt-4">
                <thead>
                  <tr><td colSpan={2}>yazmak - to write</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben yazd&#305;m</td><td>I wrote</td></tr>
                  <tr><td>Sen yazd&#305;n</td><td>You wrote</td></tr>
                  <tr><td>O yazd&#305;</td><td>He/she/it wrote</td></tr>
                  <tr><td>Biz yazd&#305;k</td><td>We wrote</td></tr>
                  <tr><td>Siz yazd&#305;n&#305;z</td><td>You wrote</td></tr>
                  <tr><td>Onlar yazd&#305;lar</td><td>They wrote</td></tr>
                </tbody>
              </Table>

              <Table responsive hover striped size="sm" className="mt-4">
                <thead>
                  <tr><td colSpan={2}>izlemek - to watch</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben izledim</td><td>I watched</td></tr>
                  <tr><td>Sen izledin</td><td>You watched</td></tr>
                  <tr><td>O izledi</td><td>He/she/it watched</td></tr>
                  <tr><td>Biz izledik</td><td>We watched</td></tr>
                  <tr><td>Siz izlediniz</td><td>You watched</td></tr>
                  <tr><td>Onlar izlediler</td><td>They watched</td></tr>
                </tbody>
              </Table>

              <p className='mt-4'>
                If the letter at the end of the verb root is one of &#231;, f, h, k, s, &#351;, t, p, we replace the "d" in the definite past tense suffix with a "t":
              </p>

              <Table responsive hover striped size="sm" className="mt-4">
                <thead>
                  <tr><td colSpan={2}>konu&#351;mak - to speak</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben konu&#351;tum</td><td>I spoke</td></tr>
                  <tr><td>Sen konu&#351;tun</td><td>You spoke</td></tr>
                  <tr><td>O konu&#351;tu</td><td>He/she/it spoke</td></tr>
                  <tr><td>Biz konu&#351;tuk</td><td>We spoke</td></tr>
                  <tr><td>Siz konu&#351;tunuz</td><td>You spoke</td></tr>
                  <tr><td>Onlar konu&#351;tular</td><td>They spoke</td></tr>
                </tbody>
              </Table>

              <Table responsive hover striped size="sm" className="mt-4">
                <thead>
                  <tr><td colSpan={2}>d&#252;&#351;mek - to fall</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben d&#252;&#351;t&#252;m</td><td>I fell</td></tr>
                  <tr><td>Sen d&#252;&#351;t&#252;n</td><td>You fell</td></tr>
                  <tr><td>O d&#252;&#351;t&#252;</td><td>He/she/it fell</td></tr>
                  <tr><td>Biz d&#252;&#351;t&#252;k</td><td>We fell</td></tr>
                  <tr><td>Siz d&#252;&#351;t&#252;n&#252;z</td><td>You fell</td></tr>
                  <tr><td>Onlar d&#252;&#351;t&#252;ler</td><td>They fell</td></tr>
                </tbody>
              </Table>



              <SectionHeading title='Negative' />
              <ImageRule src={Negative} />

              <p>
                The negative suffixes are "ma/me". We attach the appropriate suffix to the verb root based on the rules of vowel harmony.
                Thereafter we attach one of "d&#305;/di". Basically if we consider these together we will only be attaching one of "mad&#305;/medi" to the verb root.
                Lastly we attach the personal suffix.
              </p>

              <Table responsive hover striped size="sm" className="mt-4">
                <thead>
                  <tr><td></td><td>a/&#305;/o/u</td><td>e/i/&#246;/&#252;</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben</td><td>-mad&#305;m</td><td>-medim</td></tr>
                  <tr><td>Sen</td><td>-mad&#305;n</td><td>-medin</td></tr>
                  <tr><td>O</td><td>-mad&#305;</td><td>-medi</td></tr>
                  <tr><td>Biz</td><td>-mad&#305;k</td><td>-medik</td></tr>
                  <tr><td>Siz</td><td>-mad&#305;n&#305;z</td><td>-mediniz</td></tr>
                  <tr><td>Onlar</td><td>-mad&#305;lar</td><td>-mediler</td></tr>
                </tbody>
              </Table>

              <p className='mt-4'>
                Examples:
              </p>

              <Table responsive hover striped size="sm" className="mt-4">
                <thead>
                  <tr><td colSpan={2} >yazmak - to write</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben yazmad&#305;m</td><td>I did not write</td></tr>
                  <tr><td>Sen yazmad&#305;n</td><td>You did not write</td></tr>
                  <tr><td>O yazmad&#305;</td><td>He/she/it did not write</td></tr>
                  <tr><td>Biz yazmad&#305;k</td><td>We did not write</td></tr>
                  <tr><td>Siz yazmad&#305;n&#305;z</td><td>You did not write</td></tr>
                  <tr><td>Onlar yazmad&#305;lar</td><td>They did not write</td></tr>
                </tbody>
              </Table>

              <Table responsive hover striped size="sm" className="mt-4">
                <thead>
                  <tr><td colSpan={2} >d&#252;&#351;mek - to fall</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben d&#252;&#351;medim</td><td>I did not fall</td></tr>
                  <tr><td>Sen d&#252;&#351;medin</td><td>You did not fall</td></tr>
                  <tr><td>O d&#252;&#351;medi</td><td>He/she/it did not fall</td></tr>
                  <tr><td>Biz d&#252;&#351;medik</td><td>We did not fall</td></tr>
                  <tr><td>Siz d&#252;&#351;mediniz</td><td>You did not fall</td></tr>
                  <tr><td>Onlar d&#252;&#351;mediler</td><td>They did not fall</td></tr>
                </tbody>
              </Table>



              <SectionHeading title='Positive Question' />
              <ImageRule src={PositiveQuestion} />

              <p>
                In order to turn a positive verb into a question, all we do is add one of "m&#305;/mi/mu/m&#252;" after it. These are not suffixes but separate
                words altogether. "Onlar", however, only gets one of "m&#305;/mi" added after it.
              </p>

              <Table responsive hover striped size="sm" className="mt-4 suffixes5col" >
                <thead>
                  <tr><td></td><td>a/&#305;</td><td>e/i</td><td>o/u</td><td>&#246;/&#252;</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben</td><td>-d&#305;m m&#305;</td><td>-dim mi</td><td>-dum mu</td><td>-d&#252;m m&#252;</td></tr>
                  <tr><td>Sen</td><td>-d&#305;n m&#305;</td><td>-din mi</td><td>-dun mu</td><td>-d&#252;n m&#252;</td></tr>
                  <tr><td>O</td><td>-d&#305; m&#305;</td><td>-di mi</td><td>-du mu</td><td>-d&#252; m&#252;</td></tr>
                  <tr><td>Biz</td><td>-d&#305;k m&#305;</td><td>-dik mi</td><td>-duk mu</td><td>-d&#252;k m&#252;</td></tr>
                  <tr><td>Siz</td><td>-d&#305;n&#305;z m&#305;</td><td>-diniz mi</td><td>-dunuz mu</td><td>-d&#252;n&#252;z m&#252;</td></tr>
                  <tr><td>Onlar</td><td>-d&#305;lar m&#305;</td><td>-diler mi</td><td>-dular m&#305;</td><td>-d&#252;ler mi</td></tr>
                </tbody>
              </Table>

              <p className='mt-4'>
                Examples:
              </p>

              <Table responsive hover striped size="sm" className="mt-4 examples4col">
                <thead>
                  <tr><td colSpan={2} >yazmak - to write</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben yazd&#305;m m&#305;?</td><td>Did I write?</td></tr>
                  <tr><td>Sen yazd&#305;n m&#305;?</td><td>Did you write?</td></tr>
                  <tr><td>O yazd&#305; m&#305;?</td><td>Did he/she/it write?</td></tr>
                  <tr><td>Biz yazd&#305;k m&#305;?</td><td>Did we write?</td></tr>
                  <tr><td>Siz yazd&#305;n&#305;z m&#305;?</td><td>Did you write?</td></tr>
                  <tr><td>Onlar yazd&#305;lar m&#305;?</td><td>Did they write?</td></tr>
                </tbody>
              </Table>

              <Table responsive hover striped size="sm" className="mt-4 examples4col">
                <thead>
                  <tr><td colSpan={2} >izlemek - to watch</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben izledim mi?</td><td>Did I watch?</td></tr>
                  <tr><td>Sen izledin mi?</td><td>Did you watch?</td></tr>
                  <tr><td>O izledi mi?</td><td>Did he/she/it watch?</td></tr>
                  <tr><td>Biz izledik mi?</td><td>Did we watch?</td></tr>
                  <tr><td>Siz izlediniz mi?</td><td>Did you watch?</td></tr>
                  <tr><td>Onlar izlediler mi?</td><td>Did they watch?</td></tr>
                </tbody>
              </Table>

              <p>
                If the letter at the end of the verb root is one of "&#231;/f/h/k/s/&#351;/t/p", we replace the "d" in the definite past tense suffix with a "t":
              </p>

              <Table responsive hover striped size="sm" className="mt-4 examples4col">
                <thead>
                  <tr><td colSpan={2} >konu&#351;mak - to speak</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben konu&#351;tum mu?</td><td>Did I speak?</td></tr>
                  <tr><td>Sen konu&#351;tun mu?</td><td>Did you speak?</td></tr>
                  <tr><td>O konu&#351;tu mu?</td><td>Did he/she/it speak?</td></tr>
                  <tr><td>Biz konu&#351;tuk mu?</td><td>Did we speak?</td></tr>
                  <tr><td>Siz konu&#351;tunuz mu?</td><td>Did you speak?</td></tr>
                  <tr><td>Onlar konu&#351;tular m&#305;?</td><td>Did they speak?</td></tr>
                </tbody>
              </Table>

              <Table responsive hover striped size="sm" className="mt-4 examples4col">
                <thead>
                  <tr><td colSpan={2} >d&#252;&#351;mek - to fall</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben d&#252;&#351;t&#252;m m&#252;?</td><td>Did I fall?</td></tr>
                  <tr><td>Sen d&#252;&#351;t&#252;n m&#252;?</td><td>Did you fall?</td></tr>
                  <tr><td>O d&#252;&#351;t&#252; m&#252;?</td><td>Did he/she/it fall?</td></tr>
                  <tr><td>Biz d&#252;&#351;t&#252;k m&#252;?</td><td>Did we fall?</td></tr>
                  <tr><td>Siz d&#252;&#351;t&#252;n&#252;z m&#252;?</td><td>Did you fall?</td></tr>
                  <tr><td>Onlar d&#252;&#351;t&#252;ler mi?</td><td>Did they fall?</td></tr>
                </tbody>
              </Table>



              <SectionHeading title='Negative Question' />
              <ImageRule src={NegativeQuestion} />

              <p>
                To convert a negative verb into a question, we simply add "m&#305;/mi" at the end.
              </p>

              <Table responsive hover striped size="sm" className="mt-4 suffixes3col" >
                <thead>
                  <tr><td></td><td>a/&#305;/o/u</td><td>e/i/&#246;/&#252;</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben</td><td>-mad&#305;m m&#305;</td><td>-medim mi</td></tr>
                  <tr><td>Sen</td><td>-mad&#305;n m&#305;</td><td>-medin mi</td></tr>
                  <tr><td>O</td><td>-mad&#305; m&#305;</td><td>-medi mi</td></tr>
                  <tr><td>Biz</td><td>-mad&#305;k m&#305;</td><td>-medik mi</td></tr>
                  <tr><td>Siz</td><td>-mad&#305;n&#305;z m&#305;</td><td>-mediniz mi</td></tr>
                  <tr><td>Onlar</td><td>-mad&#305;lar m&#305;</td><td>-mediler mi</td></tr>
                </tbody>
              </Table>

              <p className='mt-4'>
                Examples:
              </p>

              <Table responsive hover striped size="sm" className="mt-4 examples4col">
                <thead>
                  <tr><td colSpan={2} >yazmak - to write</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben yazmad&#305;m m&#305;?</td><td>Did I not write?</td></tr>
                  <tr><td>Sen yazmad&#305;n m&#305;?</td><td>Did you not write?</td></tr>
                  <tr><td>O yazmad&#305; m&#305;?</td><td>Did he/she/it not write?</td></tr>
                  <tr><td>Biz yazmad&#305;k m&#305;?</td><td>Did we not write?</td></tr>
                  <tr><td>Siz yazmad&#305;n&#305;z m&#305;?</td><td>Did you not write?</td></tr>
                  <tr><td>Onlar yazmad&#305;lar m&#305;?</td><td>Did they not write?</td></tr>
                </tbody>
              </Table>

              <Table responsive hover striped size="sm" className="mt-4 examples4col">
                <thead>
                  <tr><td colSpan={2} >d&#252;&#351;mek - to fall</td></tr>
                </thead>
                <tbody>
                  <tr><td>Ben d&#252;&#351;medim mi?</td><td>Did I not fall?</td></tr>
                  <tr><td>Sen d&#252;&#351;medin mi?</td><td>Did you not fall?</td></tr>
                  <tr><td>O d&#252;&#351;medi mi?</td><td>Did he/she/it not fall?</td></tr>
                  <tr><td>Biz d&#252;&#351;medik mi?</td><td>Did we not fall?</td></tr>
                  <tr><td>Siz d&#252;&#351;mediniz mi?</td><td>Did you not fall?</td></tr>
                  <tr><td>Onlar d&#252;&#351;mediler mi?</td><td>Did they not fall?</td></tr>
                </tbody>
              </Table>




              {/* <ol className="ps-4 docs-list">
                <li>
                  Install Node.js from <Card.Link href="https://nodejs.org/" target="_blank">node.js official page</Card.Link> or using a <a href="https://github.com/nvm-sh/nvm" target="_blank" rel="noopener noreferrer">node.js version manager (nvm for short)</a>.
                  <Alert className="my-4" variant="info"><strong>Important!</strong> Make sure the installed Node version is {`>=`} 8.10 and of npm {`>=`} 5.6</Alert>
                </li>
                <li>
                  <p>After installing node.js, open a terminal and run <code>npm install</code> in the main <code>volt</code> folder to download all project dependencies. You'll find them in the <code>node_modules</code> folder.</p>
                  <Code code="$ npm install" language="bash" />
                </li>
                <li>
                  <p>Then start the app in development mode by running the following command in terminal:</p>
                  <Code code="$ npm start" language="bash" />
                </li>
                <li>
                  Open <Card.Link href="http://localhost:3000" target="_blank">http://localhost:3000</Card.Link> to view it in the browser.
                </li>
              </ol> */}
            </article>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);
