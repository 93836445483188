import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Animals = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Animals</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Animal</td><td>Hayvan</td></tr>
                                        <tr><td>Bear</td><td>Ay&#305;</td></tr>
                                        <tr><td>Bird</td><td>Ku&#351;</td></tr>
                                        <tr><td>Camel</td><td>Deve</td></tr>
                                        <tr><td>Cat</td><td>Kedi</td></tr>
                                        <tr><td>Cheetah</td><td>&#199;ita</td></tr>
                                        <tr><td>Chicken</td><td>Tavuk</td></tr>
                                        <tr><td>Cow</td><td>&#304;nek</td></tr>
                                        <tr><td>Dog</td><td>K&#246;pek</td></tr>
                                        <tr><td>Donkey</td><td>E&#351;ek</td></tr>
                                        <tr><td>Duck</td><td>&#214;rdek</td></tr>
                                        <tr><td>Eagle</td><td>Kartal</td></tr>
                                        <tr><td>Elephant</td><td>Fil</td></tr>
                                        <tr><td>Fish</td><td>Bal&#305;k</td></tr>
                                        <tr><td>Giraffe</td><td>Z&#252;rafa</td></tr>
                                        <tr><td>Horse</td><td>At</td></tr>
                                        <tr><td>Kangaroo</td><td>Kanguru</td></tr>
                                        <tr><td>Lion</td><td>Aslan</td></tr>
                                        <tr><td>Monkey</td><td>Maymun</td></tr>
                                        <tr><td>Mouse</td><td>Fare</td></tr>
                                        <tr><td>Owl</td><td>Bayku&#351;</td></tr>
                                        <tr><td>Penguin</td><td>Penguen</td></tr>
                                        <tr><td>Rabbit</td><td>Tav&#351;an</td></tr>
                                        <tr><td>Sheep</td><td>Koyun</td></tr>
                                        <tr><td>Snake</td><td>Y&#305;lan</td></tr>
                                        <tr><td>Spider</td><td>&#214;r&#252;mcek</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
