import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const ConsonantMutation = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Consonant Mutation</h1>

                                <p>
                                    In Turkish, there are certain consonants that are replaced by other letters when suffixes are added to them.
                                </p>

                                <p>
                                    When we add a suffix to word that ends in "p/&#231;/t/k" and the consonant has a vowel both before and after it, the consonant will change:
                                </p>

                                <Table hover striped size="sm" className="rules cmutation maj1">
                                    <tbody>
                                        <tr><td><span className="red">p</span></td><td className="centerarrow">&#10152;</td><td><span className="red">b</span></td></tr>
                                        <tr><td><span className="red">&#231;</span></td><td className="centerarrow">&#10152;</td><td><span className="red">c</span></td></tr>
                                        <tr><td><span className="red">t</span></td><td className="centerarrow">&#10152;</td><td><span className="red">d</span></td></tr>
                                        <tr><td><span className="red">k</span></td><td className="centerarrow">&#10152;</td><td><span className="red">&#287;/g</span></td></tr>
                                    </tbody>
                                </Table>

                                <p>
                                    Examples:
                                </p>

                                <p className="scd">
                                    (kitap) Benim kita<span className="red">b</span>&#305;m - My book<br />
                                    (dolap) Dola<span className="red">b</span>a koydum - I put it into the cupboard<br />
                                    <br />
                                    (a&#287;a&#231;) A&#287;a<span className="red">c</span>a bak - Look at the tree<br />
                                    (bor&#231;) Bor<span className="red">c</span>um ne kadar? - How much do I owe?<br />
                                    <br />
                                    (yo&#287;urt) Hakan yo&#287;ur<span className="red">d</span>u yedi - Hakan ate the yogurt<br />
                                    (d&#246;rt) Saat d&#246;r<span className="red">d</span>e &#231;eyrek var - The time is a quarter to four<br />
                                    <br />
                                    (s&#246;zl&#252;k) &#304;ngilizce-T&#252;rk&#231;e s&#246;zl&#252;<span className="red">&#287;</span>&#252;n var m&#305;? - Do you have an English-Turkish dictionary?<br />
                                    (&#231;ocuk) Fatih &#231;ocu<span className="red">&#287;</span>a &#351;eker verdi - Fatih gave candy to the child<br />
                                </p>

                                <p>
                                    The letter "k" is replaced by a "g" instead of a "&#287;" when it has a "n" before it and a vowel after it:
                                </p>

                                <p className="scd">
                                    (renk) G&#246;z ren<span className="red">g</span>i - Eye color<br />
                                    (kepenk) &#214;mer kepen<span className="red">g</span>i kapatt&#305; - &#214;mer closed the shutter
                                </p>


                                <h2>Exceptions</h2>

                                <p>
                                    In general, single syllable words don't have consonant mutation applied to them:
                                </p>
                                <p className="scd">
                                    (top) Benim topum - My ball<br />
                                    (ko&#231;) &#199;oban&#305;n ko&#231;u - The shepherd's goat<br />
                                    (s&#252;t) S&#252;t&#252; barda&#287;a d&#246;k - Pour the milk into the glass
                                </p>


                                <p>
                                    Words that are derived from other languages (French, English, Arabic, etc.) don't experience any consonant mutation:
                                </p>
                                <p className="scd">
                                    (bilet) Biletini unutma - Don't forget your ticket<br />
                                    (internet) &#304;nterneti her g&#252;n kullan&#305;yorum - I use the internet everyday<br />
                                    (hukuk) &#304;slam hukuku - Islamic law
                                </p>


                                <p>
                                    Proper nouns such as names of people or places also do not change:
                                </p>
                                <p className="scd">
                                    (Zeynep) Zeynep'e kalem verdim - I gave Zeynep a pen<br />
                                    (Hali&#231;) Hali&#231;'e gittik - We went to the Golden Horn<br />
                                    (Tokat) Tokat'&#305;n n&#252;fusu - The population of Tokat
                                </p>

                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
