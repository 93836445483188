import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

export const Plurals = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Plurals</h1>

                                <p>
                                    The plural suffixes in Turkish are <span className="red">-lar</span> and <span className="red">-ler</span>. We use the rules of <Link to={"/grammar/vowel-harmony"}>Vowel Harmony</Link> to decide which suffix to use. Words whose last vowel is one of (a, &#305;, o, u) will get -lar added to them, while those whose last vowel is one of (e, i, &#246;, &#252;) will be followed by -ler.
                                </p>

                                <Table responsive hover striped size="sm" className="plu1">
                                    <thead>
                                        <tr>
                                            <th>Last Vowel</th>
                                            <th>Suffix</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>a, &#305;, o, u</td>
                                            <td>-lar</td>
                                        </tr>
                                        <tr>
                                            <td>e, i, &#246;, &#252;</td>
                                            <td>-ler</td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <p className='mt-4'>
                                    Examples:
                                </p>

                                <p className="scd">
                                    Araba - Araba<span className="red">lar</span> (Car - Cars)<br />
                                    Kap&#305; - Kap&#305;<span className="red">lar</span> (Door - Doors)<br />
                                    Top - Top<span className="red">lar</span> (Ball - Balls)<br />
                                    Soru - Soru<span className="red">lar</span> (Question - Questions)<br />

                                    <br />

                                    Kalem - Kalem<span className="red">ler</span> (Pen - Pens)<br />
                                    Cami - Cami<span className="red">ler</span> (Mosque - Mosques)<br />
                                    G&#246;z - G&#246;z<span className="red">ler</span> (Eye - Eyes)<br />
                                    T&#252;rk - T&#252;rk<span className="red">ler</span> (Turk - Turks)<br />
                                </p>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
