import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";
import Positive from "../../../assets/img/content/rules/grammar/turkish_grammar_i_am_positive.jpg";
import Negative from "../../../assets/img/content/rules/grammar/turkish_grammar_i_am_negative.jpg";
import PositiveQuestion from "../../../assets/img/content/rules/grammar/turkish_grammar_i_am_positive_question.jpg";
import NegativeQuestion from "../../../assets/img/content/rules/grammar/turkish_grammar_i_am_negative_question.jpg";
import ImageRule from '../../../components/ImageRule';
import SectionHeading from '../../../components/SectionHeading';
import PersonalPronounsTable from "../common/PersonalProunounsTable";

export const IAmYouAre = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>I am, you are</h1>

                                <p>
                                    To say "I am", "You are", "He is" etc. in English we use a personal pronoun and a noun/adjective. For example: "I am a doctor", "I" is the personal pronoun and "doctor" is the noun.
                                </p>

                                <p>
                                    For Turkish, we use a personal pronoun, a noun/adjective <span className="empha">and</span> a personal suffix.
                                    For example: "Ben doktorum", "Ben" is the personal pronoun, "doktor" is the noun and "-um" is the personal suffix. The personal pronoun and the
                                    personal suffix are associated with each other.
                                </p>

                                <div className="listandtable">
                                    <div className="listc">
                                        <p>
                                            There are four cases to be considered:
                                        </p>
                                        <ol>
                                            <li>Positive (Eg. I am a doctor)</li>
                                            <li>Negative (Eg. I am not a doctor)</li>
                                            <li>Positive Question (Eg. Am I a doctor?)</li>
                                            <li>Negative Question (Eg. Am I not a doctor?)</li>
                                        </ol>
                                    </div>

                                    <PersonalPronounsTable />
                                </div>


                                <SectionHeading title='Positive' />
                                <ImageRule src={Positive} />

                                <p>
                                    Each personal suffix has more than one form(spelling). We choose the correct form of the personal suffix based on the last vowel of the noun/adjective. This is done to preserve vowel harmony:
                                    <Table responsive hover striped size="sm" className="suffixes5col">
                                        <thead>
                                            <tr>
                                                <th>Last Vowel &#9654;</th>
                                                <th>a/&#305;</th>
                                                <th>e/i</th>
                                                <th>o/u</th>
                                                <th>&#246;/&#252;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="colh">Ben</td>
                                                <td>-[y]&#305;m</td>
                                                <td>-[y]im</td>
                                                <td>-[y]um</td>
                                                <td>-[y]&#252;m</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">Sen</td>
                                                <td>-s&#305;n</td>
                                                <td>-sin</td>
                                                <td>-sun</td>
                                                <td>-s&#252;n</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">O</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">Biz</td>
                                                <td>-[y]&#305;z</td>
                                                <td>[y]iz</td>
                                                <td>-[y]uz</td>
                                                <td>-[y]&#252;z</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">Siz</td>
                                                <td>-s&#305;n&#305;z</td>
                                                <td>-siniz</td>
                                                <td>-sunuz</td>
                                                <td>-s&#252;n&#252;z</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">Onlar</td>
                                                <td>-lar</td>
                                                <td>-ler</td>
                                                <td>-lar</td>
                                                <td>-ler</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>

                                <p>
                                    Examples:

                                    <Table responsive hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">doktor - doctor</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben doktor<span className="gramrule">um</span></td>
                                                <td>I am a doctor</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen doktor<span className="gramrule">sun</span></td>
                                                <td>You are a doctor</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O doktor</td>
                                                <td>He/she/it is a doctor</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz doktor<span className="gramrule">uz</span></td>
                                                <td>We are doctors</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz doktor<span className="gramrule">sunuz</span></td>
                                                <td>You are doctors</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar doktor<span className="gramrule">lar</span></td>
                                                <td>They are doctors</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table responsive hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">g&#252;zel - beautiful</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben g&#252;zel<span className="gramrule">im</span></td>
                                                <td>I am beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen g&#252;zel<span className="gramrule">sin</span></td>
                                                <td>You are beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O g&#252;zel</td>
                                                <td>He/she/it is beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz g&#252;zel<span className="gramrule">iz</span></td>
                                                <td>We are beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz g&#252;zel<span className="gramrule">siniz</span></td>
                                                <td>You are beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar g&#252;zel<span className="gramrule">ler</span></td>
                                                <td>They are beautiful</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>

                                <p>
                                    For "Biz", if the last letter of the noun/adjective is a vowel we add the buffer letter "y" to prevent having two vowels side by side. Eg.:<br />
                                </p>
                                <p>
                                    <Table responsive hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">baba - father</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben baba<span className="gramrule">y&#305;m</span></td>
                                                <td>I am a father</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen baba<span className="gramrule">s&#305;n</span></td>
                                                <td>You are a father</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O baba</td>
                                                <td>He/it is a father</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz baba<span className="gramrule">y&#305;z</span></td>
                                                <td>We are fathers</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz baba<span className="gramrule">s&#305;n&#305;z</span></td>
                                                <td>You are fathers</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar baba<span className="gramrule">lar</span></td>
                                                <td>They are fathers</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table responsive hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">k&#246;t&#252; - bad</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben k&#246;t&#252;<span className="gramrule">y&#252;m</span></td>
                                                <td>I am bad</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen k&#246;t&#252;<span className="gramrule">s&#252;n</span></td>
                                                <td>You are bad</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O k&#246;t&#252;</td>
                                                <td>He/she/it is bad</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz k&#246;t&#252;<span className="gramrule">y&#252;z</span></td>
                                                <td>We are bad</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz k&#246;t&#252;<span className="gramrule">s&#252;n&#252;z</span></td>
                                                <td>You are bad</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar k&#246;t&#252;<span className="gramrule">ler</span></td>
                                                <td>They are bad</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>


                                <SectionHeading title='Negative' />
                                <ImageRule src={Negative} />

                                <p>For negative we attach our personal suffixes to the word "de&#287;il"(not):</p>
                                <p>
                                    <Table responsive hover striped size="sm" className="pronouns2col">
                                        <tbody>
                                            <tr>
                                                <td>Ben</td>
                                                <td>de&#287;ilim</td>
                                            </tr>
                                            <tr>
                                                <td>Sen</td>
                                                <td>de&#287;ilsin</td>
                                            </tr>
                                            <tr>
                                                <td>O</td>
                                                <td>de&#287;il</td>
                                            </tr>
                                            <tr>
                                                <td>Biz</td>
                                                <td>de&#287;iliz</td>
                                            </tr>
                                            <tr>
                                                <td>Siz</td>
                                                <td>de&#287;ilsiniz</td>
                                            </tr>
                                            <tr>
                                                <td>Onlar</td>
                                                <td>de&#287;iller</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>

                                <p>
                                    Examples:
                                </p>
                                <p>
                                    <Table hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">doktor - doctor</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben doktor <span className="gramrule">de&#287;ilim</span></td>
                                                <td>I am not a doctor</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen doktor <span className="gramrule">de&#287;ilsin</span></td>
                                                <td>You are not a doctor</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O doktor <span className="gramrule">de&#287;il</span></td>
                                                <td>He/she/it is not a doctor</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz doktor <span className="gramrule">de&#287;iliz</span></td>
                                                <td>We are not doctors</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz doktor <span className="gramrule">de&#287;ilsiniz</span></td>
                                                <td>You are not doctors</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar doktor <span className="gramrule">de&#287;iller</span></td>
                                                <td>They are not doctors</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">g&#252;zel - beautiful</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben g&#252;zel <span className="gramrule">de&#287;ilim</span></td>
                                                <td>I am not beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen g&#252;zel <span className="gramrule">de&#287;ilsin</span></td>
                                                <td>You are not beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O g&#252;zel <span className="gramrule">de&#287;il</span></td>
                                                <td>She/it is not beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz g&#252;zel <span className="gramrule">de&#287;iliz</span></td>
                                                <td>We are not beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz g&#252;zel <span className="gramrule">de&#287;ilsiniz</span></td>
                                                <td>You are not beautiful</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar g&#252;zel <span className="gramrule">de&#287;iller</span></td>
                                                <td>They are not beautiful</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>

                                <SectionHeading title='Positive Question' />
                                <ImageRule src={PositiveQuestion} />

                                <p> To form a question we utilize variations of the word "mi" together with personal suffixes.
                                    We choose one of m&#305;/mi/mu/m&#252;. This is done to preserve vowel harmony:
                                </p>
                                <p>
                                    <Table responsive hover striped size="sm" className="suffixes5col">
                                        <thead>
                                            <tr>
                                                <th className="corner">Last Vowel &#9654;</th>
                                                <th>a/&#305;</th>
                                                <th>e/i</th>
                                                <th>o/u</th>
                                                <th>&#246;/&#252;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="colh">Ben</td>
                                                <td>m&#305;y&#305;m</td>
                                                <td>miyim</td>
                                                <td>muyum</td>
                                                <td>m&#252;y&#252;m</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">Sen</td>
                                                <td>m&#305;s&#305;n</td>
                                                <td>misin</td>
                                                <td>musun</td>
                                                <td>m&#252;s&#252;n</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">O</td>
                                                <td>m&#305;</td>
                                                <td>mi</td>
                                                <td>mu</td>
                                                <td>m&#252;</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">Biz</td>
                                                <td>m&#305;y&#305;z</td>
                                                <td>miyiz</td>
                                                <td>muyuz</td>
                                                <td>m&#252;y&#252;z</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">Siz</td>
                                                <td>m&#305;s&#305;n&#305;z</td>
                                                <td>misiniz</td>
                                                <td>musunuz</td>
                                                <td>m&#252;s&#252;n&#252;z</td>
                                            </tr>
                                            <tr>
                                                <td className="colh">Onlar</td>
                                                <td>m&#305;</td>
                                                <td>mi</td>
                                                <td>mu</td>
                                                <td>m&#252;</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>

                                <p>
                                    Examples:
                                </p>
                                <p>
                                    <Table responsive hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">doktor - doctor</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben doktor <span className="gramrule">muyum?</span></td>
                                                <td>Am I a doctor?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen doktor <span className="gramrule">musun?</span></td>
                                                <td>Are you a doctor?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O doktor <span className="gramrule">mu?</span></td>
                                                <td>Is he/she/it a doctor?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz doktor <span className="gramrule">muyuz?</span></td>
                                                <td>Are we doctors?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz doktor <span className="gramrule">musunuz?</span></td>
                                                <td>Are you doctors?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar doktor <span className="gramrule">mu?</span></td>
                                                <td>Are they doctors?</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table responsive hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">g&#252;zel - beautiful</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben g&#252;zel <span className="gramrule">miyim?</span></td>
                                                <td>Am I beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen g&#252;zel <span className="gramrule">misin?</span></td>
                                                <td>Are you beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O g&#252;zel <span className="gramrule">mi?</span></td>
                                                <td>Is he/she/it beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz g&#252;zel <span className="gramrule">miyiz?</span></td>
                                                <td>Are we beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz g&#252;zel <span className="gramrule">misiniz?</span></td>
                                                <td>Are you beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar g&#252;zel <span className="gramrule">mi?</span></td>
                                                <td>Are they beautiful?</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>



                                <SectionHeading title='Negative Question' />
                                <ImageRule src={NegativeQuestion} />

                                <p> Negative questions combine "de&#287;il" and "mi" to give "de&#287;il mi". The personal suffixes get attached to "mi".
                                    Unlike the positive question, for the negative question "mi" has only one form (and not four) for each personal suffix:
</p>
<p>
                                    <Table responsive hover striped size="sm" className="pronouns2col">
                                        <tbody>
                                            <tr>
                                                <td>Ben</td>
                                                <td>de&#287;il miyim</td>
                                            </tr>
                                            <tr>
                                                <td>Sen</td>
                                                <td>de&#287;il misin</td>
                                            </tr>
                                            <tr>
                                                <td>O</td>
                                                <td>de&#287;il mi</td>
                                            </tr>
                                            <tr>
                                                <td>Biz</td>
                                                <td>de&#287;il miyiz</td>
                                            </tr>
                                            <tr>
                                                <td>Siz</td>
                                                <td>de&#287;il misiniz</td>
                                            </tr>
                                            <tr>
                                                <td>Onlar</td>
                                                <td>de&#287;iller mi</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>

                                <p>
                                    Examples:
                                    </p>
                                    <p>
                                    <Table responsive hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">doktor - doctor</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben doktor <span className="gramrule">de&#287;il miyim?</span></td>
                                                <td>Am I not a doctor?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen doktor <span className="gramrule">de&#287;il misin?</span></td>
                                                <td>Are you not a doctor?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O doktor <span className="gramrule">de&#287;il mi?</span></td>
                                                <td>Is he/she/it not a doctor?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz doktor <span className="gramrule">de&#287;il miyiz?</span></td>
                                                <td>Are we not doctors?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz doktor <span className="gramrule">de&#287;il misiniz</span></td>
                                                <td>Are you not doctors?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar doktor <span className="gramrule">de&#287;iller mi?</span></td>
                                                <td>Are they not doctors?</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table responsive hover striped size="sm" className="examples4col">
                                        <thead>
                                            <tr>
                                                <td colspan="2" className="head">g&#252;zel - beautiful</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tur">Ben g&#252;zel <span className="gramrule">de&#287;il miyim?</span></td>
                                                <td>Am I not beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Sen g&#252;zel <span className="gramrule">de&#287;il misin?</span></td>
                                                <td>Are you not beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">O g&#252;zel <span className="gramrule">de&#287;il mi?</span></td>
                                                <td>Is he/she/it not beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Biz g&#252;zel <span className="gramrule">de&#287;il miyiz?</span></td>
                                                <td>Are we not beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Siz g&#252;zel <span className="gramrule">de&#287;il misiniz?</span></td>
                                                <td>Are you not beautiful?</td>
                                            </tr>
                                            <tr>
                                                <td className="tur">Onlar g&#252;zel <span className="gramrule">de&#287;iller mi?</span></td>
                                                <td>Are they not beautiful?</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </p>

                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
