import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";
import DOC from "../../../assets/img/content/rules/grammar/deg_of_com/degrees_of_comparison.jpg";
import ImageRule from "../../../components/ImageRule";

export const DegreesOfComparison = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Degrees of comparison</h1>

                                <ImageRule src={DOC} />
                                <p>
                                    We use Degrees of Comparison to compare people, places or things. An adjective can have one of three forms: Positive,
                                    Comparitive or Superlative. For example, for the adjective "big" the Positive form would be "<span className="empha">big</span>" itself, the
                                    Comparitive	form would be "<span className="empha">bigger</span>" and the Superlative form would be "<span className="empha">biggest</span>".
                                </p>

                                <p>
                                    In Turkish, we express the Comparitive form using "<span className="empha">daha</span>" and the Superlative form using "<span className="empha">en</span>".
                                    For example, for the adjective "b&#252;y&#252;k" the Positive form would be "<span className="empha">b&#252;y&#252;k</span>" itself, the
                                    Comparitive	form would be "<span className="empha">daha b&#252;y&#252;k</span>" and the Superlative form would be
                                    "<span className="empha">en b&#252;y&#252;k</span>".
                                </p>

                                <p className='mt-4'>
                                    Examples:
                                </p>

                                <Table responsivehover striped size="sm" className='deg'>
                                    <thead>
                                        <tr className='head'><th>Positive</th><th>Comparitive</th><th>Superlative</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr className='bg1'><td>b&#252;y&#252;k</td><td>daha b&#252;y&#252;k</td><td>en b&#252;y&#252;k</td></tr><tr className='bg1'><td>big</td><td>bigger</td><td>biggest</td></tr><tr className='bg2'><td>k&#252;&#231;&#252;k</td><td>daha k&#252;&#231;&#252;k</td><td>en k&#252;&#231;&#252;k</td></tr><tr className='bg2'><td>small</td><td>smaller</td><td>smallest</td></tr><tr className='bg1'><td>h&#305;zl&#305;</td><td>daha h&#305;zl&#305;</td><td>en h&#305;zl&#305;</td></tr><tr className='bg1'><td>fast</td><td>faster</td><td>fastest</td></tr><tr className='bg2'><td>kolay</td><td>daha kolay</td><td>en kolay</td></tr><tr className='bg2'><td>easy</td><td>easier</td><td>easiest</td></tr><tr className='bg1'><td>iyi</td><td>daha iyi</td><td>en iyi</td></tr><tr className='bg1'><td>good</td><td>better</td><td>best</td></tr><tr className='bg2'><td>gen&#231;</td><td>daha gen&#231;</td><td>en gen&#231;</td></tr><tr className='bg2'><td>young</td><td>younger</td><td>youngest</td></tr>
                                    </tbody>
                                </Table>

                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
