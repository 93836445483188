import React from 'react';
import { Row, Col, Card, Container, Table } from '@themesberg/react-bootstrap';

import PositiveOne from "../../../assets/img/content/rules/verbs/present_simple/present_simple_positive_1.jpg";
import PositiveTwo from "../../../assets/img/content/rules/verbs/present_simple/present_simple_positive_2.jpg";
import PositiveThree from "../../../assets/img/content/rules/verbs/present_simple/present_simple_positive_3.jpg";
import Negative from "../../../assets/img/content/rules/verbs/present_simple/present_simple_positive_question.jpg";
import PositiveQuestion from "../../../assets/img/content/rules/verbs/present_simple/present_simple_negative.jpg";
import NegativeQuestion from "../../../assets/img/content/rules/verbs/present_simple/present_simple_negative_question.jpg";
import ImageRule from '../../../components/ImageRule';
import SectionHeading from '../../../components/SectionHeading';

export const PresentSimpleTense = () => (
	<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Present Simple Tense</h1>
							<p className="fs-5 fw-light">(r)</p>

							<p>
								We use the present simple tense for actions that we do all the time. It is used for actions that
								we have done in the past, that we still do now and that we will continue to do in the future. Eg.:
							</p>

							<p className="scd">
								I write<br />
								I eat<br />
								He thinks<br />
								She uses<br />
								They draw
							</p>

							<p>
								In Turkish, we indicate the present simple tense by attaching certain suffixes to the verb root. We obtain the verb root by removing the
								"mak/mek" from the infinitive form. For example, the verb root of "yazmak" would be "yaz", "kullanmak" would be "kullan" and "yemek" would be "ye".
							</p>

							<div className="listandtable">
								<div className="listc">
									<p>
										We can express the present simple tense in four ways:
									</p>
									<ol>
										<li>Positive (Eg. I eat)</li>
										<li>Negative (Eg. I do not eat)</li>
										<li>Positive Question (Eg. Do I eat?)</li>
										<li>Negative Question (Eg. Do I not eat?)</li>
									</ol>
								</div>
								<div className="tablec">
									<Table responsive hover striped size="sm" className="pronouns2col">
										<tbody>
											<tr className="bg1"><td>I</td><td className="tur">Ben</td></tr>
											<tr className="bg2"><td>You</td><td className="tur">Sen</td></tr>
											<tr className="bg1"><td>He / She / It</td><td className="tur">O</td></tr>
											<tr className="bg2"><td>We</td><td className="tur">Biz</td></tr>
											<tr className="bg1"><td>You (Plural)</td><td className="tur">Siz</td></tr>
											<tr className="bg2"><td>They</td><td className="tur">Onlar</td></tr>
										</tbody>
										<caption>Personal pronouns</caption>
									</Table>
								</div>
							</div>

							<SectionHeading title='Positive' />

							<p>
								We form a positive present simple tense verb in one of three ways, depending on the structure of the verb root. In all three cases the
								suffix that we attach to the verb root includes the letter "r". Each case attaches it in a different way. The key is to always remember the "r".
							</p>

							<p>
								We focus on two attributes of the verb root: whether its last letter is a vowel or consonant and the number of syllables it's made up of.
							</p>

							<br />

							<h3>Positive 1 - Ends in vowel: ("-r")</h3>

							<ImageRule src={PositiveOne} />

							<p>
								The first case is when the verb root has:
							</p>

							<ul>
								<li>A vowel at the end of it</li>
							</ul>

							<p>
								For example: ba&#351;lamak (ba&#351;la), yemek (ye), okumak (oku). This case applies to verbs with any amount of syllables, the only condition
								is that the letter at the end of the verb root is a vowel.
							</p>

							<p>
								To form the positive present simple tense verb, all we do is attach "r" to the verb root followed by the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes5col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-r&#305;m</td><td>-rim</td><td>-rum</td><td>-r&#252;m</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-rs&#305;n</td><td>-rsin</td><td>-rsun</td><td>-rs&#252;n</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-r</td><td>-r</td><td>-r</td><td>-r</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-r&#305;z</td><td>-riz</td><td>-ruz</td><td>-r&#252;z</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-rs&#305;n&#305;z</td><td>-rsiniz</td><td>-rsunuz</td><td>-rs&#252;n&#252;z</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-rlar</td><td>-rler</td><td>-rlar</td><td>-rler</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root<br />(Positive 1 - Ends in vowel)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">ba&#351;lamak(ba&#351;la) - to begin</td><td colspan="2" className="head">yemek(ye) - to eat</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben ba&#351;lar&#305;m</td><td>I begin</td><td className="tur">Ben yerim</td><td>I eat</td></tr>
									<tr><td className="tur">Sen ba&#351;lars&#305;n</td><td>You begin</td><td className="tur">Sen yersin</td><td>You eat</td></tr>
									<tr><td className="tur">O ba&#351;lar</td><td>He/she/it begins</td><td className="tur">O yer</td><td>He/she/it eats</td></tr>
									<tr><td className="tur">Biz ba&#351;lar&#305;z</td><td>We begin</td><td className="tur">Biz yeriz</td><td>We eat</td></tr>
									<tr><td className="tur">Siz ba&#351;lars&#305;n&#305;z</td><td>You begin</td><td className="tur">Siz yersiniz</td><td>You eat</td></tr>
									<tr><td className="tur">Onlar ba&#351;larlar</td><td>They begin</td><td className="tur">Onlar yerler</td><td>They eat</td></tr>
								</tbody>
							</Table>

							<br /><br />

							<h3>Positive 2 - Ends in a consonant and has one syllable: ("-ar/-er")</h3>

							<ImageRule src={PositiveTwo} />

							<p>
								The second case is when the verb root:
							</p>

							<ul>
								<li>Ends in a consonant</li>
								<li>Has exactly one syllable</li>
							</ul>

							<p>
								For example: yazmak (yaz), &#231;izmek (&#231;iz), sormak (sor), g&#252;lmek (g&#252;l). The helping verb "etmek" (et) also falls under
								this category.
							</p>


							<p>
								We attach one of "ar/er" based on the last vowel found in the verb root. Then we attach the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-ar&#305;m</td><td>-erim</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-ars&#305;n</td><td>-ersin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-ar</td><td>-er</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-ar&#305;z</td><td>-eriz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-ars&#305;n&#305;z</td><td>-ersiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-arlar</td><td>-erler</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root<br />(Positive 2 - Ends in a consonant and one syllable)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak(yaz) - to write</td><td colspan="2" className="head">&#231;izmek(&#231;iz) - to draw</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazar&#305;m</td><td>I write</td><td className="tur">Ben &#231;izerim</td><td>I draw</td></tr>
									<tr><td className="tur">Sen yazars&#305;n</td><td>You write</td><td className="tur">Sen &#231;izersin</td><td>You draw</td></tr>
									<tr><td className="tur">O yazar</td><td>He/she/it writes</td><td className="tur">O &#231;izer</td><td>He/she/it draws</td></tr>
									<tr><td className="tur">Biz yazar&#305;z</td><td>We write</td><td className="tur">Biz &#231;izeriz</td><td>We draw</td></tr>
									<tr><td className="tur">Siz yazars&#305;n&#305;z</td><td>You write</td><td className="tur">Siz &#231;izersiniz</td><td>You draw</td></tr>
									<tr><td className="tur">Onlar yazarlar</td><td>They write</td><td className="tur">Onlar &#231;izerler</td><td>They draw</td></tr>
								</tbody>
							</Table>


							<p>
								Note:<br />
								This case does have a number of exceptions including: almak, bilmek, bulmak, durmak, gelmek, g&#246;rmek, kalmak,
								olmak (which is a helping verb), &#246;lmek, sanmak, vermek, varmak.
							</p>

							<br /><br />

							<h3>Positive 3 - Ends in a consonant and has more than one syllable: ("-&#305;r/-ir/-ur/-&#252;r")</h3>

							<ImageRule src={PositiveThree} />
							<p>
								The last case is when the verb root:
							</p>

							<ul>
								<li>Ends in a consonant</li>
								<li>Has more than one syllable</li>
							</ul>

							<p>
								For example: kullanmak (kullan), &#246;&#287;renmek (&#246;&#287;ren), oturmak (otur), d&#252;&#351;&#252;nmek (d&#252;&#351;&#252;n).
								We attach one of "&#305;r/ir/ur/&#252;r" depending on the verb root's last vowel. Thereafter we attach the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes5col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-&#305;r&#305;m</td><td>-irim</td><td>-urum</td><td>-&#252;r&#252;m</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-&#305;rs&#305;n</td><td>-irsin</td><td>-ursun</td><td>-&#252;rs&#252;n</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-&#305;r</td><td>-ir</td><td>-ur</td><td>-&#252;r</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-&#305;r&#305;z</td><td>-iriz</td><td>-uruz</td><td>-&#252;r&#252;z</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-&#305;rs&#305;n&#305;z</td><td>-irsiniz</td><td>-ursunuz</td><td>-&#252;rs&#252;n&#252;z</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-&#305;rlar</td><td>-irler</td><td>-urlar</td><td>-&#252;rler</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root<br />(Positive 3 - Ends in a consonant and more than one syllable)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">kullanmak(kullan) - to use</td><td colspan="2" className="head">d&#252;&#351;&#252;nmek(d&#252;&#351;&#252;n) - to think</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben kullan&#305;r&#305;m</td><td>I use</td><td className="tur">Ben d&#252;&#351;&#252;n&#252;r&#252;m</td><td>I think</td></tr>
									<tr><td className="tur">Sen kullan&#305;rs&#305;n</td><td>You use</td><td className="tur">Sen d&#252;&#351;&#252;n&#252;rs&#252;n</td><td>You think</td></tr>
									<tr><td className="tur">O kullan&#305;r</td><td>He/she/it uses</td><td className="tur">O d&#252;&#351;&#252;n&#252;r</td><td>He/she/it thinks</td></tr>
									<tr><td className="tur">Biz kullan&#305;r&#305;z</td><td>We use</td><td className="tur">Biz d&#252;&#351;&#252;n&#252;r&#252;z</td><td>We think</td></tr>
									<tr><td className="tur">Siz kullan&#305;rs&#305;n&#305;z</td><td>You use</td><td className="tur">Siz d&#252;&#351;&#252;n&#252;rs&#252;n&#252;z</td><td>You think</td></tr>
									<tr><td className="tur">Onlar kullan&#305;rlar</td><td>They use</td><td className="tur">Onlar d&#252;&#351;&#252;n&#252;rler</td><td>They think</td></tr>
								</tbody>
							</Table>

							<p>
								Note:<br />
								Sometimes helping verbs such as "etmek" or "olmak" are fused together with other words to form one big verb eg. seyretmek, bahsetmek,
								kaybolmak, affolmak. Verbs like these do not fall under this category and we apply the appropriate rule based on "etmek" or "olmak" only.
								In other words we treat them as if "etmek" or "olmak" are written separately.
							</p>



							<SectionHeading title='Negative' />
							<ImageRule src={Negative} />

							<p>
								There is just one way of forming the negative present simple tense verb. We attach one of "maz/mez" to the verb root depending on its last vowel.
								We then attach the personal suffix.
							</p>

							<p>
								"Ben" and "Biz" are a bit different. For "Ben" we attach "mam/mem" instead of "maz/mez". For "Biz" we attach "may&#305;z/meyiz".
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-mam</td><td>-mem</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-mazs&#305;n</td><td>-mezsin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-maz</td><td>-mez</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-may&#305;z</td><td>-meyiz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-mazs&#305;n&#305;z</td><td>-mezsiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-mazlar</td><td>-mezler</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Negative)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak(yaz) - to write</td><td colspan="2" className="head">&#231;izmek(&#231;iz) - to draw</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazmam</td><td>I do not write</td><td className="tur">Ben &#231;izmem</td><td>I do not draw</td></tr>
									<tr><td className="tur">Sen yazmazs&#305;n</td><td>You do not write</td><td className="tur">Sen &#231;izmezsin</td><td>You do not draw</td></tr>
									<tr><td className="tur">O yazmaz</td><td>He/she/it does not write</td><td className="tur">O &#231;izmez</td><td>He/she/it does not draw</td></tr>
									<tr><td className="tur">Biz yazmay&#305;z</td><td>We do not write</td><td className="tur">Biz &#231;izmeyiz</td><td>We do not draw</td></tr>
									<tr><td className="tur">Siz yazmazs&#305;n&#305;z</td><td>You do not write</td><td className="tur">Siz &#231;izmezsiniz</td><td>You do not draw</td></tr>
									<tr><td className="tur">Onlar yazmazlar</td><td>They do not write</td><td className="tur">Onlar &#231;izmezler</td><td>They do not draw</td></tr>
								</tbody>
							</Table>

							<br />

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">kullanmak(kullan) - to use</td><td colspan="2" className="head">yemek(ye) - to eat</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben kullanmam</td><td>I do not use</td><td className="tur">Ben yemem</td><td>I do not eat</td></tr>
									<tr><td className="tur">Sen kullanmazs&#305;n</td><td>You do not use</td><td className="tur">Sen yemezsin</td><td>You do not eat</td></tr>
									<tr><td className="tur">O kullanmaz</td><td>He/she/it does not use</td><td className="tur">O yemez</td><td>He/she/it does not eat</td></tr>
									<tr><td className="tur">Biz kullanmay&#305;z</td><td>We do not use</td><td className="tur">Biz yemeyiz</td><td>We do not eat</td></tr>
									<tr><td className="tur">Siz kullanmazs&#305;n&#305;z</td><td>You do not use</td><td className="tur">Siz yemezsiniz</td><td>You do not eat</td></tr>
									<tr><td className="tur">Onlar kullanmazlar</td><td>They do not use</td><td className="tur">Onlar yemezler</td><td>They do not eat</td></tr>
								</tbody>
							</Table>


							<SectionHeading title='Positive Question' />
							<ImageRule src={PositiveQuestion} />
							<p>
								While there are three different ways of forming a positive present simple tense verb, we can use one short method to turn them all into questions.
							</p>

							<p>
								The key is to focus on the "r" that was added when the positive verb was formed. The first thing we do is split the verb after the "r". To this we
								add "m&#305;/mi/mu/m&#252;" separately, based on the vowel before the "r". Lastly we attach the personal suffix.
							</p>

							<p>
								For "Onlar", the personal suffix remains attached to the "r". All we do is add "m&#305;" or "mi" to it at the end. Furthermore, for "Ben" and "Biz"
								we add a "y" before the personal suffix to prevent having two vowels side by side.
							</p>

							<Table responsive hover striped size="sm" className="suffixes5col" >
								<thead>
									<tr><th className="corner">Vowel before "r"&#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>m&#305;y&#305;m</td><td>miyim</td><td>muyum</td><td>m&#252;y&#252;m</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>m&#305;s&#305;n</td><td>misin</td><td>musun</td><td>m&#252;s&#252;n</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>m&#305;</td><td>mi</td><td>mu</td><td>m&#252;</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>m&#305;y&#305;z</td><td>miyiz</td><td>muyuz</td><td>m&#252;y&#252;z</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>m&#305;s&#305;n&#305;z</td><td>misiniz</td><td>musunuz</td><td>m&#252;s&#252;n&#252;z</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-lar m&#305;</td><td>-ler mi</td><td>-lar m&#305;</td><td>-ler mi</td></tr>
								</tbody>
								<caption>To be added after the "r" in the positive form<br />(Positive Question)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak(yaz) - to write</td><td colspan="2" className="head">&#231;izmek(&#231;iz) - to draw</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazar m&#305;y&#305;m?</td><td>Do I write?</td><td className="tur">Ben &#231;izer miyim?</td><td>Do I draw?</td></tr>
									<tr><td className="tur">Sen yazar m&#305;s&#305;n?</td><td>Do you write?</td><td className="tur">Sen &#231;izer misin?</td><td>Do you draw?</td></tr>
									<tr><td className="tur">O yazar m&#305;?</td><td>Does he/she/it write?</td><td className="tur">O &#231;izer mi?</td><td>Does he/she/it draw?</td></tr>
									<tr><td className="tur">Biz yazar m&#305;y&#305;z?</td><td>Do we write?</td><td className="tur">Biz &#231;izer miyiz?</td><td>Do we draw?</td></tr>
									<tr><td className="tur">Siz yazar m&#305;s&#305;n&#305;z?</td><td>Do you write?</td><td className="tur">Siz &#231;izer misiniz?</td><td>Do you draw?</td></tr>
									<tr><td className="tur">Onlar yazarlar m&#305;?</td><td>Do they write?</td><td className="tur">Onlar &#231;izerler mi?</td><td>Do they draw?</td></tr>
								</tbody>
							</Table>

							<br />

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">kullanmak(kullan) - to use</td><td colspan="2" className="head">yemek(ye) - to eat</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben kullan&#305;r m&#305;y&#305;m?</td><td>Do I use?</td><td className="tur">Ben yer miyim?</td><td>Do I eat?</td></tr>
									<tr><td className="tur">Sen kullan&#305;r m&#305;s&#305;n?</td><td>Do you use?</td><td className="tur">Sen yer misin?</td><td>Do you eat?</td></tr>
									<tr><td className="tur">O kullan&#305;r m&#305;?</td><td>Does he/she/it use?</td><td className="tur">O yer mi?</td><td>Does he/she/it eat?</td></tr>
									<tr><td className="tur">Biz kullan&#305;r m&#305;y&#305;z?</td><td>Do we use?</td><td className="tur">Biz yer miyiz?</td><td>Do we eat?</td></tr>
									<tr><td className="tur">Siz kullan&#305;r m&#305;s&#305;n&#305;z?</td><td>Do you use?</td><td className="tur">Siz yer misiniz?</td><td>Do you eat?</td></tr>
									<tr><td className="tur">Onlar kullan&#305;rlar m&#305;?</td><td>Do they use?</td><td className="tur">Onlar yerler mi?</td><td>Do they eat?</td></tr>
								</tbody>
							</Table>



							<SectionHeading title='Negative Question' />
							<ImageRule src={NegativeQuestion} />

							<p>
								To form a negative question, we attach "maz/mez" to the verb root depending on its last vowel. We then separately add "m&#305;/mi" together
								with the personal suffix.
							</p>

							<p>
								For "Onlar", the personal suffix gets attached to "maz/mez" instead. Furthermore, for "Ben" and "Biz" we add a "y" before the personal suffix
								to prevent having two vowels side by side.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-maz m&#305;y&#305;m</td><td>-mez miyim</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-maz m&#305;s&#305;n</td><td>-mez misin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-maz m&#305;</td><td>-mez mi</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-maz m&#305;y&#305;z</td><td>-mez miyiz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-maz m&#305;s&#305;n&#305;z</td><td>-mez misiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-mazlar m&#305;</td><td>-mezler mi</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Negative Question)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak(yaz) - to write</td><td colspan="2" className="head">&#231;izmek(&#231;iz) - to draw</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazmaz m&#305;y&#305;m?</td><td>Do I not write?</td><td className="tur">Ben &#231;izmez miyim?</td><td>Do I not draw?</td></tr>
									<tr><td className="tur">Sen yazmaz m&#305;s&#305;n?</td><td>Do you not write?</td><td className="tur">Sen &#231;izmez misin?</td><td>Do you not draw?</td></tr>
									<tr><td className="tur">O yazmaz m&#305;?</td><td>Does he/she/it not write?</td><td className="tur">O &#231;izmez mi?</td><td>Does he/she/it not draw?</td></tr>
									<tr><td className="tur">Biz yazmaz m&#305;y&#305;z?</td><td>Do we not write?</td><td className="tur">Biz &#231;izmez miyiz?</td><td>Do we not draw?</td></tr>
									<tr><td className="tur">Siz yazmaz m&#305;s&#305;n&#305;z?</td><td>Do you not write?</td><td className="tur">Siz &#231;izmez misiniz?</td><td>Do you not draw?</td></tr>
									<tr><td className="tur">Onlar yazmazlar m&#305;?</td><td>Do they not write?</td><td className="tur">Onlar &#231;izmezler mi?</td><td>Do they not draw?</td></tr>
								</tbody>
							</Table>

							<br />

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">kullanmak(kullan) - to use</td><td colspan="2" className="head">yemek(ye) - to eat</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben kullanmaz m&#305;y&#305;m?</td><td>Do I not use?</td><td className="tur">Ben yemez miyim?</td><td>Do I not eat?</td></tr>
									<tr><td className="tur">Sen kullanmaz m&#305;s&#305;n?</td><td>Do you not use?</td><td className="tur">Sen yemez misin?</td><td>Do you not eat?</td></tr>
									<tr><td className="tur">O kullanmaz m&#305;?</td><td>Does he/she/it not use?</td><td className="tur">O yemez mi?</td><td>Does he/she/it not eat?</td></tr>
									<tr><td className="tur">Biz kullanmaz m&#305;y&#305;z?</td><td>Do we not use?</td><td className="tur">Biz yemez miyiz?</td><td>Do we not eat?</td></tr>
									<tr><td className="tur">Siz kullanmaz m&#305;s&#305;n&#305;z?</td><td>Do you not use?</td><td className="tur">Siz yemez misiniz?</td><td>Do you not eat?</td></tr>
									<tr><td className="tur">Onlar kullanmazlar m&#305;?</td><td>Do they not use?</td><td className="tur">Onlar yemezler mi?</td><td>Do they not eat?</td></tr>
								</tbody>
							</Table>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container>
);
