import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Clothing = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Clothing</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Belt</td><td>Kemer</td></tr>
                                        <tr><td>Blouse</td><td>Bluz</td></tr>
                                        <tr><td>Gloves</td><td>Eldiven</td></tr>
                                        <tr><td>Headscarf</td><td>E&#351;arp / Ba&#351;&#246;rt&#252;s&#252;</td></tr>
                                        <tr><td>Jacket</td><td>Mont</td></tr>
                                        <tr><td>Jeans</td><td>Kot pantolon</td></tr>
                                        <tr><td>Jersey</td><td>Kazak</td></tr>
                                        <tr><td>Jubbah / Thobe</td><td>C&#252;bbe</td></tr>
                                        <tr><td>Overcoat (Men's)</td><td>Palto</td></tr>
                                        <tr><td>Overcoat (Women's)</td><td>Manto</td></tr>
                                        <tr><td>Pants / Trousers</td><td>Pantolon</td></tr>
                                        <tr><td>Pyjamas</td><td>Pijama</td></tr>
                                        <tr><td>Shirt</td><td>G&#246;mlek</td></tr>
                                        <tr><td>Shoes</td><td>Ayakkab&#305;</td></tr>
                                        <tr><td>Skirt</td><td>Etek</td></tr>
                                        <tr><td>Socks</td><td>&#199;orap</td></tr>
                                        <tr><td>Suit</td><td>Tak&#305;m elbise</td></tr>
                                        <tr><td>Tie</td><td>Kravat</td></tr>
                                        <tr><td>Tracksuit</td><td>E&#351;ofman</td></tr>
                                        <tr><td>T-shirt</td><td>Ti&#351;&#246;rt</td></tr>
                                        <tr><td>Underwear</td><td>&#304;&#231; &#231;ama&#351;&#305;r</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
