import { Card, Col, Container, Row } from "@themesberg/react-bootstrap";

export const Eating = () => {
	return (<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Eating</h1>
							<p class="phrases">
								<span class="c1">Could we have the menu please?</span><br />
								<span className="text-secondary">Bize menüyü getirir misin?</span><br />
								<br />
								<span class="c1">What would you like to eat?</span><br />
								<span className="text-secondary">Ne yemek istiyorsunuz?</span><br />
								<br />
								<span class="c1">I'll have the ___________ and the ___________.</span><br />
								<span className="text-secondary">___________ ve ___________ alayım.</span><br />
								<br />
								<span class="c1">I'll have one/two portions of the __________.</span><br />
								<span className="text-secondary">Ben bir/iki porsiyon __________ alayım.</span><br />
								<br />
								<span class="c1">Could I have the bill please?</span><br />
								<span className="text-secondary">Hesap lütfen.</span><br />
								<br />
								<span class="c1">Enjoy your meal.</span><br />
								<span className="text-secondary">Afiyet olsun.</span><br />
								<br />
								<br />
							</p>

							<p class="phrases">
								<span class="c1">Restaurant</span><br />
								<span className="text-secondary">Lokanta</span><br />
								<br />
								<span class="c1">Waiter</span><br />
								<span className="text-secondary">Garson</span><br />
								<br />
								<span class="c1">Menu</span><br />
								<span className="text-secondary">Menü</span><br />
								<br />
								<span class="c1">Bill</span><br />
								<span className="text-secondary">Hesap</span><br />
								<br />
								<span class="c1">Food</span><br />
								<span className="text-secondary">Yemek</span><br />
								<br />
								<span class="c1">Soup</span><br />
								<span className="text-secondary">Çorba</span><br />
								<br />
								<span class="c1">Salad</span><br />
								<span className="text-secondary">Salata</span><br />
								<br />
								<span class="c1">Bread</span><br />
								<span className="text-secondary">Ekmek</span><br />
								<br />
								<span class="c1">Meat</span><br />
								<span className="text-secondary">Et</span><br />
								<br />
								<span class="c1">Beef</span><br />
								<span className="text-secondary">Sığır eti</span><br />
								<br />
								<span class="c1">Chicken</span><br />
								<span className="text-secondary">Tavuk</span><br />
								<br />
								<span class="c1">Fish</span><br />
								<span className="text-secondary">Balık</span><br />
								<br />
								<span class="c1">Rice</span><br />
								<span className="text-secondary">Pilav</span><br />
								<br />
								<span class="c1">Dessert</span><br />
								<span className="text-secondary">Tatlı</span><br />
								<br />
								<span class="c1">Drink</span><br />
								<span className="text-secondary">İçecek</span><br />
								<br />
								<span class="c1">Water</span><br />
								<span className="text-secondary">Su</span><br />
								<br />
								<span class="c1">Cola</span><br />
								<span className="text-secondary">Kola</span><br />
								<br />
								<span class="c1">Tea</span><br />
								<span className="text-secondary">Çay</span><br />
								<br />
								<span class="c1">Coffee</span><br />
								<span className="text-secondary">Kahve</span><br />
							</p>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container >
	);
}
