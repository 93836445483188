import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Food = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Food</h1>

                                <h2>Meat and Fish</h2>
                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Beef</td><td>S&#305;&#287;&#305;r eti / Et</td></tr>
                                        <tr><td>Chicken</td><td>Tavuk</td></tr>
                                        <tr><td>Fish</td><td>Bal&#305;k</td></tr>
                                        <tr><td>Lamb</td><td>Kuzu</td></tr>
                                        <tr><td>Sausage</td><td>Sosis</td></tr>
                                        <tr><td>Steak</td><td>Biftek</td></tr>
                                        <tr><td>Turkey</td><td>Hindi</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Grains</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Bread</td><td>Ekmek</td></tr>
                                        <tr><td>Pasta</td><td>Makarna</td></tr>
                                        <tr><td>Rice</td><td>Pirin&#231; / Pilav</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Dairy</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Cheese</td><td>Peynir</td></tr>
                                        <tr><td>Egg</td><td>Yumurta</td></tr>
                                        <tr><td>Ice-cream</td><td>Dondurma</td></tr>
                                        <tr><td>Milk</td><td>S&#252;t</td></tr>
                                        <tr><td>Yogurt</td><td>Yo&#287;urt</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Fruit</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Apple</td><td>Elma</td></tr>
                                        <tr><td>Apricot</td><td>Kay&#305;s&#305;</td></tr>
                                        <tr><td>Banana</td><td>Muz</td></tr>
                                        <tr><td>Grapes</td><td>&#220;z&#252;m</td></tr>
                                        <tr><td>Mango</td><td>Mango</td></tr>
                                        <tr><td>Orange</td><td>Portakal</td></tr>
                                        <tr><td>Peach</td><td>&#350;eftali</td></tr>
                                        <tr><td>Pear</td><td>Armut</td></tr>
                                        <tr><td>Pineapple</td><td>Ananas</td></tr>
                                        <tr><td>Pomegranate</td><td>Nar</td></tr>
                                        <tr><td>Sour cherry</td><td>Vi&#351;ne</td></tr>
                                        <tr><td>Strawberry</td><td>&#199;ilek</td></tr>
                                        <tr><td>Watermelon</td><td>Karpuz</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Vegetables</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Cabbage</td><td>Lahana</td></tr>
                                        <tr><td>Carrot</td><td>Havu&#231;</td></tr>
                                        <tr><td>Corn</td><td>M&#305;s&#305;r</td></tr>
                                        <tr><td>Cucumber</td><td>Salatal&#305;k</td></tr>
                                        <tr><td>Eggplant</td><td>Patl&#305;can</td></tr>
                                        <tr><td>Lettuce</td><td>Marul</td></tr>
                                        <tr><td>Onion</td><td>So&#287;an</td></tr>
                                        <tr><td>Peas</td><td>Bezelye</td></tr>
                                        <tr><td>Potato</td><td>Patates</td></tr>
                                        <tr><td>Spinach</td><td>Ispanak</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Fast food</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Burger</td><td>Burger</td></tr>
                                        <tr><td>French fries</td><td>Patates k&#305;zartmas&#305;</td></tr>
                                        <tr><td>Ketchup</td><td>Ket&#231;ap</td></tr>
                                        <tr><td>Mayonnaise</td><td>Mayonez</td></tr>
                                        <tr><td>Mustard</td><td>Hardal</td></tr>
                                        <tr><td>Pizza</td><td>Pizza</td></tr>
                                        <tr><td>Sandwich</td><td>Sandvi&#231;</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Snacks</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Cake</td><td>Pasta</td></tr>
                                        <tr><td>Candy</td><td>&#350;eker</td></tr>
                                        <tr><td>Chocolate</td><td>&#199;ikolata</td></tr>
                                        <tr><td>Cookies</td><td>Bisk&#252;vi</td></tr>
                                        <tr><td>Dessert</td><td>Tatl&#305;</td></tr>
                                        <tr><td>Potato chips</td><td>Patates cipsi</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Drinks</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Coffee</td><td>Kahve</td></tr>
                                        <tr><td>Cola</td><td>Kola</td></tr>
                                        <tr><td>Fruit juice</td><td>Meyve suyu</td></tr>
                                        <tr><td>Tea</td><td>&#199;ay</td></tr>
                                        <tr><td>Water</td><td>Su</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Spices</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Chili pepper</td><td>Pul biber</td></tr>
                                        <tr><td>Pepper</td><td>Karabiber</td></tr>
                                        <tr><td>Salt</td><td>Tuz</td></tr>
                                        <tr><td>Vinegar</td><td>Sirke</td></tr>
                                    </tbody>
                                </Table>

                                <h2>Meals</h2>
                                <Table responsive hover striped size="sm" className="meet">
                                    <tbody>
                                        <tr><td>Breakfast</td><td>Kahvalt&#305;</td></tr>
                                        <tr><td>Lunch</td><td>&#214;&#287;le yeme&#287;i</td></tr>
                                        <tr><td>Dinner</td><td>Ak&#351;am yeme&#287;i</td></tr>
                                    </tbody>
                                </Table>

                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
