
import React from 'react';
import { Row, Col, Card, Container, Alert, Image, Table } from '@themesberg/react-bootstrap';

import Positive from "../../../assets/img/content/rules/verbs/must/saying_must_positive.jpg";
import Negative from "../../../assets/img/content/rules/verbs/must/saying_must_negative.jpg";
import ImageRule from '../../../components/ImageRule';
import SectionHeading from '../../../components/SectionHeading';

export const Must = () => (
	<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>"Must"</h1>
							<p className="fs-5 fw-light">(mal&#305;/meli)</p>

							<div className="listandtable">
								<div className="listc">
									<p>
										In Turkish we express "must" using the suffixes "mal&#305;/meli". We do this by attaching them to the verb root.
									</p>
									<ol>
										<li>Positive (Eg. You must come)</li>
										<li>Negative (Eg. You must not come)</li>
									</ol>
								</div>
								<div className="tablec">
									<Table responsive hover striped size="sm" className="pronouns2col">
										<tbody>
											<tr className="bg1"><td>I</td><td className="tur">Ben</td></tr>
											<tr className="bg2"><td>You</td><td className="tur">Sen</td></tr>
											<tr className="bg1"><td>He / She / It</td><td className="tur">O</td></tr>
											<tr className="bg2"><td>We</td><td className="tur">Biz</td></tr>
											<tr className="bg1"><td>You (Plural)</td><td className="tur">Siz</td></tr>
											<tr className="bg2"><td>They</td><td className="tur">Onlar</td></tr>
										</tbody>
										<caption>Personal pronouns</caption>
									</Table>
								</div>
							</div>

							<SectionHeading title='Positive' />
							<ImageRule src={Positive} />

							<p>
								We attach one of "mal&#305;/meli" depending on the verb root's last vowel. Thereafter we attach the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-mal&#305;y&#305;m</td><td>-meliyim</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-mal&#305;s&#305;n</td><td>-melisin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-mal&#305;</td><td>-meli</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-mal&#305;y&#305;z</td><td>-meliyiz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-mal&#305;s&#305;n&#305;z</td><td>-melisiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-mal&#305;lar</td><td>-meliler</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Positive)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">gelmek - to come</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazmal&#305;y&#305;m</td><td>I must write</td><td className="tur">Ben gelmeliyim</td><td>I must come</td></tr>
									<tr><td className="tur">Sen yazmal&#305;s&#305;n</td><td>You must write</td><td className="tur">Sen gelmelisin</td><td>You must come</td></tr>
									<tr><td className="tur">O yazmal&#305;</td><td>He/she/it must write</td><td className="tur">O gelmeli</td><td>He/she/it must come</td></tr>
									<tr><td className="tur">Biz yazmal&#305;y&#305;z</td><td>We must write</td><td className="tur">Biz gelmeliyiz</td><td>We must come</td></tr>
									<tr><td className="tur">Siz yazmal&#305;s&#305;n&#305;z</td><td>You must write</td><td className="tur">Siz gelmelisiniz</td><td>You must come</td></tr>
									<tr><td className="tur">Onlar yazmal&#305;lar</td><td>They must write</td><td className="tur">Onlar gelmeliler</td><td>They must come</td></tr>
								</tbody>
							</Table>


							<SectionHeading title='Negative' />
							<ImageRule src={Negative} />

							<p>
								For negative, we attach the negative suffix "ma/me" to the verb root. The suffix we attach depends on the verb root's last vowel. Thereafter
								we attach one of "mal&#305;/meli" and finally we attach the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-mamal&#305;y&#305;m</td><td>-memeliyim</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-mamal&#305;s&#305;n</td><td>-memelisin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-mamal&#305;</td><td>-memeli</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-mamal&#305;y&#305;z</td><td>-memeliyiz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-mamal&#305;s&#305;n&#305;z</td><td>-memelisiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-mamal&#305;lar</td><td>-memeliler</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Negative)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">gelmek - to come</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazmamal&#305;y&#305;m</td><td>I must not write</td><td className="tur">Ben gelmemeliyim</td><td>I must not come</td></tr>
									<tr><td className="tur">Sen yazmamal&#305;s&#305;n</td><td>You must not write</td><td className="tur">Sen gelmemelisin</td><td>You must not come</td></tr>
									<tr><td className="tur">O yazmamal&#305;</td><td>He/she/it must not write</td><td className="tur">O gelmemeli</td><td>He/she/it must not come</td></tr>
									<tr><td className="tur">Biz yazmamal&#305;y&#305;z</td><td>We must not write</td><td className="tur">Biz gelmemeliyiz</td><td>We must not come</td></tr>
									<tr><td className="tur">Siz yazmamal&#305;s&#305;n&#305;z</td><td>You must not write</td><td className="tur">Siz gelmemelisiniz</td><td>You must not come</td></tr>
									<tr><td className="tur">Onlar yazmamal&#305;lar</td><td>They must not write</td><td className="tur">Onlar gelmemeliler</td><td>They must not come</td></tr>
								</tbody>
							</Table>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row >
	</Container >
);
