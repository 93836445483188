
import React from 'react';
import { Row, Col, Card, Container, Alert, Image, Table } from '@themesberg/react-bootstrap';

import Positive from "../../../assets/img/content/rules/verbs/pres_cont/turkish_verbs_present_continuous_positive.jpg";
import Negative from "../../../assets/img/content/rules/verbs/pres_cont/turkish_verbs_present_continuous_positive_question.jpg";
import PositiveQuestion from "../../../assets/img/content/rules/verbs/pres_cont/turkish_verbs_present_continuous_negative.jpg";
import NegativeQuestion from "../../../assets/img/content/rules/verbs/pres_cont/turkish_verbs_present_continuous_negative_question.jpg";
import ImageRule from '../../../components/ImageRule';
import SectionHeading from '../../../components/SectionHeading';

export const PresentContinuousTense = () => (
	<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>

							<h1>Present Continuous Tense</h1>
							<p className="fs-5 fw-light">(yor)</p>

							<p>
								Turkish present continuous tense uses the suffix "yor". The present continuous tense verb is
								basically one big word containing the verb root, "yor" and the personal suffix.
							</p>

							<p>
								We obtain the verb root by removing the "mak/mek" from the infinitive form. For example, the verb root of
								"yazmak" would be "yaz", "izlemek" would be "izle" and "konu&#351;mak" would be "konu&#351;".
							</p>



							<div className="listandtable">
								<div className="listc">
									<p>
										We can express the present continuous tense in four ways:
									</p>
									<ol>
										<li>Positive (Eg. I am writing)</li>
										<li>Negative (Eg. I am not writing)</li>
										<li>Positive Question (Eg. Am I writing?)</li>
										<li>Negative Question (Eg. Am I not writing?)</li>
									</ol>
								</div>
								<div className="tablec">
									<Table responsive striped size="sm" className="pronouns2col">
										<tbody>
											<tr className="bg1"><td>I</td><td>Ben</td></tr>
											<tr className="bg2"><td>You</td><td>Sen</td></tr>
											<tr className="bg1"><td>He / She / It</td><td className="tur">O</td></tr>
											<tr className="bg2"><td>We</td><td className="tur">Biz</td></tr>
											<tr className="bg1"><td>You (Plural)</td><td className="tur">Siz</td></tr>
											<tr className="bg2"><td>They</td><td className="tur">Onlar</td></tr>
										</tbody>
										<caption>Personal pronouns</caption>
									</Table>
								</div>
							</div>

							<SectionHeading title='Positive' />
							<ImageRule src={Positive} />

							<p>To form a positive verb we start off with the verb root. If the verb root ends in a vowel, <span className="underline">we drop the vowel at the end completely</span>.</p>

							<p>To it we attach one of "&#305;/i/u/&#252;". The letter that we choose depends on the verb root's last vowel. Choosing the correct letter is done to
								preserve vowel harmony. Next we attach the present continuous tense suffix "yor". Lastly we attach the personal suffix.</p>

							<Table responsive striped size="sm" className="suffixes5col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-&#305;yorum</td><td>-iyorum</td><td>-uyorum</td><td>-&#252;yorum</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-&#305;yorsun</td><td>-iyorsun</td><td>-uyorsun</td><td>-&#252;yorsun</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-&#305;yor</td><td>-iyor</td><td>-uyor</td><td>-&#252;yor</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-&#305;yoruz</td><td>-iyoruz</td><td>-uyoruz</td><td>-&#252;yoruz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-&#305;yorsunuz</td><td>-iyorsunuz</td><td>-uyorsunuz</td><td>-&#252;yorsunuz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-&#305;yorlar</td><td>-iyorlar</td><td>-uyorlar</td><td>-&#252;yorlar</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Positive)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">izlemek - to watch</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yaz&#305;yorum</td><td>I am writing</td><td className="tur">Ben izliyorum</td><td>I am watching</td></tr>
									<tr><td className="tur">Sen yaz&#305;yorsun</td><td>You are writing</td><td className="tur">Sen izliyorsun</td><td>You are watching</td></tr>
									<tr><td className="tur">O yaz&#305;yor</td><td>He/she/it is writing</td><td className="tur">O izliyor</td><td>He/she/it is watching</td></tr>
									<tr><td className="tur">Biz yaz&#305;yoruz</td><td>We are writing</td><td className="tur">Biz izliyoruz</td><td>We are watching</td></tr>
									<tr><td className="tur">Siz yaz&#305;yorsunuz</td><td>You are writing</td><td className="tur">Siz izliyorsunuz</td><td>You are watching</td></tr>
									<tr><td className="tur">Onlar yaz&#305;yorlar</td><td>They are writing</td><td className="tur">Onlar izliyorlar</td><td>They are watching</td></tr>
								</tbody>
							</Table>

							<Table responsive striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">konu&#351;mak - to speak</td><td colspan="2" className="head">&#246;demek - to pay</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben konu&#351;uyorum</td><td>I am speaking</td><td className="tur">Ben &#246;d&#252;yorum</td><td>I am paying</td></tr>
									<tr><td className="tur">Sen konu&#351;uyorsun</td><td>You are speaking</td><td className="tur">Sen &#246;d&#252;yorsun</td><td>You are paying</td></tr>
									<tr><td className="tur">O konu&#351;uyor</td><td>He/she/it is speaking</td><td className="tur">O &#246;d&#252;yor</td><td>He/she/it is paying</td></tr>
									<tr><td className="tur">Biz konu&#351;uyoruz</td><td>We are speaking</td><td className="tur">Biz &#246;d&#252;yoruz</td><td>We are paying</td></tr>
									<tr><td className="tur">Siz konu&#351;uyorsunuz</td><td>You are speaking</td><td className="tur">Siz &#246;d&#252;yorsunuz</td><td>You are paying</td></tr>
									<tr><td className="tur">Onlar konu&#351;uyorlar</td><td>They are speaking</td><td className="tur">Onlar &#246;d&#252;yorlar</td><td>They are paying</td></tr>
								</tbody>
							</Table>


							<SectionHeading title='Negative' />
							<ImageRule src={Negative} />

							<p>
								For negative, we start off once again with the verb root. However, unlike for positive we don't drop any letters. We attach
								one of "m&#305;/mi/mu/m&#252;" based on the last vowel of the verb root. Next we attach the present continuous tense suffix "yor".
								Finally we attach the personal suffix.
							</p>

							<Table responsive striped size="sm" className="suffixes5col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-m&#305;yorum</td><td>-miyorum</td><td>-muyorum</td><td>-m&#252;yorum</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-m&#305;yorsun</td><td>-miyorsun</td><td>-muyorsun</td><td>-m&#252;yorsun</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-m&#305;yor</td><td>-miyor</td><td>-muyor</td><td>-m&#252;yor</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-m&#305;yoruz</td><td>-miyoruz</td><td>-muyoruz</td><td>-m&#252;yoruz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-m&#305;yorsunuz</td><td>-miyorsunuz</td><td>-muyorsunuz</td><td>-m&#252;yorsunuz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-m&#305;yorlar</td><td>-miyorlar</td><td>-muyorlar</td><td>-m&#252;yorlar</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Negative)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">izlemek - to watch</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazm&#305;yorum</td><td>I am not writing</td><td className="tur">Ben izlemiyorum</td><td>I am not watching</td></tr>
									<tr><td className="tur">Sen yazm&#305;yorsun</td><td>You are not writing</td><td className="tur">Sen izlemiyorsun</td><td>You are not watching</td></tr>
									<tr><td className="tur">O yazm&#305;yor</td><td>He/she/it is not writing</td><td className="tur">O izlemiyor</td><td>He/she/it is not watching</td></tr>
									<tr><td className="tur">Biz yazm&#305;yoruz</td><td>We are not writing</td><td className="tur">Biz izlemiyoruz</td><td>We are not watching</td></tr>
									<tr><td className="tur">Siz yazm&#305;yorsunuz</td><td>You are not writing</td><td className="tur">Siz izlemiyorsunuz</td><td>You are not watching</td></tr>
									<tr><td className="tur">Onlar yazm&#305;yorlar</td><td>They are not writing</td><td className="tur">Onlar izlemiyorlar</td><td>They are not watching</td></tr>
								</tbody>
							</Table>

							<Table responsive striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">konu&#351;mak - to speak</td><td colspan="2" className="head">d&#252;&#351;&#252;nmek - to think</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben konu&#351;muyorum</td><td>I am not speaking</td><td className="tur">Ben d&#252;&#351;&#252;nm&#252;yorum</td><td>I am not thinking</td></tr>
									<tr><td className="tur">Sen konu&#351;muyorsun</td><td>You are not speaking</td><td className="tur">Sen d&#252;&#351;&#252;nm&#252;yorsun</td><td>You are not thinking</td></tr>
									<tr><td className="tur">O konu&#351;muyor</td><td>He/she/it is not speaking</td><td className="tur">O d&#252;&#351;&#252;nm&#252;yor</td><td>He/she/it is not thinking</td></tr>
									<tr><td className="tur">Biz konu&#351;muyoruz</td><td>We are not speaking</td><td className="tur">Biz d&#252;&#351;&#252;nm&#252;yoruz</td><td>We are not thinking</td></tr>
									<tr><td className="tur">Siz konu&#351;muyorsunuz</td><td>You are not speaking</td><td className="tur">Siz d&#252;&#351;&#252;nm&#252;yorsunuz</td><td>You are not thinking</td></tr>
									<tr><td className="tur">Onlar konu&#351;muyorlar</td><td>They are not speaking</td><td className="tur">Onlar d&#252;&#351;&#252;nm&#252;yorlar</td><td>They are not thinking</td></tr>
								</tbody>
							</Table>



							<SectionHeading title='Positive Question' />
							<ImageRule src={PositiveQuestion} />

							<p>
								In order to form a question from the positive form, we split the verb after "yor" and add "mu" before the personal suffix.
								"Onlar" is an exception, all we do is add "m&#305;" at the end. Since we can't have two vowels side by side, we attach a "y"
								to turn "muum" into "muyum" for "Ben". Likewise we turn "muuz" into "muyuz" for "Biz".
							</p>

							<Table responsive striped size="sm" className="suffixes5col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-&#305;yor muyum</td><td>-iyor muyum</td><td>-uyor muyum</td><td>-&#252;yor muyum</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-&#305;yor musun</td><td>-iyor musun</td><td>-uyor musun</td><td>-&#252;yor musun</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-&#305;yor mu</td><td>-iyor mu</td><td>-uyor mu</td><td>-&#252;yor mu</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-&#305;yor muyuz</td><td>-iyor muyuz</td><td>-uyor muyuz</td><td>-&#252;yor muyuz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-&#305;yor musunuz</td><td>-iyor musunuz</td><td>-uyor musunuz</td><td>-&#252;yor musunuz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-&#305;yorlar m&#305;</td><td>-iyorlar m&#305;</td><td>-uyorlar m&#305;</td><td>-&#252;yorlar m&#305;</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Positive Question)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">izlemek - to watch</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yaz&#305;yor muyum?</td><td>Am I writing?</td><td className="tur">Ben izliyor muyum?</td><td>Am I watching?</td></tr>
									<tr><td className="tur">Sen yaz&#305;yor musun?</td><td>Are you writing?</td><td className="tur">Sen izliyor musun?</td><td>Are you watching?</td></tr>
									<tr><td className="tur">O yaz&#305;yor mu?</td><td>Is he/she/it writing?</td><td className="tur">O izliyor mu?</td><td>Is he/she/it watching?</td></tr>
									<tr><td className="tur">Biz yaz&#305;yor muyuz?</td><td>Are we writing?</td><td className="tur">Biz izliyor muyuz?</td><td>Are we watching?</td></tr>
									<tr><td className="tur">Siz yaz&#305;yor musunuz?</td><td>Are you writing?</td><td className="tur">Siz izliyor musunuz?</td><td>Are you watching?</td></tr>
									<tr><td className="tur">Onlar yaz&#305;yorlar m&#305;?</td><td>Are they writing?</td><td className="tur">Onlar izliyorlar m&#305;?</td><td>Are they watching?</td></tr>
								</tbody>
							</Table>


							<Table responsive striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">konu&#351;mak - to speak</td><td colspan="2" className="head">&#246;demek - to pay</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben konu&#351;uyor muyum?</td><td>Am I speaking?</td><td className="tur">Ben &#246;d&#252;yor muyum?</td><td>Am I paying?</td></tr>
									<tr><td className="tur">Sen konu&#351;uyor musun?</td><td>Are you speaking?</td><td className="tur">Sen &#246;d&#252;yor musun?</td><td>Are you paying?</td></tr>
									<tr><td className="tur">O konu&#351;uyor mu?</td><td>Is he/she/it speaking?</td><td className="tur">O &#246;d&#252;yor mu?</td><td>Is he/she/it paying?</td></tr>
									<tr><td className="tur">Biz konu&#351;uyor muyuz?</td><td>Are we speaking?</td><td className="tur">Biz &#246;d&#252;yor muyuz?</td><td>Are we paying?</td></tr>
									<tr><td className="tur">Siz konu&#351;uyor musunuz?</td><td>Are you speaking?</td><td className="tur">Siz &#246;d&#252;yor musunuz?</td><td>Are you paying?</td></tr>
									<tr><td className="tur">Onlar konu&#351;uyorlar m&#305;?</td><td>Are they speaking?</td><td className="tur">Onlar &#246;d&#252;yorlar m&#305;?</td><td>Are they paying?</td></tr>
								</tbody>
							</Table>


							<SectionHeading title='Negative Question' />
							<ImageRule src={NegativeQuestion} />

							<p>
								Forming a question out of the negative form is similar to the positive. We break the negative verb into two parts after "yor" and place
								"mu" just before the personal suffix. "Onlar" doesn't obey the rule, all we do is add "m&#305;" at the end.
							</p>

							<Table responsive striped size="sm" className="suffixes5col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-m&#305;yor muyum</td><td>-miyor muyum</td><td>-muyor muyum</td><td>-m&#252;yor muyum</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-m&#305;yor musun</td><td>-miyor musun</td><td>-muyor musun</td><td>-m&#252;yor musun</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-m&#305;yor mu</td><td>-miyor mu</td><td>-muyor mu</td><td>-m&#252;yor mu</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-m&#305;yor muyuz</td><td>-miyor muyuz</td><td>-muyor muyuz</td><td>-m&#252;yor muyuz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-m&#305;yor musunuz</td><td>-miyor musunuz</td><td>-muyor musunuz</td><td>-m&#252;yor musunuz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-m&#305;yorlar m&#305;</td><td>-miyorlar m&#305;</td><td>-muyorlar m&#305;</td><td>-m&#252;yorlar m&#305;</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Negative Question)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">izlemek - to watch</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazm&#305;yor muyum?</td><td>Am I not writing?</td><td className="tur">Ben izlemiyor muyum?</td><td>Am I not watching?</td></tr>
									<tr><td className="tur">Sen yazm&#305;yor musun?</td><td>Are you not writing?</td><td className="tur">Sen izlemiyor musun?</td><td>Are you not watching?</td></tr>
									<tr><td className="tur">O yazm&#305;yor mu?</td><td>Is he/she/it not writing?</td><td className="tur">O izlemiyor mu?</td><td>Is he/she/it not watching?</td></tr>
									<tr><td className="tur">Biz yazm&#305;yor muyuz?</td><td>Are we not writing?</td><td className="tur">Biz izlemiyor muyuz?</td><td>Are we not watching?</td></tr>
									<tr><td className="tur">Siz yazm&#305;yor musunuz?</td><td>Are you not writing?</td><td className="tur">Siz izlemiyor musunuz?</td><td>Are you not watching?</td></tr>
									<tr><td className="tur">Onlar yazm&#305;yorlar m&#305;?</td><td>Are they not writing?</td><td className="tur">Onlar izlemiyorlar m&#305;?</td><td>Are they not watching?</td></tr>
								</tbody>
							</Table>

							<Table responsive striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">konu&#351;mak - to speak</td><td colspan="2" className="head">d&#252;&#351;&#252;nmek - to think</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben konu&#351;muyor muyum?</td><td>Am I not speaking?</td><td className="tur">Ben d&#252;&#351;&#252;nm&#252;yor muyum?</td><td>Am I not thinking?</td></tr>
									<tr><td className="tur">Sen konu&#351;muyor musun?</td><td>Are you not speaking?</td><td className="tur">Sen d&#252;&#351;&#252;nm&#252;yor musun?</td><td>Are you not thinking?</td></tr>
									<tr><td className="tur">O konu&#351;muyor mu?</td><td>Is he/she/it not speaking?</td><td className="tur">O d&#252;&#351;&#252;nm&#252;yor mu?</td><td>Is he/she/it not thinking?</td></tr>
									<tr><td className="tur">Biz konu&#351;muyor muyuz?</td><td>Are we not speaking?</td><td className="tur">Biz d&#252;&#351;&#252;nm&#252;yor muyuz?</td><td>Are we not thinking?</td></tr>
									<tr><td className="tur">Siz konu&#351;muyor musunuz?</td><td>Are you not speaking?</td><td className="tur">Siz d&#252;&#351;&#252;nm&#252;yor musunuz?</td><td>Are you not thinking?</td></tr>
									<tr><td className="tur">Onlar konu&#351;muyorlar m&#305;?</td><td>Are they not speaking?</td><td className="tur">Onlar d&#252;&#351;&#252;nm&#252;yorlar m&#305;?</td><td>Are they not thinking?</td></tr>
								</tbody>
							</Table>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container>
);
