
import React from 'react';
import { Row, Col, Card, Container, Table } from '@themesberg/react-bootstrap';

import Positive from "../../../assets/img/content/rules/verbs/rep_past/turkish_reported_past_positive.jpg";
import Negative from "../../../assets/img/content/rules/verbs/rep_past/turkish_reported_past_positive_question.jpg";
import PositiveQuestion from "../../../assets/img/content/rules/verbs/rep_past/turkish_reported_past_negative.jpg";
import NegativeQuestion from "../../../assets/img/content/rules/verbs/rep_past/turkish_reported_past_negative_question.jpg";
import ImageRule from '../../../components/ImageRule';
import { Link } from 'react-router-dom';
import SectionHeading from '../../../components/SectionHeading';

export const ReportedPastTense = () => (
	<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Reported Past Tense</h1>
							<p className="fs-5 fw-light">(m&#305;&#351;/mi&#351;/mu&#351;/m&#252;&#351;)</p>

							<p>
								There are two types of past tense in Turkish: definite past tense and reported past tense.
							</p>

							<p>
								We use reported past tense for events that are "reported" to us by someone else. It is used when there isn't complete certainty about whether a
								particular event has taken place or not.
							</p>

							<p>
								For example, let's say you ask your friend about where he went for the summer holiday. He tells you that he went to Turkey.
								Now if someone else asks you about where your friend went for the holiday, you would tell them "Arkada&#351;&#305;m T&#252;rkiyeye
								git<span className="empha">mi&#351;</span>"	("My friend went to Turkey"). Since you didn't witness your friend spend his holiday, the
								information is only <span className="empha">reported</span>.
							</p>

							<p>
								Since reported past tense has no equivalent in English, <Link to={"./definite-past-tense"} >definite</Link> and
								reported past tense usually look the same when translated:
							</p>

							<p className="scd">
								Arkada&#351;&#305;m T&#252;rkiyeye gitti - My friend went to Turkey<br />
								Arkada&#351;&#305;m T&#252;rkiyeye gitmi&#351; - My friend went to Turkey
							</p>

							<p>
								To form a reported past tense verb we start by choosing one of "m&#305;&#351;/mi&#351;/mu&#351;/m&#252;&#351;" based on the last vowel of
								the verb root. We obtain the verb root by removing the "mak/mek" from the infinitive form. For example, the verb root of "yazmak" would be "yaz",
								"gitmek" would be "git" and  "d&#252;&#351;mek" would be "d&#252;&#351;".
							</p>

							<div className="listandtable">
								<div className="listc">
									<p>
										We can express the reported past tense in four ways:
									</p>
									<ol>
										<li>Positive (Eg. He went)</li>
										<li>Negative (Eg. He did not go)</li>
										<li>Positive Question (Eg. Did he go?)</li>
										<li>Negative Question (Eg. Did he not go?)</li>
									</ol>
								</div>
								<div className="tablec">
									<Table responsive hover striped size="sm" caption className="pronouns2col caption">
										<tbody>
											<tr className="bg1"><td>I</td><td>Ben</td></tr>
											<tr className="bg2"><td>You</td><td>Sen</td></tr>
											<tr className="bg1"><td>He / She / It</td><td>O</td></tr>
											<tr className="bg2"><td>We</td><td>Biz</td></tr>
											<tr className="bg1"><td>You (Plural)</td><td>Siz</td></tr>
											<tr className="bg2"><td>They</td><td>Onlar</td></tr>
										</tbody>
										<caption>Personal pronouns</caption>
									</Table>
								</div>
							</div>


							<SectionHeading title='Positive' />
							<ImageRule src={Positive} />

							<p>
								We attach one of "m&#305;&#351;/mi&#351;/mu&#351;/m&#252;&#351;" to the verb root based on its last vowel. We then add the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes5col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-m&#305;&#351;&#305;m</td><td>-mi&#351;im</td><td>-mu&#351;um</td><td>-m&#252;&#351;&#252;m</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-m&#305;&#351;s&#305;n</td><td>-mi&#351;sin</td><td>-mu&#351;sun</td><td>-m&#252;&#351;s&#252;n</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-m&#305;&#351;</td><td>-mi&#351;</td><td>-mu&#351;</td><td>-m&#252;&#351;</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-m&#305;&#351;&#305;z</td><td>-mi&#351;iz</td><td>-mu&#351;uz</td><td>-m&#252;&#351;&#252;z</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-m&#305;&#351;s&#305;n&#305;z</td><td>-mi&#351;siniz</td><td>-mu&#351;sunuz</td><td>-m&#252;&#351;s&#252;n&#252;z</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-m&#305;&#351;lar</td><td>-mi&#351;ler</td><td>-mu&#351;lar</td><td>-m&#252;&#351;ler</td></tr>
								</tbody>
								<caption>Suffixes to be added to verb root (Positive)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">gitmek - to go</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazm&#305;&#351;&#305;m</td><td>I wrote</td><td className="tur">Ben gitmi&#351;im</td><td>I went</td></tr>
									<tr><td className="tur">Sen yazm&#305;&#351;s&#305;n</td><td>You wrote</td><td className="tur">Sen gitmi&#351;sin</td><td>You went</td></tr>
									<tr><td className="tur">O yazm&#305;&#351;</td><td>He/she/it wrote</td><td className="tur">O gitmi&#351;</td><td>He/she/it went</td></tr>
									<tr><td className="tur">Biz yazm&#305;&#351;&#305;z</td><td>We wrote</td><td className="tur">Biz gitmi&#351;iz</td><td>We went</td></tr>
									<tr><td className="tur">Siz yazm&#305;&#351;s&#305;n&#305;z</td><td>You wrote</td><td className="tur">Siz gitmi&#351;siniz</td><td>You went</td></tr>
									<tr><td className="tur">Onlar yazm&#305;&#351;lar</td><td>They wrote</td><td className="tur">Onlar gitmi&#351;ler</td><td>They went</td></tr>
								</tbody>
							</Table>


							<SectionHeading title='Negative' />
							<ImageRule src={Negative} />
							<p>
								The negative suffixes here are "ma/me". We attach the appropriate suffix to the verb root according to the rules of vowel harmony. We then attach one of "m&#305;&#351;/mi&#351;". Together we will only be attaching one of "mam&#305;&#351;/memi&#351;" to the verb root. Lastly we attach the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-mam&#305;&#351;&#305;m</td><td>-memi&#351;im</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-mam&#305;&#351;s&#305;n</td><td>-memi&#351;sin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-mam&#305;&#351;</td><td>-memi&#351;</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-mam&#305;&#351;&#305;z</td><td>-memi&#351;iz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-mam&#305;&#351;s&#305;n&#305;z</td><td>-memi&#351;siniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-mam&#305;&#351;lar</td><td>-memi&#351;ler</td></tr>
								</tbody>
								<caption>Suffixes to be added to verb root (Negative)</caption>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">gitmek - to go</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazmam&#305;&#351;&#305;m</td><td>I did not write</td><td className="tur">Ben gitmemi&#351;im</td><td>I did not go</td></tr>
									<tr><td className="tur">Sen yazmam&#305;&#351;s&#305;n</td><td>You did not write</td><td className="tur">Sen gitmemi&#351;sin</td><td>You did not go</td></tr>
									<tr><td className="tur">O yazmam&#305;&#351;</td><td>He/she/it did not write</td><td className="tur">O gitmemi&#351;</td><td>He/she/it did not go</td></tr>
									<tr><td className="tur">Biz yazmam&#305;&#351;&#305;z</td><td>We did not write</td><td className="tur">Biz gitmemi&#351;iz</td><td>We did not go</td></tr>
									<tr><td className="tur">Siz yazmam&#305;&#351;s&#305;n&#305;z</td><td>You did not write</td><td className="tur">Siz gitmemi&#351;siniz</td><td>You did not go</td></tr>
									<tr><td className="tur">Onlar yazmam&#305;&#351;lar</td><td>They did not write</td><td className="tur">Onlar gitmemi&#351;ler</td><td>They did not go</td></tr>
								</tbody>
							</Table>



							<SectionHeading title='Positive Question' />
							<ImageRule src={PositiveQuestion} />
							<p>
								In order to form a question out of the positive verb we split it after the reported past tense suffix "m&#305;&#351;/mi&#351;/mu&#351;/m&#252;&#351;", forming two words. We then attach one of "m&#305;/mi/mu/m&#252;" before the personal suffix. Collectively, we are left with four options to attach to the verb root before adding the personal suffix:  "-m&#305;&#351; m&#305;-/-mi&#351; mi-/-mu&#351; mu-/-m&#252;&#351; m&#252;-".
							</p>

							<p>
								Onlar only gets "m&#305;" or "mi" added to it at the end. Furthermore, for Ben and Biz we add a "y" before the personal suffix to prevent having two vowels side by side.
							</p>

							<Table responsive hover striped size="sm" className="suffixes5col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-m&#305;&#351; m&#305;y&#305;m</td><td>-mi&#351; miyim</td><td>-mu&#351; muyum</td><td>-m&#252;&#351; m&#252;y&#252;m</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-m&#305;&#351; m&#305;s&#305;n</td><td>-mi&#351; misin</td><td>-mu&#351; musun</td><td>-m&#252;&#351; m&#252;s&#252;n</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-m&#305;&#351; m&#305;</td><td>-mi&#351; mi</td><td>-mu&#351; mu</td><td>-m&#252;&#351; m&#252;</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-m&#305;&#351; m&#305;y&#305;z</td><td>-mi&#351; miyiz</td><td>-mu&#351; muyuz</td><td>-m&#252;&#351; m&#252;y&#252;z</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-m&#305;&#351; m&#305;s&#305;n&#305;z</td><td>-mi&#351; misiniz</td><td>-mu&#351; musunuz</td><td>-m&#252;&#351; m&#252;s&#252;n&#252;z</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-m&#305;&#351;lar m&#305;</td><td>-mi&#351;ler mi</td><td>-mu&#351;lar m&#305;</td><td>-m&#252;&#351;ler mi</td></tr>
								</tbody>
								<caption>Suffixes to be added to verb root (Positive Question)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">gitmek - to go</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazm&#305;&#351; m&#305;y&#305;m?</td><td>Did I write?</td><td className="tur">Ben gitmi&#351; miyim?</td><td>Did I go?</td></tr>
									<tr><td className="tur">Sen yazm&#305;&#351; m&#305;s&#305;n?</td><td>Did you write?</td><td className="tur">Sen gitmi&#351; misin?</td><td>Did you go?</td></tr>
									<tr><td className="tur">O yazm&#305;&#351; m&#305;?</td><td>Did he/she/it write?</td><td className="tur">O gitmi&#351; mi?</td><td>Did he/she/it go?</td></tr>
									<tr><td className="tur">Biz yazm&#305;&#351; m&#305;y&#305;z?</td><td>Did we write?</td><td className="tur">Biz gitmi&#351; miyiz?</td><td>Did we go?</td></tr>
									<tr><td className="tur">Siz yazm&#305;&#351; m&#305;s&#305;n&#305;z?</td><td>Did you write?</td><td className="tur">Siz gitmi&#351; misiniz?</td><td>Did you go?</td></tr>
									<tr><td className="tur">Onlar yazm&#305;&#351;lar m&#305;?</td><td>Did they write?</td><td className="tur">Onlar gitmi&#351;ler mi?</td><td>Did they go?</td></tr>
								</tbody>
							</Table>



							<SectionHeading title='Negative Question' />
							<ImageRule src={NegativeQuestion} />

							<p>
								To convert a negative reported past tense verb into a question we split it after "m&#305;&#351;/mi&#351;", thereafter attaching one of "m&#305;/mi" before the personal suffix.
							</p>

							<p>
								Onlar only gets "m&#305;" or "mi" added to it at the end. Furthermore, for Ben and Biz we add a "y" before the personal suffix to prevent having two vowels side by side.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-mam&#305;&#351; m&#305;y&#305;m</td><td>-memi&#351; miyim</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-mam&#305;&#351; m&#305;s&#305;n</td><td>-memi&#351; misin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-mam&#305;&#351; m&#305;</td><td>-memi&#351; mi</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-mam&#305;&#351; m&#305;y&#305;z</td><td>-memi&#351; miyiz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-mam&#305;&#351; m&#305;s&#305;n&#305;z</td><td>-memi&#351; misiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-mam&#305;&#351;lar m&#305;</td><td>-memi&#351;ler mi</td></tr>
								</tbody>
								<caption>Suffixes to be added to verb root (Negative Question)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">gitmek - to go</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazmam&#305;&#351; m&#305;y&#305;m?</td><td>Did I not write?</td><td className="tur">Ben gitmemi&#351; miyim?</td><td>Did I not go?</td></tr>
									<tr><td className="tur">Sen yazmam&#305;&#351; m&#305;s&#305;n?</td><td>Did you not write?</td><td className="tur">Sen gitmemi&#351; misin?</td><td>Did you not go?</td></tr>
									<tr><td className="tur">O yazmam&#305;&#351; m&#305;?</td><td>Did he/she/it not write?</td><td className="tur">O gitmemi&#351; mi?</td><td>Did he/she/it not go?</td></tr>
									<tr><td className="tur">Biz yazmam&#305;&#351; m&#305;&#305;z?</td><td>Did we not write?</td><td className="tur">Biz gitmemi&#351; miyiz?</td><td>Did we not go?</td></tr>
									<tr><td className="tur">Siz yazmam&#305;&#351; m&#305;s&#305;n&#305;z?</td><td>Did you not write?</td><td className="tur">Siz gitmemi&#351; misiniz?</td><td>Did you not go?</td></tr>
									<tr><td className="tur">Onlar yazmam&#305;&#351;lar m&#305;?</td><td>Did they not write?</td><td className="tur">Onlar gitmemi&#351;ler mi?</td><td>Did they not go?</td></tr>
								</tbody>
							</Table>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row >
	</Container >
);
