import React from 'react';
import { Row, Col, Card, Container, Table } from '@themesberg/react-bootstrap';

import Positive from "../../../assets/img/content/rules/verbs/future/future_tense_positive.jpg";
import Negative from "../../../assets/img/content/rules/verbs/future/future_tense_positive_question.jpg";
import PositiveQuestion from "../../../assets/img/content/rules/verbs/future/future_tense_negative.jpg";
import NegativeQuestion from "../../../assets/img/content/rules/verbs/future/future_tense_negative_question.jpg";
import ImageRule from '../../../components/ImageRule';
import SectionHeading from '../../../components/SectionHeading';

export const FutureTense = () => (
	<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Future Tense</h1>
							<p className="fs-5 fw-light">(acak/ecek)</p>

							<p>
								Turkish future tense uses the suffixes "acak" and "ecek". The future tense verb contains the
								verb root, "acak" or "ecek" and the personal suffix.
							</p>

							<p>
								We obtain the verb root by removing the "mak/mek" from the infinitive form. For example, the verb root of "yazmak" would be "yaz",
								"izlemek" would be "izle" and "ba&#351;lamak" would be "ba&#351;la".
							</p>

							<div className="listandtable">
								<div className="listc">
									<p>
										Turkish future tense can be expressed in four ways:
									</p>
									<ol>
										<li>Positive (Eg. I will write)</li>
										<li>Negative (Eg. I will not write)</li>
										<li>Positive Question (Eg. Will I write?)</li>
										<li>Negative Question (Eg. Will I not write?)</li>
									</ol>
								</div>
								<div className="tablec">
									<Table responsive hover striped size="sm" className="pronouns2col">
										<tbody>
											<tr className="bg1"><td>I</td><td className="tur">Ben</td></tr>
											<tr className="bg2"><td>You</td><td className="tur">Sen</td></tr>
											<tr className="bg1"><td>He / She / It</td><td className="tur">O</td></tr>
											<tr className="bg2"><td>We</td><td className="tur">Biz</td></tr>
											<tr className="bg1"><td>You (Plural)</td><td className="tur">Siz</td></tr>
											<tr className="bg2"><td>They</td><td className="tur">Onlar</td></tr>
										</tbody>
									</Table>
								</div>
							</div>

							<SectionHeading title='Positive' />
							<ImageRule src={Positive} />

							<p>
								To form a positive future tense verb, we start off with the verb root. We attach one of "acak/ecek" based on the last vowel found in the
								verb root. Finally we attach the personal suffix.
							</p>

							<p>
								If the verb root ends in a vowel, we add a "y" before "acak/ecek" as two vowels cannot be placed side by side. Furthermore, for "Ben" and "Biz",
								the "k" at the end of "acak/ecek" turns into a "&#287;" since the letter immediately after it is a vowel.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-[y]aca&#287;&#305;m</td><td>-[y]ece&#287;im</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-[y]acaks&#305;n</td><td>-[y]eceksin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-[y]acak</td><td>-[y]ecek</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-[y]aca&#287;&#305;z</td><td>-[y]ece&#287;iz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-[y]acaks&#305;n&#305;z</td><td>-[y]eceksiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-[y]acaklar</td><td>-[y]ecekler</td></tr>
								</tbody>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">g&#252;lmek - to laugh</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazaca&#287;&#305;m</td><td>I will write</td><td className="tur">Ben g&#252;lece&#287;im</td><td>I will laugh</td></tr>
									<tr><td className="tur">Sen yazacaks&#305;n</td><td>You will write</td><td className="tur">Sen g&#252;leceksin</td><td>You will laugh</td></tr>
									<tr><td className="tur">O yazacak</td><td>He/she/it will write</td><td className="tur">O g&#252;lecek</td><td>He/she/it will laugh</td></tr>
									<tr><td className="tur">Biz yazaca&#287;&#305;z</td><td>We will write</td><td className="tur">Biz g&#252;lece&#287;iz</td><td>We will laugh</td></tr>
									<tr><td className="tur">Siz yazacaks&#305;n&#305;z</td><td>You will write</td><td className="tur">Siz g&#252;leceksiniz</td><td>You will laugh</td></tr>
									<tr><td className="tur">Onlar yazacaklar</td><td>They will write</td><td className="tur">Onlar g&#252;lecekler</td><td>They will laugh</td></tr>
								</tbody>
							</Table>

							<p>
								The letter "y" indicated inside square brackets [y] in the table above should be added when the last letter of the verb
								root is a vowel:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">ba&#351;lamak - to start</td><td colspan="2" className="head">izlemek - to watch</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben ba&#351;layaca&#287;&#305;m</td><td>I will start</td><td className="tur">Ben izleyece&#287;im</td><td>I will watch</td></tr>
									<tr><td className="tur">Sen ba&#351;layacaks&#305;n</td><td>You will start</td><td className="tur">Sen izleyeceksin</td><td>You will watch</td></tr>
									<tr><td className="tur">O ba&#351;layacak</td><td>He/she/it will start</td><td className="tur">O izleyecek</td><td>He/she/it will watch</td></tr>
									<tr><td className="tur">Biz ba&#351;layaca&#287;&#305;z</td><td>We will start</td><td className="tur">Biz izleyece&#287;iz</td><td>We will watch</td></tr>
									<tr><td className="tur">Siz ba&#351;layacaks&#305;n&#305;z</td><td>You will start</td><td className="tur">Siz izleyeceksiniz</td><td>You will watch</td></tr>
									<tr><td className="tur">Onlar ba&#351;layacaklar</td><td>They will start</td><td className="tur">Onlar izleyecekler</td><td>They will watch</td></tr>
								</tbody>
							</Table>



							<SectionHeading title='Negative' />
							<ImageRule src={Negative} />

							<p>
								For negative future tense, we attach one of "ma/me" to the verb root based on its last vowel. We would then add the future tense suffix
								"acak/ecek", but because this would place two vowels side by side we have to add a "y" in the middle. Unlike for positive,
								this "y" will always be there no matter what the verb root is. Finally we add the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-mayaca&#287;&#305;m</td><td>-meyece&#287;im</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-mayacaks&#305;n</td><td>-meyeceksin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-mayacak</td><td>-meyecek</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-mayaca&#287;&#305;z</td><td>-meyece&#287;iz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-mayacaks&#305;n&#305;z</td><td>-meyeceksiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-mayacaklar</td><td>-meyecekler</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Negative)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">izlemek - to watch</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazmayaca&#287;&#305;m</td><td>I will not write</td><td className="tur">Ben izlemeyece&#287;im</td><td>I will not watch</td></tr>
									<tr><td className="tur">Sen yazmayacaks&#305;n</td><td>You will not write</td><td className="tur">Sen izlemeyeceksin</td><td>You will not watch</td></tr>
									<tr><td className="tur">O yazmayacak</td><td>He/she/it will not write</td><td className="tur">O izlemeyecek</td><td>He/she/it will not watch</td></tr>
									<tr><td className="tur">Biz yazmayaca&#287;&#305;z</td><td>We will not write</td><td className="tur">Biz izlemeyece&#287;iz</td><td>We will not watch</td></tr>
									<tr><td className="tur">Siz yazmayacaks&#305;n&#305;z</td><td>You will not write</td><td className="tur">Siz izlemeyeceksiniz</td><td>You will not watch</td></tr>
									<tr><td className="tur">Onlar yazmayacaklar</td><td>They will not write</td><td className="tur">Onlar izlemeyecekler</td><td>They will not watch</td></tr>
								</tbody>
							</Table>



							<SectionHeading title='Positive Question' />
							<ImageRule src={PositiveQuestion} />

							<p>
								To form a question from the positive form, we split the verb after "acak/ecek" and attach one of "m&#305;/mi" before the personal suffix. "Onlar"
								doesn't obey the rule, all we do is add "m&#305;/mi" at the end. For "Ben", we also add a "y" before the personal suffix to prevent having two
								vowels side by side, giving "m&#305;y&#305;m/miyim". We do the same for "Biz" to get "m&#305;y&#305;z/miyiz".
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-[y]acak m&#305;y&#305;m</td><td>-[y]ecek miyim</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-[y]acak m&#305;s&#305;n</td><td>-[y]ecek misin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-[y]acak m&#305;</td><td>-[y]ecek mi</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-[y]acak m&#305;y&#305;z</td><td>-[y]ecek miyiz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-[y]acak m&#305;s&#305;n&#305;z</td><td>-[y]ecek misiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-[y]acaklar m&#305;</td><td>-[y]ecekler mi</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Positive Question)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">g&#252;lmek - to laugh</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazacak m&#305;y&#305;m?</td><td>Will I write?</td><td className="tur">Ben g&#252;lecek miyim?</td><td>Will I laugh?</td></tr>
									<tr><td className="tur">Sen yazacak m&#305;s&#305;n?</td><td>Will you write?</td><td className="tur">Sen g&#252;lecek misin?</td><td>Will you laugh?</td></tr>
									<tr><td className="tur">O yazacak m&#305;?</td><td>Will he/she/it write?</td><td className="tur">O g&#252;lecek mi?</td><td>Will he/she/it laugh?</td></tr>
									<tr><td className="tur">Biz yazacak m&#305;y&#305;z?</td><td>Will we write?</td><td className="tur">Biz g&#252;lecek miyiz?</td><td>Will we laugh?</td></tr>
									<tr><td className="tur">Siz yazacak m&#305;s&#305;n&#305;z?</td><td>Will you write?</td><td className="tur">Siz g&#252;lecek misiniz?</td><td>Will you laugh?</td></tr>
									<tr><td className="tur">Onlar yazacaklar m&#305;?</td><td>Will they write?</td><td className="tur">Onlar g&#252;lecekler mi?</td><td>Will they laugh?</td></tr>
								</tbody>
							</Table>

							<p>
								The letter "y" indicated inside square brackets [y] in the table above should be added when the last letter of the verb
								root is a vowel:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">ba&#351;lamak - to start</td><td colspan="2" className="head">izlemek - to watch</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben ba&#351;layacak m&#305;y&#305;m?</td><td>Will I start?</td><td className="tur">Ben izleyecek miyim?</td><td>Will I watch?</td></tr>
									<tr><td className="tur">Sen ba&#351;layacak m&#305;s&#305;n?</td><td>Will you start?</td><td className="tur">Sen izleyecek misin?</td><td>Will you watch?</td></tr>
									<tr><td className="tur">O ba&#351;layacak m&#305;?</td><td>Will he/she/it start?</td><td className="tur">O izleyecek mi?</td><td>Will he/she/it watch?</td></tr>
									<tr><td className="tur">Biz ba&#351;layacak m&#305;y&#305;z?</td><td>Will we start?</td><td className="tur">Biz izleyecek miyiz?</td><td>Will we watch?</td></tr>
									<tr><td className="tur">Siz ba&#351;layacak m&#305;s&#305;n&#305;z?</td><td>Will you start?</td><td className="tur">Siz izleyecek misiniz?</td><td>Will you watch?</td></tr>
									<tr><td className="tur">Onlar ba&#351;layacaklar m&#305;?</td><td>Will they start?</td><td className="tur">Onlar izleyecekler mi?</td><td>Will they watch?</td></tr>
								</tbody>
							</Table>


							<SectionHeading title='Negative Question' />
							<ImageRule src={NegativeQuestion} />

							<p>
								Forming a negative question is similar to forming a positive question. We break the verb into two parts after "mayacak/meyecek", placing
								"m&#305;/mi" just before the personal suffix. "Onlar" is an exception, all we do is add "m&#305;/mi" at the end.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col" >
								<thead>
									<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Ben</td><td>-mayacak m&#305;y&#305;m</td><td>-meyecek miyim</td></tr>
									<tr className="bg2"><td className="colh">Sen</td><td>-mayacak m&#305;s&#305;n</td><td>-meyecek misin</td></tr>
									<tr className="bg1"><td className="colh">O</td><td>-mayacak m&#305;</td><td>-meyecek mi</td></tr>
									<tr className="bg2"><td className="colh">Biz</td><td>-mayacak m&#305;y&#305;z</td><td>-meyecek miyiz</td></tr>
									<tr className="bg1"><td className="colh">Siz</td><td>-mayacak m&#305;s&#305;n&#305;z</td><td>-meyecek misiniz</td></tr>
									<tr className="bg2"><td className="colh">Onlar</td><td>-mayacaklar m&#305;</td><td>-meyecekler mi</td></tr>
								</tbody>
								<caption>Suffixes to be added to the verb root (Negative Question)</caption>
							</Table>


							<p className='mt-4'>
								Examples:
							</p>

							<Table responsive hover striped size="sm" className="mt-4">
								<thead>
									<tr><td colspan="2" className="head">yazmak - to write</td><td colspan="2" className="head">izlemek - to watch</td></tr>
								</thead>
								<tbody>
									<tr><td className="tur">Ben yazmayacak m&#305;y&#305;m?</td><td>Will I not write?</td><td className="tur">Ben izlemeyecek miyim?</td><td>Will I not watch?</td></tr>
									<tr><td className="tur">Sen yazmayacak m&#305;s&#305;n?</td><td>Will you not write?</td><td className="tur">Sen izlemeyecek misin?</td><td>Will you not watch?</td></tr>
									<tr><td className="tur">O yazmayacak m&#305;?</td><td>Will he/she/it not write?</td><td className="tur">O izlemeyecek mi?</td><td>Will he/she/it not watch?</td></tr>
									<tr><td className="tur">Biz yazmayacak m&#305;y&#305;z?</td><td>Will we not write?</td><td className="tur">Biz izlemeyecek miyiz?</td><td>Will we not watch?</td></tr>
									<tr><td className="tur">Siz yazmayacak m&#305;s&#305;n&#305;z?</td><td>Will you not write?</td><td className="tur">Siz izlemeyecek misiniz?</td><td>Will you not watch?</td></tr>
									<tr><td className="tur">Onlar yazmayacaklar m&#305;?</td><td>Will they not write?</td><td className="tur">Onlar izlemeyecekler mi?</td><td>Will they not watch?</td></tr>
								</tbody>
							</Table>

						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container>
);
