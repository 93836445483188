import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Politics = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Politics</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Ballot box</td><td>Sandık</td></tr>
                                        <tr><td>Border</td><td>S&#305;n&#305;r</td></tr>
                                        <tr><td>Citizen</td><td>Vatanda&#351;</td></tr>
                                        <tr><td>Constitution</td><td>Anayasa</td></tr>
                                        <tr><td>Coup</td><td>Darbe</td></tr>
                                        <tr><td>Democracy</td><td>Demokrasi</td></tr>
                                        <tr><td>Economy</td><td>Ekonomi</td></tr>
                                        <tr><td>Election</td><td>Se&#231;im</td></tr>
                                        <tr><td>Government</td><td>H&#252;k&#252;met</td></tr>
                                        <tr><td>Minister</td><td>Bakan</td></tr>
                                        <tr><td>Parliament</td><td>Parlamento / Meclis</td></tr>
                                        <tr><td>Party</td><td>Parti</td></tr>
                                        <tr><td>Politician</td><td>Siyaset&#231;i</td></tr>
                                        <tr><td>Politics</td><td>Siyaset</td></tr>
                                        <tr><td>President</td><td>Cumhurba&#351;kan&#305; / Devlet Ba&#351;kan&#305;</td></tr>
                                        <tr><td>Prime Minister</td><td>Ba&#351;bakan</td></tr>
                                        <tr><td>Republic</td><td>Cumhuriyet</td></tr>
                                        <tr><td>Vote</td><td>Oy</td></tr>
                                        <tr><td>War</td><td>Sava&#351;</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
