
import { Card, Col, Container, Row } from '@themesberg/react-bootstrap';
import React from "react";
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';

export default () => {
  return (
    <Container className="px-0">
      <Row>
        <Col xs={12} className="p-3">
          <Card>
            <Card.Body>
              <article>
                <h1 id="contents">Contents</h1>

                <p className="fs-5 fw-bold">Grammar</p>
                <ul className="docs-list">
                  {Object.entries(Routes.Grammar).map(([key, value]) => (
                    <li><Link to={value.path}>{value.title}</Link></li>
                  ))}
                </ul>

                <p className="fs-5 fw-bold">Verbs</p>
                <ul className="docs-list">
                  {Object.entries(Routes.Verbs).map(([key, value]) => (
                    <li><Link to={value.path}>{value.title}</Link></li>
                  ))}
                </ul>

                <p className="fs-5 fw-bold">Phrases</p>
                <ul className="docs-list">
                  {Object.entries(Routes.Phrases).map(([key, value]) => (
                    <li><Link to={value.path}>{value.title}</Link></li>
                  ))}
                </ul>

                <p className="fs-5 fw-bold">Vocabulary</p>
                <ul className="docs-list">
                  {Object.entries(Routes.Vocabulary).map(([key, value]) => (
                    <li><Link to={value.path}>{value.title}</Link></li>
                  ))}
                </ul>
              </article>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
