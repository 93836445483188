import React, { useEffect } from 'react';
import { loadScript, initializeGPTSlot } from '../../../utils/scriptUtils';

const AdSlot = ({ slotId, adUnit, dimensions }) => {
  useEffect(() => {
    loadScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js', () => {
      initializeGPTSlot(slotId, adUnit, dimensions);
    });
  }, [slotId, adUnit, dimensions]);
    
  if (process.env.REACT_APP_SHOW_ADS === 'false') {
    return null;
  }

  return <div id={slotId} style={{ width: dimensions[0], height: dimensions[1] }}></div>;
};

export default AdSlot;