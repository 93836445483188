
import React from 'react';
import { Row, Col, Card, Container, Table } from '@themesberg/react-bootstrap';

export const Subjunctive = () => {
	return (
		<Container className="px-0">
			<Row>
				<Col xs={12} className="p-3">
					<Card>
						<Card.Body>
							<article>
								<h1>Subjunctive</h1>
								<p className="fs-5 fw-light">a/e</p>

								<div className="listandtable">
									<div className="listc">
										<p>
											The subjunctive mood is used to express a desire or to make a suggestion about an action. It often takes the meaning
											of "let (someone) do (something)" or "(someone) should do (something)".
										</p>
										<p>
											The subjunctive is most commonly used in the first person i.e. "Ben" or "Biz". However it can
											be used with any of the six personal suffixes.
										</p>
										<ol>
											<li>Positive (E.g. Let me go to school)</li>
											<li>Negative (E.g. Let me not go to school)</li>
											<li>Positive Question (E.g. Should I go to school?)</li>
											<li>Negative Question (E.g. Should I not go to school?)</li>
										</ol>
									</div>
									<div className="tablec">
										<Table responsive hover striped size="sm" className="pronouns2col">
											<tbody>
												<tr className="bg1"><td>I</td><td className="tur">Ben</td></tr>
												<tr className="bg2"><td>You</td><td className="tur">Sen</td></tr>
												<tr className="bg1"><td>He / She / It</td><td className="tur">O</td></tr>
												<tr className="bg2"><td>We</td><td className="tur">Biz</td></tr>
												<tr className="bg1"><td>You (Plural)</td><td className="tur">Siz</td></tr>
												<tr className="bg2"><td>They</td><td className="tur">Onlar</td></tr>
											</tbody>
											<caption>Personal pronouns</caption>
										</Table>
									</div>
								</div>


								<h2 className="spaceabove"><a id="subjunctivepos" className="linktarget">Positive</a></h2>

								<p className="centerrule">
									[<span className="red">verb root</span> + <span className="red">"a/e"</span> + <span className="red">personal suffix</span>]
								</p>

								<p>
									We start with the verb root. Then we attach the correct subjunctive suffix "a" or "e", depending on the last vowel of the verb root.
									Lastly, we attach the personal suffix.
								</p>

								<Table responsive hover striped size="sm" className="suffixes3col" >
									<thead>
										<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
									</thead>
									<tbody>
										<tr className="bg1"><td className="colh">Ben</td><td>-[y]ayım</td><td>-[y]eyim</td></tr>
										<tr className="bg2"><td className="colh">Sen</td><td>-[y]asın</td><td>-[y]esin</td></tr>
										<tr className="bg1"><td className="colh">O</td><td>-[y]a</td><td>-[y]e</td></tr>
										<tr className="bg2"><td className="colh">Biz</td><td>-[y]alım</td><td>-[y]elim</td></tr>
										<tr className="bg1"><td className="colh">Siz</td><td>-[y]asınız</td><td>-[y]esiniz</td></tr>
										<tr className="bg2"><td className="colh">Onlar</td><td>-[y]alar</td><td>-[y]eler</td></tr>
									</tbody>
									<caption>Suffixes to be added to the verb root (Positive)</caption>
								</Table>

								<p className='mt-4'>
									Examples:
								</p>

								<Table responsive hover striped size="sm" className="mt-4">
									<thead>
										<tr><td colspan="2" className="head">yapmak - to do</td><td colspan="2" className="head">görmek - to see</td></tr>
									</thead>
									<tbody>
										<tr><td className="tur">Ben yapayım</td><td>Let me do</td><td className="tur">Ben göreyim</td><td>Let me see</td></tr>
										<tr><td className="tur">Biz yapalım</td><td>Let us do</td><td className="tur">Biz görelim</td><td>Let us see</td></tr>
									</tbody>
								</Table>

								<p>
									If the letter at the end of the verb root is a vowel, we first attach a "y" to avoid having two vowels side by side:
								</p>

								<Table responsive hover striped size="sm" className="mt-4">
									<thead>
										<tr><td colspan="2" className="head">ba&#351;lamak - to start</td><td colspan="2" className="head">izlemek - to watch</td></tr>
									</thead>
									<tbody>
										<tr><td className="tur">Ben ba&#351;la<span className="y_underline">y</span>ayım</td><td>Let me start</td><td className="tur">Ben izle<span className="y_underline">y</span>eyim</td><td>Let me watch</td></tr>
										<tr><td className="tur">Biz ba&#351;la<span className="y_underline">y</span>alım</td><td>Let us start</td><td className="tur">Biz izle<span className="y_underline">y</span>elim</td><td>Let us watch</td></tr>
									</tbody>
								</Table>


								<h2 className="spaceabove"><a id="subjunctiveneg" className="linktarget">Negative</a></h2>

								<p className="centerrule">
									[<span className="red">verb root</span> + <span className="red">"ma/me"</span> + <span className="red">"y"</span> + <span className="red">"a/e"</span> + <span className="red">personal suffix</span>]
								</p>

								<p>
									For negative, we attach the negative suffix "ma/me" to the verb root depending on its last vowel. Then we attach the buffer letter "y"
									to avoid having two vowels next to each other. After that we attach the correct subjunctive suffix "a" or "e", depending on the previous vowel.
									Lastly, we attach the personal suffix.
								</p>

								<Table responsive hover striped size="sm" className="suffixes3col" >
									<thead>
										<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
									</thead>
									<tbody>
										<tr className="bg1"><td className="colh">Ben</td><td>-mayayım</td><td>-meyeyim</td></tr>
										<tr className="bg2"><td className="colh">Sen</td><td>-mayasın</td><td>-meyesin</td></tr>
										<tr className="bg1"><td className="colh">O</td><td>-maya</td><td>-meye</td></tr>
										<tr className="bg2"><td className="colh">Biz</td><td>-mayalım</td><td>-meyelim</td></tr>
										<tr className="bg1"><td className="colh">Siz</td><td>-mayasınız</td><td>-meyesiniz</td></tr>
										<tr className="bg2"><td className="colh">Onlar</td><td>-mayalar</td><td>-meyeler</td></tr>
									</tbody>
									<caption>Suffixes added to the verb root (Negative)</caption>
								</Table>

								<p className='mt-4'>
									Examples:
								</p>

								<Table responsive hover striped size="sm" className="mt-4">
									<thead>
										<tr><td colspan="2" className="head">yapmak - to do</td><td colspan="2" className="head">görmek - to see</td></tr>
									</thead>
									<tbody>
										<tr><td className="tur">Ben yapmayayım</td><td>Let me not do</td><td className="tur">Ben görmeyeyim</td><td>Let me not see</td></tr>
										<tr><td className="tur">Biz yapmayalım</td><td>Let us not do</td><td className="tur">Biz görmeyelim</td><td>Let us not see</td></tr>
									</tbody>
								</Table>


								<h2 className="spaceabove"><a id="subjunctiveposques" className="linktarget">Positive Question</a></h2>

								<p className="centerrule">
									[<span className="red">verb root</span> + <span className="red">"a/e"</span> + <span className="red">personal suffix</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="red">"mı/mi"</span>]
								</p>

								<p>
									We can easily turn a positive subjunctive verb into a question. All we do is add one of "mı/mi" after it. These are not
									suffixes but separate words altogether.
								</p>

								<p>
									Verbs of this form take the meaning of "Should (someone) do (something)?".
								</p>

								<Table responsive hover striped size="sm" className="suffixes3col" >
									<thead>
										<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
									</thead>
									<tbody>
										<tr className="bg1"><td className="colh">Ben</td><td>-[y]ayım mı</td><td>-[y]eyim mi</td></tr>
										<tr className="bg2"><td className="colh">Sen</td><td>-[y]asın mı</td><td>-[y]esin mi</td></tr>
										<tr className="bg1"><td className="colh">O</td><td>-[y]a mı</td><td>-[y]e mi</td></tr>
										<tr className="bg2"><td className="colh">Biz</td><td>-[y]alım mı</td><td>-[y]elim mi</td></tr>
										<tr className="bg1"><td className="colh">Siz</td><td>-[y]asınız mı</td><td>-[y]esiniz mi</td></tr>
										<tr className="bg2"><td className="colh">Onlar</td><td>-[y]alar mı</td><td>-[y]eler mi</td></tr>
									</tbody>
									<caption>Suffixes to be added to the verb root (Positive Question)</caption>
								</Table>

								<p className='mt-4'>
									Examples:
								</p>

								<Table responsive hover striped size="sm" className="mt-4">
									<thead>
										<tr><td colspan="2" className="head">yapmak - to do</td><td colspan="2" className="head">görmek - to see</td></tr>
									</thead>
									<tbody>
										<tr><td className="tur">Ben yapayım mı?</td><td>Should I do?</td><td className="tur">Ben göreyim mi?</td><td>Should I see?</td></tr>
										<tr><td className="tur">Biz yapalım mı?</td><td>Should we do?</td><td className="tur">Biz görelim mi?</td><td>Should we see?</td></tr>
									</tbody>
								</Table>


								<h2 className="spaceabove"><a id="subjunctivenegques" className="linktarget">Negative Question</a></h2>

								<p className="centerrule">
									[<span className="red">verb root</span> + <span className="red">"ma/me"</span> + <span className="red">"y"</span> + <span className="red">"a/e"</span> + <span className="red">personal suffix</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="red">"mı/mi"</span>]
								</p>

								<p>
									Likewise, we can easily turn a negative subjunctive verb into a question. All we do is add one of "mı/mi" after it. These are not
									suffixes but separate words altogether.
								</p>

								<p>
									Verbs of this form take the meaning of "Should (someone) not do (something)?".
								</p>

								<Table responsive hover striped size="sm" className="suffixes3col" >
									<thead>
										<tr><th className="corner">Last Vowel of verb root&#9654;</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
									</thead>
									<tbody>
										<tr className="bg1"><td className="colh">Ben</td><td>-mayayım mı</td><td>-meyeyim mi</td></tr>
										<tr className="bg2"><td className="colh">Sen</td><td>-mayasın mı</td><td>-meyesin mi</td></tr>
										<tr className="bg1"><td className="colh">O</td><td>-maya mı</td><td>-meye mi</td></tr>
										<tr className="bg2"><td className="colh">Biz</td><td>-mayalım mı</td><td>-meyelim mi</td></tr>
										<tr className="bg1"><td className="colh">Siz</td><td>-mayasınız mı</td><td>-meyesiniz mi</td></tr>
										<tr className="bg2"><td className="colh">Onlar</td><td>-mayalar mı</td><td>-meyeler mi</td></tr>
									</tbody>
									<caption>Suffixes added to the verb root (Negative Question)</caption>
								</Table >

								<p className='mt-4'>
									Examples:
								</p>

								<Table responsive hover striped size="sm" className="mt-4">
									<thead>
										<tr><td colspan="2" className="head">yapmak - to do</td><td colspan="2" className="head">görmek - to see</td></tr>
									</thead>
									<tbody>
										<tr><td className="tur">Ben yapmayayım mı?</td><td>Should I not do?</td><td className="tur">Ben görmeyeyim mi?</td><td>Should I not see?</td></tr>
										<tr><td className="tur">Biz yapmayalım mı?</td><td>Should we not do?</td><td className="tur">Biz görmeyelim mi?</td><td>Should we not see?</td></tr>
									</tbody>
								</Table>
							</article >
						</Card.Body >
					</Card >
				</Col >
			</Row >
		</Container >
	)
}
