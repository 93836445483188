import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";

// content
import { DefinitePastTense } from './content/verbs/DefinitePastTense';
import { ReportedPastTense } from './content/verbs/ReportedPastTense';
import { PresentContinuousTense } from './content/verbs/PresentContinuousTense';
import { PresentSimpleTense } from './content/verbs/PresentSimpleTense';
import { FutureTense } from './content/verbs/FutureTense';
import { Imperative } from './content/verbs/Imperative';
import { PassiveVoice } from './content/verbs/PassiveVoice';
import { Can } from './content/verbs/Can';
import { If } from './content/verbs/If';
import { Must } from './content/verbs/Must';
import { Subjunctive } from './content/verbs/Subjunctive';

import Alphabet from './content/grammar/Alphabet';
import { IAmYouAre } from './content/grammar/IAmYouAre';
import { ConsonantMutation } from './content/grammar/ConsonantMutation';
import { DegreesOfComparison } from './content/grammar/DegreesOfComparison';
import { Numbers } from './content/grammar/Numbers';
import { Plurals } from './content/grammar/Plurals';
import { Possession } from './content/grammar/Possession';
import { TellingTheTime } from './content/grammar/TellingTheTime';
import { VarAndYok } from './content/grammar/VarAndYok';
import { VowelHarmony } from './content/grammar/VowelHarmony';
import BasicPhrases from './content/phrases/BasicPhrases';
import MeetingPeople from './content/phrases/MeetingPeople';
import { Directions } from './content/phrases/Directions';
import { Eating } from './content/phrases/Eating';
import { Shopping } from './content/phrases/Shopping';
import { Occasions } from './content/phrases/Occasions';

import { Airport } from './content/vocabulary/Airport';
import { Animals } from './content/vocabulary/Animals';
import { AtHome } from './content/vocabulary/AtHome';
import { Clothing } from './content/vocabulary/Clothing';
import { Colors } from './content/vocabulary/Colors';
import { Countries } from './content/vocabulary/Countries';
import { Currency } from './content/vocabulary/Currency';
import { DaysAndMonths } from './content/vocabulary/DaysAndMonths';
import { FamilyMembers } from './content/vocabulary/FamilyMembers';
import { Food } from './content/vocabulary/Food';
import { Languages } from './content/vocabulary/Languages';
import { Islam } from './content/vocabulary/Islam';
import { MobilePhone } from './content/vocabulary/MobilePhone';
import { Occupations } from './content/vocabulary/Occupations';
import { PartsOfTheBody } from './content/vocabulary/PartsOfTheBody';
import { Politics } from './content/vocabulary/Politics';
import { School } from './content/vocabulary/School';
import { Transport } from './content/vocabulary/Transport';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />

    {/* components */}
    <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

    {/* documentation */}
    <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />

    {/* content */}
    <RouteWithSidebar exact path={Routes.Verbs.DefinitePastTense.path} component={DefinitePastTense} />
    <RouteWithSidebar exact path={Routes.Verbs.ReportedPastTense.path} component={ReportedPastTense} />
    <RouteWithSidebar exact path={Routes.Verbs.PresentContinuousTense.path} component={PresentContinuousTense} />
    <RouteWithSidebar exact path={Routes.Verbs.PresentSimpleTense.path} component={PresentSimpleTense} />
    <RouteWithSidebar exact path={Routes.Verbs.FutureTense.path} component={FutureTense} />
    <RouteWithSidebar exact path={Routes.Verbs.PassiveVoice.path} component={PassiveVoice} />
    <RouteWithSidebar exact path={Routes.Verbs.Imperative.path} component={Imperative} />
    <RouteWithSidebar exact path={Routes.Verbs.Subjunctive.path} component={Subjunctive} />
    <RouteWithSidebar exact path={Routes.Verbs.Can.path} component={Can} />
    <RouteWithSidebar exact path={Routes.Verbs.Must.path} component={Must} />
    <RouteWithSidebar exact path={Routes.Verbs.If.path} component={If} />

    <RouteWithSidebar exact path={Routes.Grammar.Alphabet.path} component={Alphabet} />
    <RouteWithSidebar exact path={Routes.Grammar.IAmYouAre.path} component={IAmYouAre} />
    <RouteWithSidebar exact path={Routes.Grammar.Numbers.path} component={Numbers} />
    <RouteWithSidebar exact path={Routes.Grammar.DegreesOfComparison.path} component={DegreesOfComparison} />
    <RouteWithSidebar exact path={Routes.Grammar.VarAndYok.path} component={VarAndYok} />
    <RouteWithSidebar exact path={Routes.Grammar.VowelHarmony.path} component={VowelHarmony} />
    <RouteWithSidebar exact path={Routes.Grammar.Possession.path} component={Possession} />
    <RouteWithSidebar exact path={Routes.Grammar.Plurals.path} component={Plurals} />
    <RouteWithSidebar exact path={Routes.Grammar.TellingTheTime.path} component={TellingTheTime} />
    <RouteWithSidebar exact path={Routes.Grammar.ConsonantMutation.path} component={ConsonantMutation} />

    <RouteWithSidebar exact path={Routes.Phrases.BasicPhrases.path} component={BasicPhrases} />
    <RouteWithSidebar exact path={Routes.Phrases.MeetingPeople.path} component={MeetingPeople} />
    <RouteWithSidebar exact path={Routes.Phrases.Directions.path} component={Directions} />
    <RouteWithSidebar exact path={Routes.Phrases.Eating.path} component={Eating} />
    <RouteWithSidebar exact path={Routes.Phrases.Shopping.path} component={Shopping} />
    <RouteWithSidebar exact path={Routes.Phrases.Occasions.path} component={Occasions} />

    <RouteWithSidebar exact path={Routes.Vocabulary.Airport.path} component={Airport} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Animals.path} component={Animals} />
    <RouteWithSidebar exact path={Routes.Vocabulary.AtHome.path} component={AtHome} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Clothing.path} component={Clothing} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Colors.path} component={Colors} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Countries.path} component={Countries} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Currency.path} component={Currency} />
    <RouteWithSidebar exact path={Routes.Vocabulary.DaysAndMonths.path} component={DaysAndMonths} />
    <RouteWithSidebar exact path={Routes.Vocabulary.FamilyMembers.path} component={FamilyMembers} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Food.path} component={Food} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Islam.path} component={Islam} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Languages.path} component={Languages} />
    <RouteWithSidebar exact path={Routes.Vocabulary.MobilePhone.path} component={MobilePhone} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Occupations.path} component={Occupations} />
    <RouteWithSidebar exact path={Routes.Vocabulary.PartsOfTheBody.path} component={PartsOfTheBody} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Politics.path} component={Politics} />
    <RouteWithSidebar exact path={Routes.Vocabulary.School.path} component={School} />
    <RouteWithSidebar exact path={Routes.Vocabulary.Transport.path} component={Transport} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
