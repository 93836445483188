import React from 'react';
import { Row, Col, Card, Container, Table } from '@themesberg/react-bootstrap';

import Positive from "../../../assets/img/content/rules/verbs/can/saying_can_positive.jpg";
import Negative from "../../../assets/img/content/rules/verbs/can/saying_can_negative.jpg";
import ImageRule from '../../../components/ImageRule';
import SectionHeading from '../../../components/SectionHeading';

export const Can = () => (
	<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>"Can"</h1>
							<p className="fs-5 fw-light">(abil/ebil)</p>

							<p>
								In Turkish, we express "can" using the suffix "abil/ebil". We attach the appropriate one to the verb root based on its last vowel. "abil/ebil"
								can be used with any tense but is usually used with present simple tense.
							</p>

							<h2 className="fs-5 mt-4">Positive</h2>

							<ImageRule src={Positive} />

							<p>
								We begin with the verb root. We attach one of "abil/ebil" depending on the root's last vowel. If the letter at the end of the verb root
								is a vowel, we also first attach a "y" to prevent having two vowels side by side. Thereafter we add the tense suffix followed by
								the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col narrowcol">
								<thead>
									<tr><th className="corner">Last vowel of verb root</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Suffix</td><td>-[y]abil-</td><td>-[y]ebil-</td></tr>
								</tbody>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<p className="scd">
								Ben yaz<span className="red">abil</span>irim  - I can write<br />
								Sen duy<span className="red">abil</span>irsin - You can hear<br />
								Onlar gel<span className="red">ebil</span>irler - They can come<br />
								Biz y&#252;z<span className="red">ebil</span>iriz - We can swim<br />
								O oku<span className="y_underline">y</span><span className="red">abil</span>ir - He/she/it can read<br />
								Siz izle<span className="y_underline">y</span><span className="red">ebil</span>irsiniz - You can watch
							</p>


							<SectionHeading title='Negative' />
							<ImageRule src={Negative} />

							<p>
								For negative, we attach one of "a/e" followed by the negative suffix "ma/me" to the verb root. The suffixes we add depend on the verb root's last vowel. If the letter at the end of the verb root is a vowel, we also first attach a "y" to prevent having two vowels side by side. Thereafter we add the tense suffix followed by the personal suffix.
							</p>

							<Table responsive hover striped size="sm" className="suffixes3col narrowcol">
								<thead>
									<tr><th className="corner">Last vowel of verb root</th><th>a/&#305;/o/u</th><th>e/i/&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Suffix</td><td>-[y]ama-</td><td>-[y]eme-</td></tr>
								</tbody>
							</Table>

							<p className='mt-4'>
								Examples:
							</p>

							<p className="scd">
								Ben yaz<span className="red">ama</span>m  - I cannot write<br />
								Sen duy<span className="red">ama</span>zs&#305;n - You cannot hear<br />
								Onlar gel<span className="red">eme</span>zler - They cannot come<br />
								Biz y&#252;z<span className="red">eme</span>yiz - We cannot swim<br />
								O oku<span className="y_underline">y</span><span className="red">ama</span>z - He/she/it cannot read<br />
								Siz izle<span className="y_underline">y</span><span className="red">eme</span>zsiniz - You cannot watch
							</p>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container>
);
