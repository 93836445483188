import React from 'react';
import { Row, Col, Card, Container, Table } from '@themesberg/react-bootstrap';

export const PassiveVoice = () => (
	<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Passive voice</h1>
							<p className="fs-5 fw-light">(n , &#305;n/in/un/&#252;n , &#305;l/il/ul/&#252;l)</p>

							<p>
								Verbs can be stated in the active voice or the passive voice.
							</p>

							<p>
								In the active voice, the person or thing that is carrying out the action is clear. We know who the "doer" is.
							</p>

							<p>
								In the passive voice, we are only aware of who/what the action is being performed upon. We don't always know who is doing the action. In English,
								we generally express the passive voice using "to be".
							</p>

							<Table responsive hover striped size="sm" className="doublewide passive">
								<thead>
									<tr><th>Active voice</th><th>Passive voice</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td>to watch</td><td>to be watched</td></tr>
									<tr className="bg2"><td>to open</td><td>to be opened</td></tr>
									<tr className="bg1"><td>to read</td><td>to be read</td></tr>
									<tr className="bg2"><td>to find</td><td>to be found</td></tr>
								</tbody>
							</Table>


							<p>
								In Turkish, we can convert a verb into the passive voice by attaching certain suffixes to its root. We obtain the verb root by removing
								the "mak/mek" from the infinitive form. For example, the verb root of "izlemek" would be "izle", "bulmak" would be "bul" and "a&#231;mak"
								would be "a&#231;".
							</p>

							<p>
								There are three different passive voice suffixes that we can attach based on the spelling of the verb root. We focus on whether its last letter
								is a vowel or consonant.
							</p>

							<br />

							<h3>Case 1 - Ends in vowel: ("n")</h3>

							<p className="centerrule">
								[<span className="red">verb root</span> + <span className="red">"n"</span> + <span className="red">"mak/mek"</span>]
							</p>

							<p>
								The first case is when the verb root has a vowel at the end of it. To convert it into the passive voice we attach a "n" after it.
							</p>


							<Table responsive hover striped size="sm" className="doublewide passive">
								<thead>
									<tr><th>Active voice</th><th>Passive voice</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td>izlemek (to watch)</td><td>izle<span className="red">n</span>mek (to be watched)</td></tr>
									<tr className="bg2"><td>okumak (to read)</td><td>oku<span className="red">n</span>mak (to be read)</td></tr>
									<tr className="bg1"><td>y&#305;kamak (to wash)</td><td>y&#305;ka<span className="red">n</span>mak (to be washed)</td></tr>
								</tbody>
							</Table>

							<p>
								Examples:<br />
							</p>

							<p className="scd">
								<span className="exleading">Active</span>: Ben video izledim - I watched a video<br />
								<span className="exleading">Passive</span>: Video izle<span className="red">n</span>di - The video was watched<br />
								<br />
								<span className="exleading">Active</span>: M&#252;ezzin ezan okuyacak - The muezzin will read azan<br />
								<span className="exleading">Passive</span>: Ezan oku<span className="red">n</span>acak - Azan will be read<br />
								<br />
								<span className="exleading">Active</span>: Biz bula&#351;&#305;klar&#305; y&#305;kad&#305;k - We washed the dishes<br />
								<span className="exleading">Passive</span>: Bula&#351;&#305;klar y&#305;ka<span className="red">n</span>d&#305; - The dishes were washed
							</p>

							<br />

							<h3>Case 2 - Ends in "l": ("&#305;n/in/un/&#252;n")</h3>

							<p className="centerrule">
								[<span className="red">verb root</span> + <span className="red">"&#305;n/in/un/&#252;n"</span> + <span className="red">"mak/mek"</span>]
							</p>

							<p>
								The second case is when the verb has the letter "l" at the end. To convert it into the passive voice we attach one of
								"&#305;n/in/un/&#252;n" after it based on its last vowel:
							</p>

							<Table responsive hover striped size="sm" className="suffixes5col 2row">
								<thead>
									<tr><th className="corner">Last vowel of verb root</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Suffix</td><td>-&#305;n</td><td>-in</td><td>-un</td><td>-&#252;n</td></tr>
								</tbody>
							</Table>

							<Table responsive hover striped size="sm" className="doublewide passive">
								<thead>
									<tr><th>Active voice</th><th>Passive voice</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td>bulmak (to find)</td><td>bul<span className="red">un</span>mak (to be found)</td></tr>
									<tr className="bg2"><td>silmek (to erase)</td><td>sil<span className="red">in</span>mek (to be erased)</td></tr>
									<tr className="bg1"><td>sat&#305;n almak (to buy)</td><td>sat&#305;n al<span className="red">&#305;n</span>mak (to be bought)</td></tr>
								</tbody>
							</Table>

							<p>
								Examples:<br />
							</p>

							<p className="scd">
								<span className="exleading">Active</span>: Ben anahtar buldum - I found a key<br />
								<span className="exleading">Passive</span>: Anahtar bul<span className="red">un</span>du - A key was found<br />
								<br />
								<span className="exleading">Active</span>: &#214;&#287;retmen tahtay&#305; sildi - The teacher erased the blackboard<br />
								<span className="exleading">Passive</span>: Tahta sil<span className="red">in</span>di - The blackboard was erased<br />
								<br />
								<span className="exleading">Active</span>: Onlar bu arabay&#305; sat&#305;n ald&#305;lar - They bought this car<br />
								<span className="exleading">Passive</span>: Bu araba sat&#305;n al<span className="red">&#305;n</span>d&#305; - This car was bought
							</p>

							<br />

							<h3>Case 3 - Ends in consonant: ("&#305;l/il/ul/&#252;l")</h3>

							<p className="centerrule">
								[<span className="red">verb root</span> + <span className="red">"&#305;l/il/ul/&#252;l"</span> + <span className="red">"mak/mek"</span>]
							</p>

							<p>
								The third case is when the verb root ends in a consonant besides "l". To convert it into the passive voice we attach one of "&#305;l/il/ul/&#252;l"
								after it based on its last vowel:
							</p>

							<Table responsive hover striped size="sm" className="suffixes5col 2row">
								<thead>
									<tr><th className="corner">Last vowel of verb root</th><th>a/&#305;</th><th>e/i</th><th>o/u</th><th>&#246;/&#252;</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td className="colh">Suffix</td><td>-&#305;l</td><td>-il</td><td>-ul</td><td>-&#252;l</td></tr>
								</tbody>
							</Table>

							<Table responsive hover striped size="sm" className="doublewide passive">
								<thead>
									<tr><th>Active voice</th><th>Passive voice</th></tr>
								</thead>
								<tbody>
									<tr className="bg1"><td>a&#231;mak (to open)</td><td>a&#231;<span className="red">&#305;l</span>mak (to be opened)</td></tr>
									<tr className="bg2"><td>unutmak (to forget)</td><td>unut<span className="red">ul</span>mak (to be forgotten)</td></tr>
									<tr className="bg1"><td>be&#287;enmek (to like)</td><td>be&#287;en<span className="red">il</span>mek (to be liked)</td></tr>
								</tbody>
							</Table>

							<p>
								Examples:<br />
							</p>

							<p className="scd">
								<span className="exleading">Active</span>: Ben pencereyi a&#231;t&#305;m - I opened the window<br />
								<span className="exleading">Passive</span>: Pencere a&#231;<span className="red">&#305;l</span>d&#305; - The window was opened<br />
								<br />
								<span className="exleading">Active</span>: Sen &#231;antay&#305; trende unuttun - You forgot the bag on the train<br />
								<span className="exleading">Passive</span>: &#199;anta trende unut<span className="red">ul</span>du - The bag was forgotten on the train<br />
								<br />
								<span className="exleading">Active</span>: Yunus durumumu be&#287;enmi&#351; - Yunus liked my status (on Facebook)<br />
								<span className="exleading">Passive</span>: Durumum be&#287;en<span className="red">il</span>mi&#351; - My status was liked
							</p>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container>
);
