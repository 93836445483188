import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const Transport = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Transport</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>Aeroplane</td><td>U&#231;ak</td></tr>
                                        <tr><td>Bicycle</td><td>Bisiklet</td></tr>
                                        <tr><td>Boat</td><td>Tekne</td></tr>
                                        <tr><td>Bus</td><td>Otob&#252;s</td></tr>
                                        <tr><td>Car</td><td>Araba</td></tr>
                                        <tr><td>Ferry</td><td>Feribot / Vapur</td></tr>
                                        <tr><td>Helicopter</td><td>Helikopter</td></tr>
                                        <tr><td>Motorbike</td><td>Motosiklet</td></tr>
                                        <tr><td>Scooter</td><td>Skuter</td></tr>
                                        <tr><td>Ship</td><td>Gemi</td></tr>
                                        <tr><td>Subway / Underground Railway</td><td>Metro</td></tr>
                                        <tr><td>Taxi</td><td>Taksi</td></tr>
                                        <tr><td>Train</td><td>Tren</td></tr>
                                        <tr><td>Tram</td><td>Tramvay</td></tr>
                                        <tr><td>Truck</td><td>Kamyon</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
