import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const VowelHarmony = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Vowel Harmony</h1>

                                <p>
                                    In Turkish, words are constructed in such a way that the vowels follow a specific pattern. This pattern is called Vowel Harmony.
                                </p>

                                <p>
                                    Vowel Harmony is used when deciding which vowels to use when attaching a suffix to a word.
                                    By looking at a particular vowel in that word (usually the last vowel), the rules of Vowel Harmony help
                                    us decide which vowels to use after it.
                                </p>

                                <p>
                                    In order to understand Vowel Harmony we have to look at the different types of vowels that exist in Turkish:
                                </p>

                                <p className="scd">
                                    Vowels: a, e, &#305;, i, o, &#246;, u, &#252;
                                </p>

                                <p className="scd">
                                    Hard vowels: a, &#305;, o, u<br />
                                    Soft vowels: e, i, &#246;, &#252;
                                </p>

                                <p className="scd">
                                    Straight vowels: a, e, &#305;, i<br />
                                    Round vowels: o, &#246;, u, &#252;
                                </p>

                                <p>
                                    There are four Vowel Harmony rules in total: two Major Vowel Harmony and two Minor Vowel Harmony rules.
                                </p>

                                <h2><a id="majorvowelharmony" className="linktarget">Major Vowel Harmony</a></h2>

                                <h3>Part 1: Hard vowels</h3>
                                <p>
                                    If the last vowel of a word is one of a/&#305;/o/u, the vowels in the suffixes that follow it can only be one of a/&#305;/o/u.
                                </p>

                                <Table hover striped size="sm" className="rules vharmony maj1">
                                    <tbody>
                                        <tr>
                                            <td><span className="red">a</span> / <span className="red">&#305;</span> / <span className="red">o</span> / <span className="red">u</span></td>
                                            <td className="centerarrow">&#10152;</td>
                                            <td><span className="red">a</span> / <span className="red">&#305;</span> / <span className="red">o</span> / <span className="red">u</span></td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <h3>Part 2: Soft vowels</h3>
                                <p>
                                    If the last vowel of a word is one of e/i/&#246;/&#252;, the vowels in the suffixes that follow it can only be one of e/i/&#246;/&#252;.
                                </p>

                                <Table hover striped size="sm" className="rules vharmony maj2">
                                    <tbody>
                                        <tr>
                                            <td><span className="red">e</span> / <span className="red">i</span> / <span className="red">&#246;</span> / <span className="red">&#252;</span></td>
                                            <td className="centerarrow">&#10152;</td>
                                            <td><span className="red">e</span> / <span className="red">i</span> / <span className="red">&#246;</span> / <span className="red">&#252;</span></td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <h2><a id="minorvowelharmony" className="linktarget">Minor Vowel Harmony</a></h2>

                                <h3>Part 1: Straight vowels</h3>
                                <p>
                                    If the last vowel of a word is one of a/e/&#305;/i, the vowels in the suffixes that follow it can only be one of a/e/&#305;/i.
                                </p>

                                <Table hover striped size="sm" className="rules vharmony min1">
                                    <tbody>
                                        <tr>
                                            <td><span className="red">a</span> / <span className="red">e</span> / <span className="red">&#305;</span> / <span className="red">i</span></td>
                                            <td className="centerarrow">&#10152;</td>
                                            <td><span className="red">a</span> / <span className="red">e</span> / <span className="red">&#305;</span> / <span className="red">i</span></td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <h3>Part 2: Round vowels</h3>
                                <p>
                                    If the last vowel of a word is one of o/&#246;/u/&#252;, the vowels in the suffixes that follow it can only be one of a/e/u/&#252;.
                                </p>

                                <Table hover striped size="sm" className="rules vharmony min2">
                                    <tbody>
                                        <tr>
                                            <td><span className="red">o</span> / <span className="red">&#246;</span> / <span className="red">u</span> / <span className="red">&#252;</span></td>
                                            <td className="centerarrow">&#10152;</td>
                                            <td><span className="red">a</span> / <span className="red">e</span> / <span className="red">u</span> / <span className="red">&#252;</span></td>
                                        </tr>
                                    </tbody>
                                </Table>

                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
