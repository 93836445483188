import { Card, Col, Container, Row, Table } from "@themesberg/react-bootstrap";

export const MobilePhone = () => {
    return (
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Body>
                            <article>
                                <h1>Mobile Phone</h1>

                                <Table responsive hover striped size="sm">
                                    <tbody>
                                        <tr><td>App</td><td>Uygulama</td></tr>
                                        <tr><td>Battery</td><td>Pil</td></tr>
                                        <tr><td>Call</td><td>&#199;a&#287;r&#305;</td></tr>
                                        <tr><td>Charger</td><td>&#350;arj aleti</td></tr>
                                        <tr><td>Data</td><td>Veri</td></tr>
                                        <tr><td>Device</td><td>Cihaz</td></tr>
                                        <tr><td>Internet</td><td>&#304;nternet</td></tr>
                                        <tr><td>Message</td><td>Mesaj</td></tr>
                                        <tr><td>Mobile phone</td><td>Cep telefonu</td></tr>
                                        <tr><td>Network</td><td>A&#287;</td></tr>
                                        <tr><td>Password</td><td>&#350;ifre</td></tr>
                                        <tr><td>Prepaid credits / airtime</td><td>Kont&#246;r</td></tr>
                                        <tr><td>Roaming</td><td>Dola&#351;&#305;m</td></tr>
                                        <tr><td>Sim card</td><td>SIM kart</td></tr>
                                        <tr><td>Smartphone</td><td>Ak&#305;ll&#305; telefon</td></tr>
                                        <tr><td>Touch screen</td><td>Dokunmatik ekran</td></tr>
                                    </tbody>
                                </Table>
                            </article>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}
