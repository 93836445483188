import { Card, Col, Container, Row } from "@themesberg/react-bootstrap";

export const Occasions = () => {
	return (<Container className="px-0">
		<Row>
			<Col xs={12} className="p-3">
				<Card>
					<Card.Body>
						<article>
							<h1>Occasions</h1>
							<p class="phrases">
								<span class="c1">Congratulations</span><br />
								<span className="text-secondary">Tebrikler</span><br />
								<br />
								<span class="c1">Get well soon</span><br />
								<span className="text-secondary">Geçmiş olsun</span><br />
								<br />
								<span class="c1">Bon voyage</span><br />
								<span className="text-secondary">Hayırlı yolculuklar</span><br />
								<br />
								<span class="c1">Enjoy your meal</span><br />
								<span className="text-secondary">Afiyet olsun</span><br />
								<br />
								<span class="c1">Ramadan Mubarak</span><br />
								<span className="text-secondary">Ramazanınız Mübarek Olsun</span><br />
								<br />
								<span class="c1">Eid Mubarak</span><br />
								<span className="text-secondary">Bayramınız Mübarek Olsun</span><br />
								<br />
								<span class="c1">Happy Eid</span><br />
								<span className="text-secondary">Hayırlı Bayramlar</span><br />
								<br />
								<span class="c1">Jumuah Mubarakah</span><br />
								<span className="text-secondary">Cumanız Mübarek Olsun</span><br />
								<br />
								<span class="c1">May Allah have mercy on his/her soul (when someone dies)</span><br />
								<span className="text-secondary">Allah rahmet eylesin</span><br />
								<br />
							</p>
						</article>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container >
	);
}